import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LatLngLiteral } from "@googlemaps/google-maps-services-js";
import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useNavigation } from "@react-navigation/native";
import {
    Button,
    Icon,
    Input,
    Modal,
    Stack,
    Text,
    useDisclose,
} from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { Location } from "../interfaces/Location";
import i18n, { t } from "../translations/i18n";

interface MapProps {
    currentLocation: LatLngLiteral;
    withCurrnetLocationMarker?: boolean | true;
    withCircleRadius?: boolean | true;
    circleRadius?: number;
    zoom?: number;
    listLocation?: Location[];
    buttonTitle?: string;
    deliveryAddress?: string;
    onLocationChange?: (location: LatLngLiteral) => void;
}

const containerStyle: React.CSSProperties = {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
};

const onLoad = (circle: google.maps.Circle) => {
    console.log("Circle onLoad circle: ", circle);
};

const onUnmount = (circle: google.maps.Circle) => {
    console.log("Circle onUnmount circle: ", circle);
};

const Map: React.FC<MapProps> = (MapProps) => {
    const navigation = useNavigation();
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";
    const textAlign = i18n.locale === "ar" ? "right" : "left";

    const { isOpen, onClose, onToggle } = useDisclose();
    const [selectedLocation, setSelectedLocation] = useState<
        LatLngLiteral | undefined
    >(MapProps.currentLocation);

    const circleOptions: google.maps.CircleOptions = {
        strokeColor: "#FF0000",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: MapProps.circleRadius || 4000,
        zIndex: 1,
    };

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        setSelectedLocation({
            lat: event.latLng?.lat() as number,
            lng: event.latLng?.lng() as number,
        });
    };

    const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
        setSelectedLocation({
            lat: event.latLng?.lat() as number,
            lng: event.latLng?.lng() as number,
        });
    };

    // navigate to home screen and pass locationId
    const handleMarkerClick = (locationId: string) => {
        navigation.navigate("Home", {
            locationId,
        });
        onClose();
    };

    const handleLocationChange = useCallback(
        (location: LatLngLiteral) => {
            if (MapProps.onLocationChange) {
                MapProps.onLocationChange(location as LatLngLiteral);
            }
        },
        [MapProps.onLocationChange]
    );

    const handleMapPress = (event) => {
        const { latitude, longitude } = event.nativeEvent.coordinate;
        setSelectedLocation({ lat: latitude, lng: longitude });
    };

    // handle location change
    useEffect(() => {
        handleLocationChange(selectedLocation as LatLngLiteral);
    }, []);

    const openMap = () => {
        // scroll to top if web
        if (Platform.OS === "web") {
            window.scrollTo(0, 0);
        }
        // open modal map
        onToggle();
    };

    if (!selectedLocation?.lat || !selectedLocation?.lng) {
        return null;
    }

    return (
        <>
            <Input
                type={"text"}
                variant="underlined"
                editable={false}
                w="100%"
                py="0"
                size="md"
                isRequired
                isInvalid={!MapProps.deliveryAddress}
                invalidOutlineColor={"rose.600"}
                textAlign={textAlign}
                value={MapProps.deliveryAddress}
                placeholder={t("Select Delivery Address")}
                onFocus={openMap}
                InputLeftElement={
                    <Button size="xs" variant={"ghost"} p={1} onPress={openMap}>
                        <Icon
                            as={MaterialCommunityIcons}
                            name="map-marker"
                            size={"24px"}
                            color={"rose.600"}
                        />
                    </Button>
                }
            />
            <Modal
                isOpen={isOpen}
                onClose={() => onClose()}
                size={"full"}
                m={0}
                p={0}
            >
                <Modal.Content minHeight={"100%"} borderTopRadius="0" p={0}>
                    <Modal.Body
                        p={0}
                        _scrollview={{
                            scrollEnabled: false,
                        }}
                    >
                        {Platform.OS === "web" && (
                            <LoadScript googleMapsApiKey="AIzaSyBFXdYsihkVESTba0x7WZTyw2mK1gfO9lI">
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={selectedLocation}
                                    zoom={MapProps?.zoom || 12}
                                    onClick={handleMapClick}
                                    options={{
                                        disableDefaultUI: true,
                                    }}
                                >
                                    <Stack
                                        position={"absolute"}
                                        bottom={0}
                                        padding={2}
                                        maxWidth={"100%"}
                                        w={"full"}
                                    >
                                        <Button
                                            size={"lg"}
                                            width={"100%"}
                                            borderTopRadius={"xl"}
                                            borderBottomRadius={"2xl"}
                                            _text={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                marginBottom: -1,
                                            }}
                                            onPress={() => {
                                                onClose();
                                                handleLocationChange(
                                                    selectedLocation as LatLngLiteral
                                                );
                                            }}
                                        >
                                            {t("Confirm Location")}
                                        </Button>
                                    </Stack>

                                    {selectedLocation &&
                                        MapProps.withCurrnetLocationMarker && (
                                            <>
                                                <Marker
                                                    position={selectedLocation}
                                                    draggable
                                                    // onDragEnd={handleMarkerDragEnd}
                                                />
                                                <Text
                                                    position={"absolute"}
                                                    alignContent={"center"}
                                                    alignItems={"center"}
                                                    textAlign={"center"}
                                                    padding={2}
                                                    top={3}
                                                    right={3}
                                                >{`${selectedLocation.lat.toFixed(
                                                    3
                                                )}, ${selectedLocation.lng.toFixed(
                                                    3
                                                )}`}</Text>
                                            </>
                                        )}

                                    {MapProps.listLocation && (
                                        <>
                                            {MapProps.listLocation.map(
                                                (location) => {
                                                    return (
                                                        <Marker
                                                            key={`marker-${location.id}`}
                                                            onClick={() => {
                                                                handleMarkerClick(
                                                                    location.id
                                                                );
                                                            }}
                                                            title={
                                                                location.businessName
                                                            }
                                                            icon={
                                                                {
                                                                    url: location.logoUrl,
                                                                    size: {
                                                                        width: 50,
                                                                        height: 50,
                                                                    } as any,
                                                                    scaledSize:
                                                                        {
                                                                            width: 50,
                                                                            height: 50,
                                                                        } as any,
                                                                } as any
                                                            }
                                                            position={
                                                                {
                                                                    lat: location
                                                                        .coordinates
                                                                        ?.latitude,
                                                                    lng: location
                                                                        .coordinates
                                                                        ?.longitude,
                                                                } as LatLngLiteral
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </>
                                    )}

                                    {MapProps.withCircleRadius &&
                                        selectedLocation && (
                                            <Circle
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                                center={selectedLocation}
                                                options={circleOptions}
                                            />
                                        )}
                                </GoogleMap>
                            </LoadScript>
                        )}
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default Map;
