import {
    Center,
    Heading,
    HStack,
    Link,
    Spinner,
    Text,
    VStack,
} from "native-base";
import React from "react";
import { _dark, _light } from "../../Theme";
import { t } from "./../translations/i18n";
import Footer from "./Footer";

export default function CenterSpinner() {
    return (
        <Center size={"100%"} _dark={_dark} _light={_light}>
            <VStack space={4}>
                <Spinner size={"lg"} color={"primary.500"} />
                <Heading
                    w={"100%"}
                    size={"lg"}
                    textAlign={"center"}
                    color={"primary.500"}
                >
                    {t("Loading")}
                </Heading>
            </VStack>
            <Center position={"absolute"} bottom={0}>
                <Footer />
            </Center>
        </Center>
    );
}
