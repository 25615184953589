function removeArabicText(text: string) {
  return text.replace(/[\u0600-\u06FF]/g, "").trim();
}

function removeEnglishText(text: string) {
  return text.replace(/[a-zA-Z0-9]/g, "").trim();
}

function removeSpecialCharacters(text: string) {
  return text.replace(/[^a-zA-Z0-9\u0600-\u06FF ]/g, "").trim();
}

export function getLanguageText(languageCode: string, text: string) {
  if (!text) return null;
  const textWithoutSpecialCharacters = removeSpecialCharacters(text);
  if (languageCode === "ar") {
    return removeEnglishText(textWithoutSpecialCharacters);
  } else {
    return removeArabicText(textWithoutSpecialCharacters);
  }
}

export function getArabicAndEnglishTextDistanceName(deliveryDistance: any) {
  // convert m to km
  const { value } = deliveryDistance?.distance;
  const km = Math.round(value / 1000);
  const str = km.toString();

  let name = `Delivery Distance Surcharge ${str} km | `;
  name += `رسوم التوصيل  ${str.replace(/[0-9]/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d]) + " كيلومتر"}`;

  return name;
};

export function getCalculatedDeliveryServiceCharge(
  calculateFee: any,
  deliveryDistance: any,
  currency: string
) {
  return {
    name: getArabicAndEnglishTextDistanceName(
      deliveryDistance
    ),
    amountMoney: {
      amount: Number(
        (
          calculateFee.distance *
          (deliveryDistance?.distance
            ?.value /
            1000) +
          calculateFee?.duration *
          (deliveryDistance?.duration
            ?.value /
            60)
        ).toFixed(0)
      ),
      currency: currency,
    },
    calculationPhase: "SUBTOTAL_PHASE",
    taxable: true,
    scope: "ORDER",
  }

}
// return the phone number without the calling code, spaces, leading zeros, and special characters
export function getPhoneNumber(phoneNumber: string, callingCode: string) {
  return phoneNumber
    .replace(callingCode, "")
    .replace(/[^0-9 ]/g, "")
    .replace(/\s/g, "")
    .replace(/^0+/, "");
}