import { createSlice } from '@reduxjs/toolkit';

export const manageItems = createSlice({
	name: 'location',
	initialState: null,
	reducers: {
		setLocation: (state: any, action: any) => {
			if (action.payload) {
				return { ...action.payload };
			}
			return { ...state };
		},
	},
});

// Action creators are generated for each case reducer function
export const { setLocation } = manageItems.actions;

export default manageItems.reducer;
