import { Box } from "native-base";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import CenterSpinner from "../components/CenterSpinner";
import Footer from "../components/Footer";
import ModalTable from "../components/ModalTable";
import useLocation from "../hooks/useLocation";
import {
    clearCart,
    setPromoCode,
    setTableNumber,
} from "../redux/reducers/manageCart";
import { setLanguage } from "../redux/reducers/manageLanguage";
import i18n, { t } from "../translations/i18n";
import { ReduxState } from "../utils/types";
const ItemFlatList = lazy(() => import("../components/ItemFlatList"));

export default function ItemsScreen({ navigation, route }: any) {
    const cart = useSelector((s: ReduxState) => s.cart);
    const { selectedTableNumber } = cart;

    const dispatch = useDispatch();

    const { locationId, tableNumber, promoCode, language } = route.params;
    const [isLoadingData, setIsLoadingData] = useState(true);
    const { location, items } = useLocation(locationId);

    // handling the table number via url
    const handleTableNumber = useCallback(() => {
        const tableNum = tableNumber ? tableNumber : selectedTableNumber;
        if (tableNum) {
            dispatch(setTableNumber(tableNum));
        } else {
            dispatch(setTableNumber(0));
        }
    }, []);

    // handling promo code via url
    const handlePromoCode = useCallback(() => {
        if (promoCode) {
            dispatch(setPromoCode(promoCode));
        } else {
            dispatch(setPromoCode(""));
        }
        //TODO: promo code validation
    }, []);

    const loadData = async () => {
        try {
            setIsLoadingData(true);
            // location set in the previous screen
            if (location?.id !== locationId) {
                dispatch(clearCart());
            }
            setIsLoadingData(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // hangling the language change using url
        i18n.locale = language;
        dispatch(setLanguage(language));
        handleTableNumber();
        handlePromoCode();
        navigation.setOptions({ title: t("Products") });
        loadData();
    }, [location]);

    if (
        isLoadingData ||
        !location ||
        location?.id !== locationId ||
        !items.length
    ) {
        return <CenterSpinner />;
    }

    return (
        <Box
            flex={1}
            width={Dimensions.get("window").width}
            height={"100%"}
            _light={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            _dark={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            pt={0}
            alignItems="center"
        >
            <ModalTable tableNumber={tableNumber} />
            <Suspense fallback={<CenterSpinner />}>
                <ItemFlatList />
            </Suspense>
            <Footer />
        </Box>
    );
}
