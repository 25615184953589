import React from "react";
import store, { persistor } from "./src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { NativeBaseProvider } from "native-base";
import { Provider } from "react-redux";
// Setup the translation and font
import {
    Tajawal_200ExtraLight,
    Tajawal_300Light,
    Tajawal_400Regular,
    Tajawal_500Medium,
    Tajawal_700Bold,
    Tajawal_800ExtraBold,
    Tajawal_900Black,
    useFonts,
} from "@expo-google-fonts/tajawal";
// Setup the theme
import { theme } from "./Theme";
// Import Navigation/Screens
import Navigation from "./Navigation";
// Providers:
import { AuthProvider } from "./src/providers/AuthProvider";
import UpdateProvider from "./src/providers/UpdateProvider";

export default function App() {
    const [fontsLoaded] = useFonts({
        Tajawal_200ExtraLight,
        Tajawal_300Light,
        Tajawal_400Regular,
        Tajawal_500Medium,
        Tajawal_700Bold,
        Tajawal_800ExtraBold,
        Tajawal_900Black,
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <NativeBaseProvider theme={theme}>
                    <AuthProvider>
                        <Navigation />
                        <UpdateProvider />
                    </AuthProvider>
                </NativeBaseProvider>
            </PersistGate>
        </Provider>
    );
}
