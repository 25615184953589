import LottieView from "lottie-react-native";
import { Center, Heading, Text } from "native-base";
import React from "react";

export default function NotFoundScreen({ route }) {
    return (
        <Center height={"100%"}>
            <LottieView
                style={{ padding: 50 }}
                source={require("../assets/lottie/404.json")}
                autoPlay
            />

            <Heading p={2}>This screen doesn't exist!</Heading>
            <Text p={4} size={"sm"} textAlign={"center"} flexWrap={"wrap"}>
                https://app.jaicome.com{route.path}
            </Text>
        </Center>
    );
}
