import { MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import {
    Badge,
    Center,
    Divider,
    HStack,
    Heading,
    Icon,
    PresenceTransition,
    Pressable,
    Spacer,
    Stack,
    Text,
} from "native-base";
import React from "react";
import i18n from "../../translations/i18n";
import { OrderStutus } from "./utils";

interface OrderStutusStepsProps {
    currentStatus: string;
    updatedAt: string[];
    onToggle: () => void;
    isDelivery: boolean;
    isOnlinePayment?: boolean;
}

const OrderStutusSteps = (prop: OrderStutusStepsProps) => {
    let orderStatus = OrderStutus[prop.currentStatus];
    if (prop.isDelivery) {
        orderStatus = OrderStutus[`DELIVERY_${prop.currentStatus}`];
    }

    const updatedAt = prop.updatedAt.map((date) => {
        return new Date(date).toLocaleString();
    });

    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";
    const textAlign = i18n.locale === "ar" ? "right" : "left";

    const getCurrentOrderStatus = (status: string) => {
        switch (status) {
            case "PROPOSED":
                return {
                    text: t("Pending"),
                    colorScheme: "info",
                };
            case "RESERVED":
                return {
                    text: t("In Progress"),
                    colorScheme: "warning",
                };
            case "PREPARED":
                return {
                    text: t("Ready"),
                    colorScheme: "success",
                };
            case "COMPLETED":
                return {
                    text: t("Picked Up"),
                    colorScheme: "success",
                };
            default:
                return { text: t("Canceled"), colorScheme: "danger" };
        }
    };

    const { colorScheme, text } = getCurrentOrderStatus(prop.currentStatus);

    const paymentStatus = () => {
        if (prop.isOnlinePayment) {
            if (prop.currentStatus !== "CANCELED") {
                return { text: t("Paid Online"), colorScheme: "success" };
            } else {
                return { text: t("Refunded"), colorScheme: "info" };
            }
        }
        return { text: t("Unpaid"), colorScheme: "warning" };
    };

    return (
        <Stack width={"95%"} p={3} m={2}>
            <HStack flexDirection={flexDirection} width={"100%"} space={2}>
                <Heading size={"md"} textAlign={textAlign} my={3}>
                    {t("Order Status")}
                </Heading>
                <Spacer />
                <Pressable onPress={() => prop.onToggle()}>
                    <Badge
                        colorScheme={colorScheme}
                        size={"xs"}
                        my={3}
                        borderRadius={14}
                    >
                        {text}
                    </Badge>
                </Pressable>
                <Badge
                    colorScheme={paymentStatus().colorScheme}
                    size={"xs"}
                    my={3}
                    borderRadius={14}
                >
                    {paymentStatus().text}
                </Badge>
            </HStack>
            {Object.keys(orderStatus).map((status, index) => {
                return (
                    <PresenceTransition
                        key={index}
                        visible={true}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                duration: 200 * (index + 1),
                            },
                        }}
                    >
                        <HStack key={index} flexDirection={flexDirection}>
                            <Center>
                                <Icon
                                    as={MaterialCommunityIcons}
                                    name={orderStatus[status].icon}
                                    size={"lg"}
                                    color={orderStatus[status].color}
                                    my={2}
                                />
                                {orderStatus[status].hasDivider && (
                                    <Divider
                                        orientation="vertical"
                                        bg={orderStatus[status].color}
                                        h={"18px"}
                                        thickness={2}
                                    />
                                )}
                            </Center>
                            <Stack px={"38px"} width={"100%"}>
                                <Heading
                                    mt={2}
                                    size={"sx"}
                                    width={"100%"}
                                    color={
                                        orderStatus[status].color === "gray.200"
                                            ? "gray.200"
                                            : undefined
                                    }
                                >
                                    {t(orderStatus[status].status)}
                                </Heading>
                                {updatedAt[index] && (
                                    <HStack
                                        flexDirection={flexDirection}
                                        width={"100%"}
                                        minHeight={"15px"}
                                    >
                                        <Icon
                                            as={MaterialCommunityIcons}
                                            name="clock-outline"
                                            size={"xs"}
                                            color={"primary.300"}
                                        />
                                        <Text
                                            fontSize={"xs"}
                                            color={"gray.400"}
                                            px={1}
                                            width={"100%"}
                                            textAlign={textAlign}
                                        >
                                            {updatedAt[index]}
                                        </Text>
                                    </HStack>
                                )}
                            </Stack>
                        </HStack>
                    </PresenceTransition>
                );
            })}
        </Stack>
    );
};

export default OrderStutusSteps;
