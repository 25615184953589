// import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

/**
 * Platform build version
 */
export const buildVersion = DeviceInfo.getVersion().toString();
/**
 * Platform push version
 */
// const pushAndroid = 1;
// const pushIos = 1;

/**
 * Get push version by platform
 */
// function getPlatformPushVersion() {
//   switch (Platform.OS) {
//     case 'android':
//       return `${pushAndroid}`;
//     case 'ios':
//       return `${pushIos}`;
//     default:
//       return '';
//   }
// }

// export const version = `v${buildVersion}.${getPlatformPushVersion()}`;
export const appVersion = `v${buildVersion}`;
