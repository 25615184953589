import LottieView from "lottie-react-native";
import { Button, Center, Heading, Spacer, Text } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { _dark, _light } from "../../Theme";
import { t } from "../translations/i18n";

export default function InternetConnection() {
    return (
        <Center
            _dark={_dark}
            _light={_light}
            height={"100%"}
            width={"full"}
            paddingX={50}
        >
            <Spacer />
            <LottieView
                style={{
                    paddingHorizontal: 50,
                    width: 280,
                    height: 280,
                    justifyContent: "center",
                    alignItems: "center",
                }}
                source={require("../assets/lottie/no-internet.json")}
                autoPlay
                loop={false}
            />

            <Heading p={2} size={"md"}>
                {t(`No Internet Connection`)}
            </Heading>
            <Text p={2}>
                {t("Please check your internet connection and try again")}
            </Text>

            {Platform.OS !== "web" ? null : (
                <Button onPress={() => window.location.reload()}>
                    {t("Retry")}
                </Button>
            )}
            <Spacer />
        </Center>
    );
}
