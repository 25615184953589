import LottieView from "lottie-react-native";
import { Actionsheet, Button, Center, Heading, VStack } from "native-base";
import { _light } from "../../Theme";
import { t } from "../translations/i18n";
import { priceFormat } from "../utils";
import { Delivery } from "../utils/types";

interface Props {
    isOpen: boolean;
    closeText: string;
    onClose: () => any;
    completeText: string;
    onComplete: () => any;
    deliveryConfig: Delivery;
}

const DeliverySheet = ({
    isOpen,
    closeText,
    onClose,
    completeText,
    onComplete,
    deliveryConfig,
}: Props) => {
    return (
        <Actionsheet isOpen={isOpen} onClose={onClose} hideDragIndicator>
            <Actionsheet.Content _light={_light} _ios={{ marginBottom: -5 }}>
                <Center>
                    <LottieView
                        style={{ width: 125, height: 125 }}
                        source={require("../assets/lottie/warning.json")}
                        loop
                        autoPlay
                    />
                    <Heading fontSize="lg" fontWeight="bold">
                        {t("Minimum order amount for delivery")}
                    </Heading>
                    <Heading fontSize="4xl" fontWeight="bold" paddingTop={2}>
                        {priceFormat(deliveryConfig?.minAmount)}
                    </Heading>
                </Center>

                <VStack space={2} width={"100%"} paddingX={2}>
                    <Button
                        size={"lg"}
                        width={"100%"}
                        borderRadius={"xl"}
                        colorScheme={"tertiary"}
                        _text={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: -1,
                        }}
                        onPress={onComplete}
                    >
                        {t(completeText)}
                    </Button>
                    <Button
                        size={"lg"}
                        width={"100%"}
                        borderTopRadius={"xl"}
                        borderBottomRadius={"2xl"}
                        colorScheme="rose"
                        variant={"outline"}
                        _text={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: -1,
                        }}
                        onPress={onClose}
                    >
                        {t(closeText)}
                    </Button>
                </VStack>
            </Actionsheet.Content>
        </Actionsheet>
    );
};

export default DeliverySheet;
