import { Image } from "expo-image";
import {
    Box,
    HStack,
    Pressable,
    Spacer,
    Stack,
    Text,
    VStack,
} from "native-base";
import React from "react";
import { _light } from "../../../Theme";
import i18n, { t } from "../../translations/i18n";
import { priceFormat } from "../../utils";
import { getLanguageText } from "../../utils/textFormat";

export default function OrderLineItem({ item, index, onPress }) {
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";
    const textAlign = i18n.locale === "ar" ? "right" : "left";

    return (
        <Pressable
            key={index}
            onPress={onPress}
            _dark={{ bg: "#262626" }}
            _light={_light}
            borderBottomColor="trueGray.200"
            borderBottomWidth={1}
        >
            <Box pl="4" pr="5" py="2" w="100%">
                <HStack
                    flexDirection={flexDirection}
                    alignItems="center"
                    space={3}
                >
                    <Image
                        style={{ width: 48, height: 48, borderRadius: 10 }}
                        source={{ uri: item.url }}
                        aria-label={`item-image-${item.data.name}`}
                    />
                    <VStack maxWidth={"59%"} flexWrap={"wrap"}>
                        <Stack
                            flexDirection={flexDirection}
                            alignItems={"center"}
                            maxWidth={"100%"}
                            flexWrap={"wrap"}
                        >
                            <Text
                                color="coolGray.800"
                                _dark={{
                                    color: "warmGray.50",
                                }}
                                fontSize={"md"}
                                bold
                                lineBreakMode={"clip"}
                                numberOfLines={2}
                            >
                                {getLanguageText(i18n.locale, item.data.name)}
                            </Text>
                            <Text
                                color="coolGray.600"
                                mx={1}
                                fontSize="xs"
                                _dark={{
                                    color: "warmGray.200",
                                }}
                            >
                                {getLanguageText(
                                    i18n.locale,
                                    item.data.variationName
                                )}
                            </Text>
                        </Stack>

                        <HStack flexDirection={flexDirection}>
                            <Text
                                color="coolGray.600"
                                _dark={{
                                    color: "warmGray.200",
                                }}
                                fontSize="xs"
                                textAlign={textAlign}
                            >
                                {t("Qty:")}
                            </Text>
                            <Text
                                color="coolGray.600"
                                _dark={{
                                    color: "warmGray.200",
                                }}
                                fontSize="xs"
                                textAlign={textAlign}
                                mx={1}
                            >
                                {item.data.quantity}
                            </Text>
                        </HStack>
                    </VStack>
                    <Spacer />
                    <Text
                        color="coolGray.800"
                        _dark={{
                            color: "warmGray.50",
                        }}
                        fontSize={"md"}
                        bold
                        alignSelf="flex-start"
                    >
                        {priceFormat(item.data.grossSalesMoney.amount)}
                    </Text>
                </HStack>
            </Box>
        </Pressable>
    );
}
