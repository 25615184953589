import { Fontisto } from "@expo/vector-icons";
import {
    Actionsheet,
    Button,
    Heading,
    HStack,
    Icon,
    ScrollView,
    useDisclose,
    VStack,
} from "native-base";
import React from "react";
import { BusinessHours } from "square";
import i18n, { t } from "../translations/i18n";
import { _dark, _light } from "../../Theme";

interface Props {
    businessHours: BusinessHours;
    colorScheme?: string;
    variant?: string;
}

// internal pseudo props to get more customizable components
export default function BusinessHoursProps({
    businessHours,
    colorScheme = "primary",
    variant = "ghost",
}: Props) {
    const { isOpen, onOpen, onClose } = useDisclose();

    if (!businessHours.periods) {
        return null;
    }

    //group by day
    const days = businessHours.periods.reduce((acc, period) => {
        const day = period.dayOfWeek;
        if (!acc[day]) {
            acc[day] = [];
        }
        acc[day].push(period);
        return acc;
    }, {} as { [key: string]: BusinessHours["periods"] });

    // current day as string SUN, MON, TUE, etc
    const currentDay = new Date()
        .toLocaleString("en-US", { weekday: "short" })
        .toUpperCase();

    return (
        <>
            <Button
                variant={"subtle"}
                rounded={"full"}
                colorScheme={"gray"}
                width={8}
                height={8}
                onPress={onOpen}
            >
                <Icon
                    as={Fontisto}
                    name="clock"
                    size={"sm"}
                    color={"gray.600"}
                />
            </Button>

            <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                    <Heading>{t("Business Hours")}</Heading>
                    <ScrollView
                        size={"full"}
                        showsVerticalScrollIndicator={false}
                    >
                        {Object.keys(days).map((day) => (
                            <Actionsheet.Item
                                key={day}
                                bg={currentDay === day ? "primary.200" : null}
                                borderRadius={5}
                                alignItems={
                                    i18n.locale === "ar"
                                        ? "flex-end"
                                        : "flex-start"
                                }
                            >
                                <HStack
                                    flexDirection={
                                        i18n.locale === "ar"
                                            ? "row-reverse"
                                            : "row"
                                    }
                                >
                                    <Heading
                                        size={"sm"}
                                        px={4}
                                        minW={"100px"}
                                        color={
                                            currentDay === day
                                                ? _light.bg
                                                : _dark.bg
                                        }
                                        textAlign={
                                            i18n.locale === "ar"
                                                ? "right"
                                                : "left"
                                        }
                                    >
                                        {t(day)}
                                    </Heading>
                                    <VStack>
                                        {days[day]?.map((period) => (
                                            <Heading
                                                size={"xs"}
                                                color={
                                                    currentDay === day
                                                        ? _light.bg
                                                        : _dark.bg
                                                }
                                                key={period.startLocalTime}
                                            >
                                                {`${period.startLocalTime?.slice(
                                                    0,
                                                    -3
                                                )} - ${period.endLocalTime?.slice(
                                                    0,
                                                    -3
                                                )}`}
                                            </Heading>
                                        ))}
                                    </VStack>
                                </HStack>
                            </Actionsheet.Item>
                        ))}
                    </ScrollView>
                </Actionsheet.Content>
            </Actionsheet>
        </>
    );
}
