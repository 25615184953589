import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, createStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import authReducer from '../reducers/auth';
import locationReducer from '../reducers/location';
import manageCartReducer from '../reducers/manageCart';
import manageItemsReducer from '../reducers/manageItems';
import manageLanguageReducer from '../reducers/manageLanguage';

const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
}

const rootReducer = combineReducers({
	location: locationReducer,
	cart: manageCartReducer,
	items: manageItemsReducer,
	language: manageLanguageReducer,
	auth: authReducer
})

const persistedReducer = persistReducer(persistConfig,
	rootReducer
)

export const store = createStore(persistedReducer);

export const persistor = persistStore(store)
export default store;