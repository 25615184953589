import { Text, View } from "native-base";
import React from "react";
import { OrderServiceCharge } from "square";
import i18n from "../../translations/i18n";
import { priceFormat } from "../../utils";
import { getLanguageText } from "../../utils/textFormat";

interface Props {
    isDelivery: boolean | undefined;
    enabledServiceCharge: boolean | undefined;
    serviceCharges: OrderServiceCharge[] | [] | undefined;
}

export default function ServiceCharges({
    isDelivery,
    enabledServiceCharge,
    serviceCharges,
}: Props) {
    const language = i18n.locale;
    const flexDirection = language === "ar" ? "row-reverse" : "row";

    if (!isDelivery && !enabledServiceCharge && !serviceCharges) return null;

    return (
        <>
            {serviceCharges?.map((serviceCharge: OrderServiceCharge) => {
                return (
                    <View
                        flexDirection={flexDirection}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        my={2}
                    >
                        <Text fontSize={"md"} bold>
                            {getLanguageText(
                                language,
                                serviceCharge.name as string
                            )}
                        </Text>
                        <Text
                            color="coolGray.800"
                            _dark={{
                                color: "warmGray.50",
                            }}
                            fontSize={"md"}
                            bold
                            alignSelf="flex-start"
                        >
                            {priceFormat(
                                serviceCharge?.amountMoney?.amount as bigint
                            )}
                        </Text>
                    </View>
                );
            })}
        </>
    );
}
