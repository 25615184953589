import React from "react";
// Setup Navigation
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { t } from "./src/translations/i18n";
// Setup the theme
import { colors } from "./Theme";

// Import Screens
import {
    CheckoutScreen,
    HomeScreen,
    ItemsScreen,
    LocationsScreen,
    NotFoundScreen,
    OrderStatusScreen,
    ProductScreen,
    SignupLocationScreen,
    PaymentScreen,
} from "./src/screens";

const Stack = createStackNavigator();

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: ["http://localhost:19006/", "https://app.jaicome.com"],
    config: {
        screens: {
            Locations: "locations/:language?",
            Home: ":language?/:locationId/:curbsideDetails?",
            Items: ":language?/:locationId/items/:tableNumber?/:promoCode?",
            Product: ":language?/:locationId/items/:tableNumber?/id/:itemId",
            Checkout: ":language?/checkout",
            OrderStatus: ":language?/order-status/:order_id",
            SignupLocation: "signup-location",
            Payment: ":language?/payment",
            NotFound: "*",
        },
    },
};

export default function Navigation() {
    return (
        <NavigationContainer linking={linking}>
            <Stack.Navigator>
                {/* <Stack.Screen options={{ headerTitle: () => <Image source={LogoSVG} size="sm" /> }} name="Home" component={HomeScreen} /> */}
                <Stack.Screen
                    options={{ headerShown: false }}
                    name={"Locations"}
                    component={LocationsScreen}
                />
                <Stack.Screen
                    options={{ headerShown: false }}
                    name={"Home"}
                    component={HomeScreen}
                />
                <Stack.Screen
                    name="Checkout"
                    component={CheckoutScreen}
                    options={{
                        title: t("Checkout"),
                        headerStyle: {
                            backgroundColor: colors.primary[500],
                        },
                        headerTintColor: "#fff",
                        headerTitleStyle: {
                            fontWeight: "bold",
                        },
                    }}
                />
                <Stack.Screen
                    name="OrderStatus"
                    component={OrderStatusScreen}
                    options={{
                        title: t("Order Status"),
                        headerShown: false,
                        headerLeft: () => null,
                        gestureEnabled: false,
                        headerStyle: {
                            backgroundColor: colors.primary[500],
                        },
                        headerTintColor: "#fff",
                        headerPressColor: "#fff",
                        headerTitleStyle: {
                            fontWeight: "bold",
                        },
                    }}
                />
                <Stack.Screen
                    name={"Product"}
                    options={{
                        title: t("Product"),
                        headerShown: false,
                        headerTintColor: "#fff",
                        headerPressColor: "#fff",
                        headerTitleStyle: {
                            fontWeight: "bold",
                        },
                    }}
                    component={ProductScreen}
                />
                <Stack.Screen
                    name="SignupLocation"
                    component={SignupLocationScreen}
                    options={{
                        title: t("Register to Jaicome"),
                        headerStyle: {
                            backgroundColor: "light",
                        },
                        headerTintColor: "#fff",
                        headerPressColor: "#fff",
                        headerTitleStyle: {
                            color: "black",
                            fontWeight: "bold",
                        },
                    }}
                />
                <Stack.Screen
                    name="Items"
                    component={ItemsScreen}
                    options={{
                        headerShown: false,
                    }}
                />
                <Stack.Screen
                    name="Payment"
                    component={PaymentScreen}
                    options={{
                        title: t("Payment"),
                        headerShown: false,
                        headerStyle: {
                            backgroundColor: colors.primary[500],
                        },
                        headerTintColor: "#fff",
                        headerPressColor: "#fff",
                        headerTitleStyle: {
                            fontWeight: "bold",
                        },
                    }}
                />
                <Stack.Screen
                    name="NotFound"
                    component={NotFoundScreen}
                    options={{
                        title: "Not Found",
                        headerShown: false,
                    }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    );
}
