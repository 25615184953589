import { Entypo } from "@expo/vector-icons";
import {
    Box,
    Checkbox,
    HStack,
    Heading,
    Icon,
    Pressable,
    Text,
    VStack,
    Actionsheet,
    Button,
    useDisclose,
    Radio,
} from "native-base";
import React from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";
import { _dark, _light } from "../../Theme";
import OrderLineItemModifier from "../interfaces/OrderLineItem/OrderLineItemModifier";
import i18n, { t } from "../translations/i18n";
import { priceFormat } from "../utils";
import { getLanguageText } from "../utils/textFormat";
import { Money, ReduxState } from "../utils/types";
import SoldOutBadge from "./SoldOutBadge";

export default function ItemModifiers({
    flexDirection,
    textAlign,
    modifier_lists,
    onChange,
}) {
    const [selectModifiers, setSelectModifiers] = React.useState(
        getModifier(modifier_lists) as any
    );

    const handleChange = (category_id, modifier_id, isTypeSingle, value) => {
        if (isTypeSingle) {
            const removeSelectModifiers =
                selectModifiers.map((data: any) =>
                    data.category_id === category_id
                        ? { ...data, isCheck: false }
                        : data
                ) || [];
            const updatedModifiers = removeSelectModifiers.map((data: any) =>
                data.modifier_id === modifier_id
                    ? { ...data, isCheck: value }
                    : data
            );
            setSelectModifiers(updatedModifiers);
            onChange(getCheckedModifiers(updatedModifiers));
        } else {
            const updatedModifiers = selectModifiers.map((data: any) =>
                data.modifier_id === modifier_id
                    ? { ...data, isCheck: value }
                    : data
            );
            setSelectModifiers(updatedModifiers);
            onChange(getCheckedModifiers(updatedModifiers));
        }
    };

    if (!modifier_lists) return <></>;

    return (
        <Box
            alignItems="center"
            width={"100%"}
            py={1}
            _dark={_dark}
            _light={_light}
        >
            {modifier_lists.map((modifier: any, index: string) => (
                <Modifier
                    key={index}
                    modifier={modifier}
                    handleChange={handleChange}
                    flexDirection={flexDirection}
                    textAlign={textAlign}
                />
            ))}
        </Box>
    );
}

function Modifier({ flexDirection, textAlign, modifier, handleChange }) {
    const { isOpen, onOpen, onClose } = useDisclose();
    const location = useSelector((s: ReduxState) => s.location);
    const [groupValue, setGroupValue] = React.useState([]);

    const id = modifier.id;
    const name = modifier.name;
    const modifier_options = modifier.modifier_options;
    const isTypeSingle = modifier.selectionType === "SINGLE"; // two options: "MULTIPLE" || "SINGLE"

    const selectionCount = groupValue.length;

    if (modifier_options.length > 4) {
        return (
            <VStack width={"100%"}>
                <HStack
                    flexDirection={flexDirection}
                    alignItems="baseline"
                    justifyContent={"space-between"}
                    py={2}
                >
                    <Heading textAlign={textAlign} fontSize="lg">
                        {getLanguageText(i18n.locale, name)}
                    </Heading>
                    <Text>
                        {t("Select")} ({selectionCount}/
                        {isTypeSingle ? 1 : modifier_options.length})
                    </Text>
                </HStack>

                {/* Show the selected options */}
                <HStack
                    flexDirection={flexDirection}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                >
                    {modifier_options.map((option: any, index: string) => {
                        const optionId = option.id;
                        const optionName = option.name;
                        const optionPrice = option.price_money.amount;
                        const onByDefault = option.on_by_default; // TODO: handle on_by_default option
                        const sold_out = option.location_overrides?.some(
                            (location_override) =>
                                location_override.location_id === location.id &&
                                location_override.sold_out
                        );

                        if (groupValue.includes(optionId)) {
                            return (
                                <Pressable
                                    key={index}
                                    flexDirection={flexDirection}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                    p={1}
                                    bg={"muted.100"}
                                    onPress={() => {
                                        // remove the selected option
                                        const newValue = groupValue.filter(
                                            (e) => e !== optionId
                                        );
                                        setGroupValue(newValue);
                                        handleChange(
                                            modifier.id,
                                            optionId,
                                            isTypeSingle,
                                            !groupValue.includes(optionId)
                                        );
                                    }}
                                >
                                    <HStack
                                        flexDirection={flexDirection}
                                        alignItems={"center"}
                                        space={2}
                                    >
                                        <Icon
                                            size={"xs"}
                                            p={1}
                                            style={{
                                                width: 24,
                                                height: 24,
                                                borderRadius: 50,
                                                borderWidth: 2,
                                                borderColor: "primary.600",
                                            }}
                                            as={Entypo}
                                            name="check"
                                            color={"primary.600"}
                                        />
                                        <Text
                                            numberOfLines={1}
                                            alignItems="center"
                                            marginBottom={-1}
                                        >
                                            {getLanguageText(
                                                i18n.locale,
                                                optionName
                                            )}
                                        </Text>
                                    </HStack>

                                    <Text
                                        fontWeight={"bold"}
                                        color={"primary.500"}
                                        numberOfLines={1}
                                        alignItems="center"
                                        marginBottom={-1}
                                        minHeight={30}
                                        mt={2}
                                    >
                                        {optionPrice != 0
                                            ? `+${priceFormat(optionPrice)}`
                                            : t("Free")}
                                    </Text>
                                </Pressable>
                            );
                        }
                    })}
                </HStack>

                <Button
                    variant={"ghost"}
                    onPress={onOpen}
                    rightIcon={
                        textAlign === "right" ? (
                            <Icon as={Entypo} name="chevron-down" size={"md"} />
                        ) : null
                    }
                    leftIcon={
                        textAlign === "left" ? (
                            <Icon as={Entypo} name="chevron-down" size={"md"} />
                        ) : null
                    }
                    justifyContent={textAlign}
                    px={0}
                    _text={{ mb: -1 }}
                >
                    {t("Press to select or edit option")}
                </Button>

                <Actionsheet isOpen={isOpen} onClose={onClose}>
                    <Actionsheet.Content>
                        <Heading textAlign={textAlign} fontSize="lg">
                            {getLanguageText(i18n.locale, name)}
                        </Heading>
                        <Checkbox.Group
                            key={id}
                            width={"100%"}
                            px={4}
                            colorScheme="primary"
                            value={groupValue}
                            accessibilityLabel="pick an item"
                            size={"md"}
                        >
                            {modifier_options.map(
                                (option: any, index: string) => {
                                    const optionId = option.id;
                                    const optionName = option.name;
                                    const optionPrice =
                                        option.price_money.amount;
                                    const sold_out =
                                        option.location_overrides?.some(
                                            (location_override) =>
                                                location_override.location_id ===
                                                    location.id &&
                                                location_override.sold_out
                                        );

                                    return (
                                        <Pressable
                                            key={index}
                                            width={"100%"}
                                            justifyContent={"space-between"}
                                            disabled={sold_out}
                                            alignItems="center"
                                            flexDirection={flexDirection}
                                            onPress={() => {
                                                const newValue =
                                                    groupValue.includes(
                                                        optionId
                                                    )
                                                        ? groupValue.filter(
                                                              (e) =>
                                                                  e !== optionId
                                                          )
                                                        : isTypeSingle
                                                        ? [optionId]
                                                        : [
                                                              ...groupValue,
                                                              optionId,
                                                          ];

                                                setGroupValue(newValue);
                                                handleChange(
                                                    modifier.id,
                                                    optionId,
                                                    isTypeSingle,
                                                    !groupValue.includes(
                                                        optionId
                                                    )
                                                );
                                                // Close the actionsheet if the modifier is single
                                                if (isTypeSingle) onClose();
                                            }}
                                        >
                                            <HStack
                                                flexDirection={flexDirection}
                                                alignItems={"center"}
                                                space={2}
                                            >
                                                <Checkbox
                                                    key={optionId}
                                                    isDisabled={
                                                        Platform.OS !== "web" ||
                                                        sold_out
                                                    }
                                                    accessibilityLabel={
                                                        optionName
                                                    }
                                                    flexDirection={
                                                        flexDirection
                                                    }
                                                    _dark={{
                                                        background: _dark.bg,
                                                    }}
                                                    _light={{
                                                        background: _light.bg,
                                                    }}
                                                    alignItems="center"
                                                    value={optionId}
                                                    _checked={{
                                                        _icon: {
                                                            color: "primary.600",
                                                            size: "12px",
                                                            margin: 0.5,
                                                        },
                                                    }}
                                                    rounded={"full"}
                                                    icon={
                                                        <Icon
                                                            as={Entypo}
                                                            name="check"
                                                        />
                                                    }
                                                />
                                                <Text
                                                    numberOfLines={1}
                                                    alignItems="center"
                                                    marginBottom={-1}
                                                >
                                                    {getLanguageText(
                                                        i18n.locale,
                                                        optionName
                                                    )}
                                                </Text>
                                            </HStack>
                                            {sold_out ? (
                                                <SoldOutBadge />
                                            ) : (
                                                <Text
                                                    fontWeight={"bold"}
                                                    color={"primary.500"}
                                                    numberOfLines={1}
                                                    alignItems="center"
                                                    marginBottom={-1}
                                                    minHeight={30}
                                                    mt={2}
                                                >
                                                    {optionPrice != 0
                                                        ? `+${priceFormat(
                                                              optionPrice
                                                          )}`
                                                        : t("Free")}
                                                </Text>
                                            )}
                                        </Pressable>
                                    );
                                }
                            )}
                        </Checkbox.Group>
                        {!isTypeSingle && (
                            <Button onPress={onClose} mt={2} width={"100%"}>
                                {t("Close")}
                            </Button>
                        )}
                    </Actionsheet.Content>
                </Actionsheet>
            </VStack>
        );
    }

    return (
        <VStack width={"100%"}>
            <HStack
                flexDirection={flexDirection}
                alignItems="baseline"
                justifyContent={"space-between"}
                py={2}
            >
                <Heading textAlign={textAlign} fontSize="lg">
                    {getLanguageText(i18n.locale, name)}
                </Heading>
                <Text>
                    {t("Select")}
                    {isTypeSingle
                        ? ` (${selectionCount}/1)`
                        : ` (${selectionCount}/${modifier_options.length})`}
                </Text>
            </HStack>

            <Checkbox.Group
                key={id}
                colorScheme="primary"
                value={groupValue}
                accessibilityLabel="pick an item"
                size={"md"}
            >
                {modifier_options.map((option: any, index: string) => {
                    const optionId = option.id;
                    const optionName = option.name;
                    const optionPrice = option.price_money.amount;
                    const sold_out = option.location_overrides?.some(
                        (location_override) =>
                            location_override.location_id === location.id &&
                            location_override.sold_out
                    );

                    return (
                        <Pressable
                            key={index}
                            width={"100%"}
                            justifyContent={"space-between"}
                            disabled={sold_out}
                            alignItems="center"
                            flexDirection={flexDirection}
                            onPress={() => {
                                const newValue = groupValue.includes(optionId)
                                    ? groupValue.filter((e) => e !== optionId)
                                    : isTypeSingle
                                    ? [optionId]
                                    : [...groupValue, optionId];

                                setGroupValue(newValue);
                                handleChange(
                                    modifier.id,
                                    optionId,
                                    isTypeSingle,
                                    !groupValue.includes(optionId)
                                );
                            }}
                        >
                            <HStack
                                flexDirection={flexDirection}
                                alignItems={"center"}
                                space={2}
                            >
                                <Checkbox
                                    key={optionId}
                                    isDisabled={
                                        Platform.OS !== "web" || sold_out
                                    }
                                    accessibilityLabel={optionName}
                                    flexDirection={flexDirection}
                                    _dark={{ background: _dark.bg }}
                                    _light={{ background: _light.bg }}
                                    alignItems="center"
                                    value={optionId}
                                    _checked={{
                                        _icon: {
                                            color: "primary.600",
                                            size: "12px",
                                            margin: 0.5,
                                        },
                                    }}
                                    rounded={"full"}
                                    icon={<Icon as={Entypo} name="check" />}
                                />
                                <Text
                                    numberOfLines={1}
                                    alignItems="center"
                                    marginBottom={-1}
                                >
                                    {getLanguageText(i18n.locale, optionName)}
                                </Text>
                            </HStack>
                            {sold_out ? (
                                <SoldOutBadge />
                            ) : (
                                <Text
                                    fontWeight={"bold"}
                                    color={"primary.500"}
                                    numberOfLines={1}
                                    alignItems="center"
                                    marginBottom={-1}
                                    minHeight={30}
                                    mt={2}
                                >
                                    {optionPrice != 0
                                        ? `+${priceFormat(optionPrice)}`
                                        : t("Free")}
                                </Text>
                            )}
                        </Pressable>
                    );
                })}
            </Checkbox.Group>
        </VStack>
    );
}

// Helpers functions
function getModifier(modifier_lists) {
    if (!modifier_lists) return [];

    let selectModifiers = [] as any;

    modifier_lists.map((modifier: any, index: string) => {
        const modifierGroup_id = modifier.id;

        modifier.modifier_options.map((option: any, index: string) => {
            const id = option.id;
            const name = option.name;
            const price = option.price_money.amount;
            const currency = option.price_money?.currency;

            const selection = {
                category_id: modifierGroup_id,
                modifier_id: id,
                isCheck: false as boolean, // to check if the option is check
                option: {
                    catalogObjectId: id,
                    name: name,
                    basePriceMoney: {
                        amount: price,
                        currency: currency,
                    } as Money,
                } as OrderLineItemModifier,
            };

            selectModifiers.push(selection);
        });
    });
    return selectModifiers;
}

function getCheckedModifiers(selectModifiers: []) {
    let checkedOptions = [] as any;
    selectModifiers.map((data: any) => {
        if (data.isCheck === true) checkedOptions.push(data.option);
    });
    return checkedOptions;
}
