import { Entypo } from "@expo/vector-icons";
import {
    Checkbox,
    Heading,
    HStack,
    Icon,
    Pressable,
    Stack,
    Text,
} from "native-base";
import React, { useEffect } from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";
import { _dark, _light } from "../../Theme";
import i18n, { t } from "../translations/i18n";
import { priceFormat, variationsSoldOut } from "../utils";
import { getLanguageText } from "../utils/textFormat";
import { ReduxState } from "../utils/types";
import SoldOutBadge from "./SoldOutBadge";

export default function ItemVariations({
    flexDirection,
    textAlign,
    variations,
    onChange,
}) {
    const locationId = useSelector((s: ReduxState) => s.location.id);

    // Check if all variations are sold out and get the status for each variation
    const sold_out = variationsSoldOut(variations, locationId);
    // the default selected variation
    const [selected, setSelected] = React.useState("");

    // handel the change of the selected variation
    const handleChange = (id) => {
        const selectedVariation = variations.filter(
            (option) => option.id == id
        )[0];
        const selected = {
            id: selectedVariation.id,
            name: selectedVariation.item_variation_data.name,
            price_money: {
                amount: Number(
                    selectedVariation.item_variation_data.price_money.amount
                ),
            },
        };
        onChange(selected);
        setSelected(id);
    };

    useEffect(() => {
        // Set the default value for the item price as the 1st avalibale 'variation'
        // get tht first id of that is not sold out and set it as the default value from sold_out object
        const defaultValue = Object.keys(sold_out).filter(
            (id) => !sold_out[id]
        )[0];
        handleChange(defaultValue);
    }, []);

    // Show nothing if there is not variations
    if (!variations || variations.length === 1) return <></>;

    // List all variations to select only one option
    return (
        <Checkbox.Group
            key={"variation-group"}
            colorScheme="primary"
            value={[selected]}
            accessibilityLabel="pick an item"
            size={"md"}
        >
            <Heading textAlign={textAlign} fontSize="lg" py={2} width={"100%"}>
                {t("Options")}
            </Heading>
            <Stack space={1} width={"100%"}>
                {variations.map((item: any, index: string) => {
                    const id = item.id;
                    const name = item.item_variation_data.name;
                    const price = item.item_variation_data?.price_money?.amount;

                    // Skip the option if it has no price
                    if (!price) return;

                    return (
                        <Pressable
                            key={index}
                            width={"100%"}
                            justifyContent={"space-between"}
                            disabled={sold_out[id]}
                            alignItems="center"
                            flexDirection={flexDirection}
                            onPress={() => {
                                handleChange(id);
                            }}
                        >
                            <HStack
                                flexDirection={flexDirection}
                                alignItems={"center"}
                                space={2}
                            >
                                <Checkbox
                                    key={id}
                                    isDisabled={
                                        Platform.OS !== "web" || sold_out[id]
                                    }
                                    accessibilityLabel={name}
                                    flexDirection={flexDirection}
                                    _dark={{ background: _dark.bg }}
                                    _light={{ background: _light.bg }}
                                    alignItems="center"
                                    value={id}
                                    _checked={{
                                        _icon: {
                                            color: "primary.600",
                                            size: "12px",
                                            margin: 0.5,
                                        },
                                    }}
                                    rounded={"full"}
                                    icon={<Icon as={Entypo} name="check" />}
                                />
                                <Text
                                    numberOfLines={1}
                                    alignItems="center"
                                    marginBottom={-1}
                                >
                                    {getLanguageText(i18n.locale, name)}
                                </Text>
                            </HStack>
                            {sold_out[id] ? (
                                <SoldOutBadge />
                            ) : (
                                <Text
                                    fontWeight={"bold"}
                                    color={"primary.500"}
                                    numberOfLines={1}
                                    alignItems="center"
                                    marginBottom={-1}
                                    minHeight={30}
                                    mt={2}
                                >
                                    {priceFormat(price)}
                                </Text>
                            )}
                        </Pressable>
                    );
                })}
            </Stack>
        </Checkbox.Group>
    );
}
