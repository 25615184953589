import { createSlice } from "@reduxjs/toolkit";

export const manageItems = createSlice({
  name: "items",
  initialState: [],
  reducers: {
    setItems: (state: any, action: any) => {
      // state = [...action.payload.filter((item: any) => item.imageUrl)]; // filter out items without images
      state = [...action.payload];
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setItems } = manageItems.actions;

export default manageItems.reducer;
