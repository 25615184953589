// function to validate saudi phone number
export function validatePhone(callingCodes = "+966", phone: string) {
    switch (callingCodes) {
        case "+966":
            const phoneRegex = /^5\d{8}$/;
            return phoneRegex.test(phone);
        // Bahrain
        case "+973":
            const phoneRegexBH = /^3\d{7}$/;
            return phoneRegexBH.test(phone);
        // United Arab Emirates
        case "+971":
            const phoneRegexAE = /^5\d{8}$/;
            return phoneRegexAE.test(phone);
        // Kuwait
        case "+965":
            const phoneRegexKW = /^\d{8}$/;
            return phoneRegexKW.test(phone);
        // Oman
        case "+968":
            const phoneRegexOM = /^9\d{8}$/;
            return phoneRegexOM.test(phone);
        // Qatar
        case "+974":
            const phoneRegexQA = /^3\d{8}$/;
            return phoneRegexQA.test(phone);
        // Jordan
        case "+962":
            const phoneRegexJO = /^7\d{8}$/;
            return phoneRegexJO.test(phone);
        // Lebanon
        case "+961":
            const phoneRegexLB = /^7\d{8}$/;
            return phoneRegexLB.test(phone);
        // Egypt
        case "+20":
            const phoneRegexEG = /^1\d{9}$/;
            return phoneRegexEG.test(phone);
        // Morocco
        case "+212":
            const phoneRegexMA = /^6\d{8}$/;
            return phoneRegexMA.test(phone);
        // Algeria
        case "+213":
            const phoneRegexDZ = /^5\d{8}$/;
            return phoneRegexDZ.test(phone);
        // Tunisia
        case "+216":
            const phoneRegexTN = /^2\d{8}$/;
            return phoneRegexTN.test(phone);
        // Libya
        case "+218":
            const phoneRegexLY = /^9\d{8}$/;
            return phoneRegexLY.test(phone);
        // United States and Canada
        case "+1":
            const phoneRegexUS = /^\d{10}$/;
            return phoneRegexUS.test(phone);
        default:
            return false;
    }
}


export function validEmail(email?: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email) {
        return re.test(String(email).toLowerCase());
    }
    return false;
}
