import { useEffect } from 'react';
import { Platform } from 'react-native';
import { batch as ReduxBatch, useDispatch, useSelector } from "react-redux";
import { getCatalogList } from '../api/square';
import { getLocationInfo } from '../firebase/reads';
import { setLocation } from '../redux/reducers/location';
import { clearCart } from '../redux/reducers/manageCart';
import { setItems } from '../redux/reducers/manageItems';
import { ReduxState } from '../utils/types';
// import { setTheme } from '../../Theme';

export default function useLocation(id: string) {
    const dispatch = useDispatch();
    // cureent items
    const currentLocation = useSelector((state: ReduxState) => state.location);
    const currentItems = useSelector((state: ReduxState) => state.items);

    // setTheme(currentLocation?.theme);


    const loadItemsData = async (merchantId: string) => {
        try {
            if (!currentItems.length || Platform.OS === "web") {
                const items = await getCatalogList(merchantId);
                ReduxBatch(() => {
                    dispatch(setItems(items));
                });
            }

        } catch (error) {
            console.log(error);
        }
    };

    const loadLocationData = async (locationId: string) => {
        try {
            const location = await getLocationInfo(locationId);
            if (locationId !== currentLocation?.id || !currentItems.length)
                await dispatch(clearCart()) // clear cart when location changes or before loading the updated items

            if (location) {
                await loadItemsData(location?.merchantId);

                ReduxBatch(() => {
                    dispatch(setLocation(location as any));
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getlocation = async (locationId: string) => {
        try {
            // temporary fix for web: this loads the location data on every page using the hook
            if (Platform.OS === "web")
                await loadLocationData(locationId);
            else
                if (locationId !== currentLocation?.id || !currentItems.length)
                    await loadLocationData(locationId);


        } catch (error) {
            console.log(error);
        }
    };

    // Call getlocation only when id changes
    useEffect(() => {
        getlocation(id);
    }, [id]);

    const location = useSelector((state: ReduxState) => state.location);
    const items = useSelector((state: ReduxState) => state.items);

    return { location, items };
}
