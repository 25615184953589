import { Image } from "expo-image";
import { Box, HStack, Spacer, Stack, Text, VStack } from "native-base";
import React from "react";
import i18n, { t } from "../../translations/i18n";
import { priceFormat } from "../../utils";
import { getLanguageText } from "../../utils/textFormat";

export default function SummaryCart({
    textAlign,
    flexDirection,
    cart,
    lineItems,
}: any) {
    const data = cart.itemsList;

    return (
        <Box>
            {lineItems?.map((item, index) => {
                return (
                    <Box my={2} w="100%" key={item.uid}>
                        <HStack
                            flexDirection={flexDirection}
                            alignItems="center"
                            space={3}
                            w="100%"
                        >
                            <Image
                                style={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: 10,
                                }}
                                source={{
                                    uri: data[index]?.url,
                                    cacheKey: data[index]?.url,
                                }}
                                aria-label={`item-image-${item.name}`}
                            />
                            <VStack maxWidth={"58%"}>
                                <Stack
                                    flexDirection={flexDirection}
                                    alignItems={"center"}
                                    maxWidth={"100%"}
                                >
                                    <HStack
                                        flexDirection={flexDirection}
                                        color="coolGray.800"
                                        _dark={{
                                            color: "warmGray.50",
                                        }}
                                        alignItems={"center"}
                                        maxWidth={"100%"}
                                    >
                                        <HStack
                                            flexDirection={flexDirection}
                                            alignItems={"center"}
                                            m={1}
                                        >
                                            <Text>{item.quantity}</Text>
                                            <Text>x</Text>
                                        </HStack>
                                        <Text
                                            fontSize={"md"}
                                            bold
                                            flexWrap={"wrap"}
                                            lineBreakMode={"clip"}
                                            numberOfLines={2}
                                        >
                                            {getLanguageText(
                                                i18n.locale,
                                                item.name
                                            )}
                                        </Text>
                                    </HStack>
                                    <Text
                                        color="coolGray.600"
                                        mx={1}
                                        fontSize="xs"
                                        _dark={{
                                            color: "warmGray.200",
                                        }}
                                    >
                                        {item.variationName === "Regular"
                                            ? t("Regular")
                                            : getLanguageText(
                                                  i18n.locale,
                                                  item.variationName
                                              )}
                                    </Text>
                                </Stack>

                                {item.modifiers ? (
                                    <Text
                                        textAlign={textAlign}
                                        flexWrap={"wrap"}
                                        maxW={200}
                                        color="coolGray.600"
                                        _dark={{
                                            color: "warmGray.200",
                                        }}
                                        fontSize="xs"
                                    >
                                        {item.modifiers
                                            .map((modifier: any) =>
                                                getLanguageText(
                                                    i18n.locale,
                                                    modifier["name"]
                                                )
                                            )
                                            .join(", ")}
                                    </Text>
                                ) : null}

                                {item.note ? (
                                    <HStack flexDirection={flexDirection}>
                                        <Text
                                            textAlign={textAlign}
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                            fontSize="xs"
                                            mx={1}
                                        >
                                            {t("Note:")}
                                        </Text>
                                        <Text
                                            textAlign={textAlign}
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                            fontSize="xs"
                                        >
                                            {item.note}
                                        </Text>
                                    </HStack>
                                ) : null}
                            </VStack>
                            <Spacer />
                            <Text
                                color="coolGray.800"
                                _dark={{
                                    color: "warmGray.50",
                                }}
                                fontSize={"md"}
                                bold
                                alignSelf="flex-start"
                            >
                                {priceFormat(
                                    item.totalMoney.amount +
                                        (item?.totalDiscountMoney?.amount || 0)
                                )}
                            </Text>
                        </HStack>
                    </Box>
                );
            })}
        </Box>
    );
}
