import { useEffect, useState } from "react";
import * as Location from "expo-location";
import { useDispatch } from "react-redux";
import { setUserLocation } from "../redux/reducers/auth";
import { Platform, I18nManager } from "react-native";
import * as Updates from "expo-updates";

// NOTE: DevSettings is not available in web
let DevSettings;
if (Platform.OS !== 'web') {
    DevSettings = require('react-native').DevSettings;
}

export default function useUserLocation() {
    const dispatch = useDispatch();
    const [permission, setPermission] = useState<Location.PermissionStatus>(
        Location.PermissionStatus.UNDETERMINED
    );

    // Disable RTL mode if the device language set to Arabic
    const disableRTL = async () => {
        I18nManager.forceRTL(false);
        I18nManager.allowRTL(false);
    };

    // Reload the app after disable RTL mode
    const reloadRTLApp = async () => {
        if (Platform.OS != "web") {
            if (__DEV__ && I18nManager.isRTL) {
                disableRTL();
                DevSettings.reload(); // NOTE: relocad the app in development mode and RTL
            }
            if (!__DEV__ && I18nManager.isRTL) {
                disableRTL();
                await Updates.reloadAsync(); // NOTE: reload the app in production mode and RTL (reloadAsync is not working in development mode)
            }
        }
    };

    useEffect(() => {
        (async () => {
            await reloadRTLApp();
            let { status } = await Location.requestForegroundPermissionsAsync();
            setPermission(status);
        })();
    }, []);

    useEffect(() => {
        if (
            permission === Location.PermissionStatus.GRANTED ||
            Platform.OS === "web"
        ) {
            // Web:
            if (Platform.OS === "web" && typeof window !== "undefined") {
                window?.navigator?.geolocation.getCurrentPosition(
                    (lastPosition) => {
                        if (lastPosition?.coords) {
                            const location = {
                                latitude: lastPosition.coords.latitude,
                                longitude: lastPosition.coords.longitude,
                            };
                            dispatch(
                                setUserLocation({
                                    latitude: location?.latitude ?? 0,
                                    longitude: location?.longitude ?? 0,
                                })
                            );
                        }
                    },
                    () => { },
                    { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
                );
            } else {
                // Android & iOS:
                Location.getCurrentPositionAsync({
                    accuracy: Location.Accuracy.High,
                }).then((location) => {
                    // Dispatch location update:
                    dispatch(
                        setUserLocation({
                            latitude: location?.coords?.latitude ?? 0,
                            longitude: location?.coords?.longitude ?? 0,
                        })
                    );
                });
            }
        }
    }, [permission]);

    return null;
}
