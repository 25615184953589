import AsyncStorage from "@react-native-async-storage/async-storage";
import { createOrder } from "../../api/square";

export const createOrderAfterPayment = async (
    invoice_id: string,
    setOrderId: (v: string) => void,
    onDone?: (orderId: string) => void
) => {
    // * 1. Get order from async storage:
    const prepareOrderJson = await AsyncStorage.getItem("PrepareOrder");
    const prepareOrder = prepareOrderJson ? JSON.parse(prepareOrderJson) : null;

    // ? Check if order exists:
    if (!prepareOrder.checkoutState.order) {
        throw new Error("Order not found!");
    }

    // * 2. Create the order and send it to the API: If all is good, create the order and send it to square.
    const { orderId } = await createOrder(prepareOrder, invoice_id);
    setOrderId(orderId); // ? Set the order id to the state.

    // * 3. Remove the order from async storage after creating/sending the order to API
    await AsyncStorage.removeItem("PrepareOrder");

    // * 4. Done:
    onDone?.(orderId);
};
