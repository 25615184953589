import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { LatLngLiteral } from "@googlemaps/google-maps-services-js";
import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useNavigation } from "@react-navigation/native";
import {
    Center,
    Icon,
    IconButton,
    Modal,
    Text,
    useDisclose,
} from "native-base";
import React from "react";
import { Dimensions, Platform } from "react-native";
import { Location } from "../interfaces/Location";

interface MapProps {
    currentLocation: LatLngLiteral;
    withCurrnetLocationMarker?: boolean | true;
    withCircleRadius?: boolean | true;
    circleRadius?: number;
    zoom?: number;
    listLocation?: Location[];
    buttonTitle?: string;
    onLocationChange?: (location: LatLngLiteral) => void;
}

const containerStyle: React.CSSProperties = {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
};

const onLoad = (circle: google.maps.Circle) => {
    console.log("Circle onLoad circle: ", circle);
};

const onUnmount = (circle: google.maps.Circle) => {
    console.log("Circle onUnmount circle: ", circle);
};

const LocationsMap: React.FC<MapProps> = (MapProps) => {
    const navigation = useNavigation();

    const { isOpen, onClose, onToggle } = useDisclose();

    const circleOptions: google.maps.CircleOptions = {
        strokeColor: "#FF0000",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: MapProps.circleRadius || 4000,
        zIndex: 1,
    };

    // navigate to home screen and pass locationId
    const handleMarkerClick = (locationId: string) => {
        navigation.navigate("Home", {
            locationId,
        });
        onClose();
    };

    const randerLocations = (listLocation) => {
        if (!listLocation || !listLocation.length) {
            return null;
        }

        return listLocation.map((location) => {
            return (
                <Marker
                    key={`marker-${location.id}`}
                    onClick={() => {
                        handleMarkerClick(location.id);
                    }}
                    title={location.businessName}
                    icon={
                        {
                            url: location.logoUrl,
                            size: {
                                width: 50,
                                height: 50,
                            } as any,
                            scaledSize: {
                                width: 50,
                                height: 50,
                            } as any,
                        } as any
                    }
                    position={
                        {
                            lat: location.coordinates?.latitude,
                            lng: location.coordinates?.longitude,
                        } as LatLngLiteral
                    }
                />
            );
        });
    };

    return (
        <>
            <IconButton
                rounded="sm"
                variant="solid"
                colorScheme="yellow"
                size="sm"
                padding={1.5}
                icon={
                    <Icon
                        size="sm"
                        as={MaterialCommunityIcons}
                        name="map-search-outline"
                        color="primary.600"
                    />
                }
                onPress={() => {
                    // scroll to top if web
                    if (Platform.OS === "web") {
                        window.scrollTo(0, 0);
                    }
                    // open modal map
                    onToggle();
                }}
            />
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={"full"}
                maxHeight={"100%"}
            >
                <Modal.Body
                    maxHeight={"100%"}
                    borderTopRadius="0"
                    m={0}
                    p={0}
                    _scrollview={{ scrollEnabled: false }}
                >
                    {Platform.OS === "web" && (
                        <LoadScript googleMapsApiKey="AIzaSyBFXdYsihkVESTba0x7WZTyw2mK1gfO9lI">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={MapProps.currentLocation}
                                zoom={MapProps?.zoom || 12}
                                options={{
                                    disableDefaultUI: true,
                                }}
                            >
                                <IconButton
                                    shadow={3}
                                    borderRadius="xl"
                                    variant={"solid"}
                                    colorScheme={"danger"}
                                    position={"absolute"}
                                    top={3}
                                    left={3}
                                    _icon={{
                                        as: Ionicons,
                                        name: "close",
                                    }}
                                    onPress={() => onClose()}
                                />

                                {MapProps.currentLocation &&
                                    MapProps.withCurrnetLocationMarker && (
                                        <Center>
                                            <Marker
                                                position={
                                                    MapProps.currentLocation
                                                }
                                                draggable
                                                // onDragEnd={handleMarkerDragEnd}
                                            />
                                            <Text
                                                position={"absolute"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                textAlign={"center"}
                                                padding={2}
                                                top={3}
                                                right={3}
                                            >{`${MapProps.currentLocation.lat?.toFixed(
                                                3
                                            )}, ${MapProps.currentLocation.lng?.toFixed(
                                                3
                                            )}`}</Text>
                                        </Center>
                                    )}

                                {randerLocations(MapProps.listLocation)}

                                {MapProps.withCircleRadius &&
                                    MapProps.currentLocation && (
                                        <Circle
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                            center={MapProps.currentLocation}
                                            options={circleOptions}
                                        />
                                    )}
                            </GoogleMap>
                        </LoadScript>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LocationsMap;
