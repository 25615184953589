import { MaterialIcons } from "@expo/vector-icons";
import {
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Icon,
    Pressable,
    Text,
    VStack,
} from "native-base";
import React from "react";
import { SwipeListView } from "react-native-swipe-list-view";
import { useDispatch, useSelector } from "react-redux";
import { _light } from "../../../Theme";
import { clearCart, removeItem } from "../../redux/reducers/manageCart";
import i18n, { t } from "../../translations/i18n";
import OrderLineItem from "./OrderItemLine";

export default function CartContent() {
    const textAlign = i18n.locale === "ar" ? "right" : "left";
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";

    const dispatch = useDispatch();

    return (
        <Center h="290px" w="100%">
            <Box _light={_light} flex="1" w="100%">
                <HStack
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    flexDirection={flexDirection}
                    p="4"
                    pt={0}
                >
                    <Heading textAlign={textAlign} size="lg">
                        {t("My Cart")}
                    </Heading>
                    <Button
                        variant={"link"}
                        colorScheme={"error"}
                        p={0}
                        onPress={() => dispatch(clearCart())}
                    >
                        {t("Clear All")}
                    </Button>
                </HStack>

                <CartItems />
            </Box>
        </Center>
    );
}

export function CartItems() {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const [isRowOpen, setIsRowOpen] = React.useState(false);
    const [rowOpenkey, setRowOpenKey] = React.useState(null);

    const listData = cart.itemsList;

    const closeRow = (rowMap, rowKey) => {
        if (rowMap[rowKey]) {
            rowMap[rowKey].closeRow();
        }
    };

    const onRowPress = (rowMap, rowKey) => {
        if ((rowMap[rowKey] && !isRowOpen) || rowOpenkey !== rowKey) {
            rowMap[rowKey].manuallySwipeRow(-80);
            setIsRowOpen(true);
            setRowOpenKey(rowKey);
        } else {
            closeRow(rowMap, rowKey);
            setIsRowOpen(false);
            setRowOpenKey(null);
        }
    };

    const deleteRow = (rowMap, rowKey) => {
        closeRow(rowMap, rowKey);

        const prevIndex = listData.findIndex((item) => item.key === rowKey);
        dispatch(removeItem(prevIndex));
    };

    const onRowDidOpen = (rowKey) => {
        console.log("This row opened", rowKey);
    };

    const renderHiddenItem = (data, rowMap) => (
        <HStack flex="1" pl="2">
            {/* <Pressable
        w="70"
        ml="auto"
        bg="coolGray.200"
        justifyContent="center"
        onPress={() => closeRow(rowMap, data.item.key)}
        _pressed={{
          opacity: 0.5,
        }}
      >
        <VStack alignItems="center" space={2}>
          <Icon
            as={<Entypo name="dots-three-horizontal" />}
            size="xs"
            color="coolGray.800"
          />
          <Text fontSize="xs" fontWeight="medium" color="coolGray.800">
            More
          </Text>
        </VStack>
      </Pressable> */}
            <Pressable
                w="70"
                ml="auto"
                bg="red.500"
                justifyContent="center"
                onPress={() => deleteRow(rowMap, data.item.key)}
                _pressed={{
                    opacity: 0.5,
                }}
            >
                <VStack alignItems="center" space={2}>
                    <Icon
                        as={<MaterialIcons name="delete" />}
                        color="white"
                        size="xs"
                    />
                    <Text color="white" fontSize="xs" fontWeight="medium">
                        {t("Delete")}
                    </Text>
                </VStack>
            </Pressable>
        </HStack>
    );

    return (
        <Box flex="1" w="100%">
            <SwipeListView
                data={listData}
                renderItem={(data, rowMap) => (
                    <OrderLineItem
                        item={data.item}
                        index={rowMap}
                        onPress={() => {
                            onRowPress(rowMap, data.item.key);
                            console.log("You touched me");
                        }}
                    />
                )}
                renderHiddenItem={renderHiddenItem}
                // rightOpenValue={-130}
                rightOpenValue={-80}
                previewRowKey={"0"}
                previewOpenValue={-40}
                previewOpenDelay={3000}
                onRowDidOpen={onRowDidOpen}
                showsVerticalScrollIndicator={false}
                scrollEnabled={true}
            />
        </Box>
    );
}
