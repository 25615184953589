import { Image } from "expo-image";
import LottieView from "lottie-react-native";
import { Center, Heading, Link, Spacer, Text } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { _dark, _light } from "../../Theme";
import { t } from "../translations/i18n";
import DownloadAppBadges from "./DownloadAppBadges";

export default function OutOfService() {
    return (
        <Center _dark={_dark} _light={_light} height={"100%"} width={"full"}>
            <Spacer />
            <LottieView
                style={{
                    paddingHorizontal: 50,
                    width: 280,
                    height: 280,
                    justifyContent: "center",
                    alignItems: "center",
                }}
                source={require("../assets/lottie/404.json")}
                autoPlay
            />

            <Heading p={2} size={"md"}>
                {t(`The ${Platform.OS} app is out of service`)}
            </Heading>
            <Text p={2}>
                {t(
                    "We are currently working on improving our app We will be back soon"
                )}
            </Text>

            {Platform.OS === "web" && (
                <Center>
                    <Heading p={2} size={"sm"}>
                        {t(`Try to order from the iOS or Android app`)}
                    </Heading>
                    <DownloadAppBadges />
                </Center>
            )}

            {Platform.OS !== "web" && (
                <Center>
                    <Heading p={2} size={"sm"}>
                        {t(`Try to order from the web app`)}
                    </Heading>
                    <Link href="https://app.jaicome.com">
                        <Image
                            style={{
                                width: 150,
                                height: 43,
                                maxWidth: 600,
                            }}
                            source={require("../assets/images/web-app-badge.png")}
                            aria-label={`web-app-badge`}
                            accessibilityLabel={`web-app-badge`}
                        />
                    </Link>
                </Center>
            )}
            <Spacer />
        </Center>
    );
}
