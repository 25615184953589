import { round } from "lodash";
import { Dimensions } from "react-native";
import * as Sharing from "expo-sharing";

export async function handleShare(shareLink: string, dialogTitle: string) {
    try {
        await Sharing.shareAsync(shareLink, {
            dialogTitle: dialogTitle,
        });
    } catch (error) {
        alert(error.message);
    }
};


export function getItemCardImageWidth() {
    let { width } = Dimensions.get("window");
    switch (true) {
        case width < 400:
            return round(width * 0.435);
        case width < 460:
            return round(width * 0.435);
        case width < 600:
            return round(width * 0.3);
        case width < 700:
            return round(width * 0.225);
        case width < 800:
            return round(width * 0.22);
        default:
            return width * 0.219;
    }
}


export function getWindowWidth() {
    let { width } = Dimensions.get('window');
    switch (true) {
        case width < 400:
            width = width * 0.44;
            return { width: width, column: 2 };
        case width < 460:
            width = width * 0.44;
            return { width: width, column: 2 };
        case width < 600:
            width = width * 0.3;
            return { width: width, column: 3 };
        case width < 700:
            width = width * 0.225;
            return { width: width, column: 4 };
        case width < 800:
            width = width * 0.22;
            return { width: width, column: 4 };
        default:
            width = width * 0.23;
            return { width: width, column: 4 };
    }
}

export function priceFormat(price: number) {
    return (Number(price) / 100).toFixed(2);
}

/**
 * debounce function
 * @param func
 * @param timeout
 * @returns
 */
export function debounce(func: any, timeout = 300) {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            //@ts-ignore
            func.apply(this, args);
        }, timeout);
    };
}

export function uniqueArray<T>(arr: T[], key: keyof T) {
    return arr.filter((value, index) => {
        return index === arr.findIndex((obj) => obj[key] === value[key]);
    });
}

export function toNumber(x: any) {
    return x === undefined || x === null || isNaN(x as any) ? 0 : Number(x);
}

export function convertToDate(date: any) {
    if (date instanceof Date) {
        return date;
    } else if (typeof date === "string") {
        return new Date(date);
    } else if (date && date.seconds) {
        return new Date(date.seconds * 1000);
    } else if (date && date._seconds) {
        return new Date(date._seconds * 1000);
    } else {
        return new Date();
    }
}

export function getDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
) {
    if (lat1 == lat2 && lon1 == lon2) {
        return 0;
    } else {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) {
            dist = 1;
        }

        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;

        // Distance in Kilometers:
        return dist * 1.609344;
    }
}

export function convertFromGeoPoint(location?: any) {
    // This method corrects the location object
    return {
        latitude: location._latitude ?? location.latitude ?? 0,
        longitude: location._longitude ?? location.longitude ?? 0,
    };
}


export function isDeliveryAvailable(startAt: string, endAt: string): boolean {
    if (!startAt || !endAt) return true; // Delivery is available all day

    const currentTime = new Date();
    const currentDateString = currentTime.toISOString().split("T")[0]; // formated as YYYY-MM-DD
    const startTime = new Date(`${currentDateString}T${startAt}`);
    let endTime = new Date(`${currentDateString}T${endAt}`);

    // Adjust endTime if it's on the next day
    if (endTime < startTime) {
        endTime = new Date(endTime.getTime() + 24 * 60 * 60 * 1000);
    }

    return currentTime >= startTime && currentTime <= endTime;
}

// convert time to 12 hours format (hh:mm:ss AM/PM)
export function convertTimeTo12(time: string) {
    const [hour, minute, second] = time.split(":");
    const date = new Date(0, 0, 0, Number(hour), Number(minute), Number(second));
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleString("en-US", options);
}

// Get the sold_out status for each variation
export function variationsSoldOut(variations: any[], locationId: string) {
    let sold_out = {};
    variations.map(({ id, item_variation_data }) => {
        // find the location override for the current location
        const location_overrides =
            item_variation_data?.location_overrides?.filter(
                (location_override) =>
                    location_override.location_id === locationId
            )[0];

        sold_out = {
            ...sold_out,
            [id]: location_overrides?.sold_out || false,
        };
    });

    return sold_out;
}