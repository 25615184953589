import { createSlice } from '@reduxjs/toolkit';
import { Language } from '../../utils/types';

const initState = { lang: 'English', code: 'en', dir: 'ltr' } as Language;

export const manageLanguage = createSlice({
	name: 'language',
	initialState: initState,
	reducers: {
		setLanguage: (state: Language, action: any) => {
			switch (action.payload) {
				case 'ar':
					return { lang: 'عربي', code: 'ar', dir: 'rtl' } as Language;
				case 'en':
					return { lang: 'English', code: 'en', dir: 'ltr' } as Language;
				default:
					return state;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { setLanguage } = manageLanguage.actions;

export default manageLanguage.reducer;
