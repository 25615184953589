import { useNetInfo } from "@react-native-community/netinfo";
import * as Updates from "expo-updates";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useMemo, useRef, useState } from "react";
import { Alert, AlertButton, Linking, Platform } from "react-native";
import InternetConnection from "../components/InternetConnection";
import OutOfService from "../components/OutOfService";
import { db } from "../firebase/firebase";
import { t } from "../translations/i18n";
import { buildVersion } from "../ts/constants/version";
import awaitable from "../ts/helpers/awaitable";

type AppVersionData = {
    build: {
        android: string;
        ios: string;
    };
    storesUrls: {
        android: string;
        ios: string;
    };
    mandatory: boolean;
};

const isLatestUpdate = (latestVersion: string) => {
    let version: number;
    if ((buildVersion.match(/\./g) || []).length > 2) {
        let lastIndex = buildVersion.lastIndexOf(".");
        version = Number(buildVersion.substr(0, lastIndex).replaceAll(".", ""));
    } else {
        version = Number(buildVersion.replaceAll(".", ""));
    }

    let latest = Number(latestVersion.replaceAll(".", ""));
    return version >= latest;
};

const onFetchUpdateAsync = async () => {
    try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
            await Updates.fetchUpdateAsync();
            await Updates.reloadAsync();
        }
    } catch (error) {
        console.log(
            "[ERR @ UpdateProvider -> onFetchUpdateAsync()]:",
            error.message
        );
    }
};

export default function UpdateProvider() {
    const checked = useRef(false);
    const { isConnected, isInternetReachable } = useNetInfo();

    const internetConnected = useMemo(
        () => isConnected !== false && isInternetReachable !== false,
        [isConnected, isInternetReachable]
    );

    const [activePlatform, setActivePlatform] = useState({
        [Platform.OS]: true,
    });

    useEffect(() => {
        const checkUpdate = async () => {
            const refAppVersion = doc(db, "configs/appVersion");
            const refAppPlatform = doc(db, "configs/appPlatform");
            const [snapAppVersion] = await awaitable(getDoc(refAppVersion));
            const [snapAppPlatform] = await awaitable(getDoc(refAppPlatform));
            const dataAppVersion = snapAppVersion?.data() as
                | AppVersionData
                | undefined;
            const dataAppPlatform = snapAppPlatform?.data();
            const isPlatformActive = dataAppPlatform?.[Platform.OS] ?? true;
            setActivePlatform((prev) => ({
                ...prev,
                [Platform.OS]: isPlatformActive,
            }));

            const platformVersion = dataAppVersion?.build?.[Platform.OS];
            if (platformVersion && !isLatestUpdate(platformVersion)) {
                let actions: AlertButton[] = [
                    {
                        text: t("Update Now"),
                        style: "default",
                        onPress: async () =>
                            awaitable(
                                Linking.openURL(
                                    dataAppVersion?.storesUrls?.[Platform.OS]
                                )
                            ),
                    },
                ];
                if (dataAppVersion.mandatory === false) {
                    actions.push({
                        text: t("Cancel"),
                        style: "cancel",
                        onPress: () => {},
                    });
                }
                Alert.alert(
                    t("Update"),
                    t(
                        "Please update the app to the latest version to continue using the system"
                    ),
                    actions,
                    { cancelable: dataAppVersion.mandatory === false }
                );
            }
            checked.current = true;
        };

        // ? If internet status changed and not already checked:
        if (internetConnected && !checked.current) {
            checkUpdate();
            onFetchUpdateAsync();
        }
    }, [internetConnected, t]);

    // if not internetConnected
    if (!internetConnected) {
        return <InternetConnection />;
    }

    // check if app is out of service
    if (!activePlatform[Platform.OS]) {
        return <OutOfService />;
    }

    return <></>;
}
