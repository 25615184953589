import { Entypo } from "@expo/vector-icons";
import {
    Box,
    Button,
    Center,
    Heading,
    Hidden,
    HStack,
    Icon,
    IconButton,
    Link,
    Text,
    VStack,
} from "native-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// Setup the theme
import { _dark, _light } from "../../Theme";
// Import Icons
import { Fontisto, MaterialCommunityIcons, Feather } from "@expo/vector-icons";

import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BusinessHoursProps from "../components/BusinessHoursProps";
import ButtonDelivery from "../components/ButtonDelivery";
import CartBar from "../components/Cart/CartBar";
import CenterSpinner from "../components/CenterSpinner";
import DiningOptionsProps from "../components/DiningOptionsProps";
import Footer from "../components/Footer";
import OptionsProps from "../components/OptionsProps";
import useLocation from "../hooks/useLocation";
import {
    clearCart,
    setCurbsideDetails,
    setIsDelivery,
    setParkingNumber,
    setTableNumber,
} from "../redux/reducers/manageCart";
import { setLanguage } from "../redux/reducers/manageLanguage";
import i18n, { t } from "../translations/i18n";
import { getLanguageText } from "../utils/textFormat";
import { Language, ReduxState } from "../utils/types";
import DateTimePicker from "../components/DateTimePicker";
import { handleShare } from "../utils";

export default function HomeScreen({ route }) {
    const { locationId, language, curbsideDetails } = route.params;
    const { location, items } = useLocation(locationId);
    const navigation = useNavigation();
    const dispatch = useDispatch();

    const cart = useSelector((s: ReduxState) => s.cart);
    const selectedTableNumber = useSelector(
        (s: ReduxState) => s.cart.selectedTableNumber || 0
    );
    const selectedParkingNumber = useSelector(
        (s: ReduxState) => s.cart.selectedParkingNumber || 0
    );

    // Set the language
    const currentLanguage = useSelector((s: ReduxState) => s.language);
    i18n.locale = currentLanguage.code;

    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";

    const [isLoadingData, setIsLoadingData] = useState(true);

    const loadData = useCallback(async () => {
        try {
            setIsLoadingData(true);
            // location set in the previous screen
            if (location?.id !== locationId) {
                dispatch(clearCart());
            }
            setIsLoadingData(false);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, location, locationId]);

    const handelNavigateToItemsScreen = useCallback(async () => {
        await navigation.navigate("Items", {
            language: i18n?.locale === "ar" ? "ar" : "en",
            locationId: location.id,
        });
    }, [location]);

    const initState = () => {
        dispatch(setTableNumber(0));
        dispatch(setParkingNumber(0));
        dispatch(setIsDelivery(false));
    };

    const isLoading = useMemo(() => {
        return (
            isLoadingData ||
            !location ||
            location?.id !== locationId ||
            !items?.length
        );
    }, [isLoadingData, location, locationId, items?.length]);

    useEffect(() => {
        i18n.locale = language;
        dispatch(setLanguage(i18n?.locale));
        dispatch(setCurbsideDetails(curbsideDetails || null));
    }, [dispatch, route.params]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    if (isLoading) return <CenterSpinner />;

    return (
        <Box size={"100%"} _dark={_dark} _light={_light}>
            {/* Cart  Bar */}
            <CartBar
                cart={cart}
                goBack={() =>
                    navigation.navigate("Locations", {
                        language: language,
                    })
                }
            />
            {/* Location/Store Image Cover */}
            <Box w={"100%"} roundedBottom={"8"}>
                <Image
                    source={{
                        uri: location?.posBackgroundUrl,
                    }}
                    style={{
                        width: Dimensions.get("window").width,
                        height: 200,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                    }}
                />
                {/* </AspectRatio> */}
            </Box>
            <Center top={-42}>
                <Center
                    p={1.5}
                    borderRadius={"3xl"}
                    _light={_light}
                    _dark={_dark}
                >
                    <Image
                        source={{
                            uri: location?.logoUrl,
                        }}
                        style={{
                            width: 80,
                            height: 80,
                            borderRadius: 20,
                        }}
                    />
                </Center>

                <Heading size={"md"} pt={2}>
                    {getLanguageText(i18n.locale, location?.businessName)}
                </Heading>

                <Text fontSize="sm" paddingTop={1} isTruncated maxW={"100%"}>
                    {`${getLanguageText(
                        i18n.locale,
                        location.address.locality as string
                    )}, ${getLanguageText(
                        i18n.locale,
                        location.address.addressLine1 as string
                    )}`}
                </Text>

                <Center py={4}>
                    <HStack space={6}>
                        {!location?.phoneNumber ? null : (
                            <Button
                                variant={"subtle"}
                                rounded={"full"}
                                colorScheme={"success"}
                                width={8}
                                height={8}
                            >
                                <Link
                                    href={`tel://${location?.phoneNumber}`}
                                    isExternal
                                >
                                    <Icon
                                        as={Fontisto}
                                        name="phone"
                                        size={"sm"}
                                        color={"success.600"}
                                    />
                                </Link>
                            </Button>
                        )}
                        {!location?.instagramUsername ? null : (
                            <Button
                                variant={"subtle"}
                                rounded={"full"}
                                colorScheme={"pink"}
                                width={8}
                                height={8}
                            >
                                <Link
                                    href={`https://instagram.com/${location?.instagramUsername}`}
                                    isExternal
                                >
                                    <Icon
                                        as={Fontisto}
                                        name="instagram"
                                        size={"sm"}
                                        color={"pink.600"}
                                    />
                                </Link>
                            </Button>
                        )}
                        {!location?.coordinates ? null : (
                            <Button
                                variant={"subtle"}
                                rounded={"full"}
                                colorScheme={"blue"}
                                width={8}
                                height={8}
                            >
                                <Link
                                    href={`https://www.google.com/maps/place/${location?.coordinates.latitude},${location?.coordinates.longitude}`}
                                    isExternal
                                >
                                    <Icon
                                        as={MaterialCommunityIcons}
                                        name="map-legend"
                                        size={"sm"}
                                        color={"blue.600"}
                                    />
                                </Link>
                            </Button>
                        )}
                        <BusinessHoursProps
                            businessHours={location.businessHours}
                        />

                        {/* button to shere the location through social media */}
                        <Hidden platform={["web"]}>
                            <IconButton
                                variant={"subtle"}
                                rounded={"full"}
                                colorScheme={"blue"}
                                width={8}
                                height={8}
                                onPress={() =>
                                    handleShare(
                                        `https://app.jaicome.com/${language}/${locationId}`,
                                        `Share ${location.businessName}`
                                    )
                                }
                                icon={
                                    <Icon
                                        as={Feather}
                                        name="share"
                                        size={"sm"}
                                    />
                                }
                            />
                        </Hidden>
                    </HStack>
                </Center>

                <Center p={4} width={"100%"}>
                    <VStack width={"100%"} space={2}>
                        {!location.isActive ? (
                            <Button
                                borderRadius={"xl"}
                                size="lg"
                                width={"100%"}
                                _text={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    marginBottom: -1,
                                }}
                                onPress={() => handelNavigateToItemsScreen()}
                            >
                                {t("Menu")}
                            </Button>
                        ) : (
                            <>
                                <Button
                                    borderRadius={"xl"}
                                    size="lg"
                                    width={"100%"}
                                    _text={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        marginBottom: -1,
                                    }}
                                    leftIcon={
                                        <Icon
                                            as={Fontisto}
                                            name="shopping-bag-1"
                                            size="sm"
                                        />
                                    }
                                    onPress={() => {
                                        initState();
                                        handelNavigateToItemsScreen();
                                    }}
                                >
                                    {t("PICK-UP")}
                                </Button>

                                {/* Delivery Button to handling delivery settings*/}
                                <ButtonDelivery
                                    deliveryConfig={location?.delivery}
                                    handelNavigateToItemsScreen={
                                        handelNavigateToItemsScreen
                                    }
                                />

                                {/* TODO: UI/UX is complete but the functionality is not complete for DateTimePicker to select the date and time */}
                                {/* <DateTimePicker /> */}

                                <DiningOptionsProps
                                    onSelected={() => {
                                        handelNavigateToItemsScreen();
                                    }}
                                    buttonText={"Pickup Spot"}
                                    title={"Select your parking spot"}
                                    optionTitle={"Parking"}
                                    numberOfOptions={location.numberOfParking}
                                    selectedOption={selectedParkingNumber || 0}
                                    colorScheme="light"
                                    leftIcon={
                                        <Icon
                                            as={MaterialCommunityIcons}
                                            name="car"
                                            size="sm"
                                        />
                                    }
                                />

                                <DiningOptionsProps
                                    onSelected={() => {
                                        handelNavigateToItemsScreen();
                                    }}
                                    buttonText={"DINE-IN"}
                                    title={"Select your table"}
                                    optionTitle={"Table"}
                                    numberOfOptions={location.numberOfTables}
                                    selectedOption={selectedTableNumber}
                                    colorScheme="light"
                                    leftIcon={
                                        <Icon
                                            as={MaterialCommunityIcons}
                                            name="silverware-fork-knife"
                                            size="sm"
                                        />
                                    }
                                />
                            </>
                        )}
                    </VStack>

                    <Box m={4}>
                        <OptionsProps
                            colorScheme="light"
                            options={
                                [
                                    { lang: "English", code: "en", dir: "ltr" },
                                    { lang: "عربي", code: "ar", dir: "rtl" },
                                ] as Language[]
                            }
                            leftIcon={
                                <Icon as={Entypo} name="language" size="sm" />
                            }
                        />
                    </Box>
                </Center>
            </Center>

            {/* FOOTER */}
            <Footer />
        </Box>
    );
}
