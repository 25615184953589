import { collection, doc } from "firebase/firestore";
import { isEmpty } from "lodash";
import {
    Alert,
    Box,
    Button,
    Divider,
    FormControl,
    Heading,
    Hidden,
    HStack,
    Icon,
    Input,
    KeyboardAvoidingView,
    ScrollView,
    Spacer,
    Stack,
    StatusBar,
    Switch,
    Text,
    Toast,
    useDisclose,
    View,
    VStack,
} from "native-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Order } from "square";
// Setup the theme
import { _dark, _light } from "../../Theme";
import AuthSheet from "../components/AuthSheet";
import CenterSpinner from "../components/CenterSpinner";
import CarPickup from "../components/Checkout/CarPickup";
import PaymentTypes from "../components/Checkout/PaymentTypes";
import SummaryCart from "../components/Checkout/SummaryCart";
import Map from "../components/Map";
import { auth, db } from "../firebase/firebase";
import { setCalOrder } from "../redux/reducers/manageCart";
import { setLanguage } from "../redux/reducers/manageLanguage";
import i18n, { t } from "../translations/i18n";
import { validatePhone, validEmail } from "../utils/dataValidation";
import { calculateOrder, createOrder, getDistance } from "../api/square";
import { initPayment } from "../api/payment";
import {
    getCalculatedDeliveryServiceCharge,
    getLanguageText,
    getPhoneNumber,
} from "../utils/textFormat";
import { Country, Distance, ReduxState } from "../utils/types";
import ServiceCharges from "../components/Checkout/ServiceCharges";
import Amount from "../components/Checkout/Amount";
import DeliverySheet from "../components/DeliverySheet";
import PromoCode from "../components/Checkout/PromoCode";
import { callingCodes } from "../components/CallingCodeModal/countries";
import CallingCodeModal from "../components/CallingCodeModal";
import OrderTypes from "../components/OrderTypes";

export default function CheckoutScreen({ navigation, route }: any) {
    const language = i18n.locale || route.params.language;
    const flexDirection = language === "ar" ? "row-reverse" : "row";
    const textAlign = language === "ar" ? "right" : "left";
    const justifyContent = language === "ar" ? "flex-end" : "flex-start";
    const height = Dimensions.get("window").height - 64;
    const width = Dimensions.get("window").width;

    const dispatch = useDispatch();

    const cart = useSelector((s: ReduxState) => s.cart);
    const user = useSelector((s: ReduxState) => s.auth.user);
    const {
        merchantId,
        currency,
        coordinates,
        delivery,
        isTest,
        enableCarPickup,
        country,
        paymentTypes,
    } = useSelector((s: ReduxState) => s.location);
    const locationId = useSelector((s: ReduxState) => s.location.id);
    const currentUserLocation = useSelector((s: ReduxState) => s.auth.location);

    const [checkoutState, setCheckoutState] = useState({
        order: {} as Order,
        ticketName: "",
        displayName: user?.name ?? "",
        phoneNumber: user?.phone ?? "",
        callingCode:
            callingCodes.find((c: Country) => c.isoCode === country)?.code ??
            "+966",
        emailAddress: user?.email ?? "",
        sourceId: "EXTERNAL",
        curbsideDetails: cart.curbsideDetails,
        deliveryLocation: undefined,
        distance: {} as Distance,
        isDelivery: cart.isDelivery ?? false,
        isProcessing: false,
    });

    const handleUpdatecheckoutState = useCallback(
        (key: string, value: any) => {
            setCheckoutState((prev) => ({ ...prev, [key]: value }));
        },
        [checkoutState]
    );

    const [openAuthSheet, setOpenAuthSheet] = useState(false);

    const { isOpen, onToggle, onClose } = useDisclose();

    const displayToast = (message: string, status: string) => {
        Toast.show({
            duration: 6000,
            placement: "top",
            render: () => {
                return (
                    <Stack w={Dimensions.get("window").width} px={4} pt={6}>
                        <Alert w="100%" status={status}>
                            <HStack flexDirection={flexDirection}>
                                <Alert.Icon />
                                <Text flexShrink={1} mx={2}>
                                    {message}
                                </Text>
                            </HStack>
                        </Alert>
                    </Stack>
                );
            },
        });
    };

    const somethingWentWrong = () => {
        handleUpdatecheckoutState("isProcessing", false);
        displayToast(t("Something went wrong, please try again"), "error");
    };

    const calculateCart = useCallback(
        async (deliveryDistance?: Distance) => {
            handleUpdatecheckoutState("isProcessing", true);
            let taxes: any[] = [];

            const lineItems = cart.itemsList.map(({ data }: any) => {
                let appliedTaxes: any[] = [];

                data?.appliedTaxes.map((tax: any) => {
                    // Check if tax in taxes array by catalogObjectId and match the appliedTaxes for each item to have the same uid
                    const taxIndex = taxes.findIndex(
                        (t) => t.catalogObjectId === tax.uid
                    );
                    if (taxIndex === -1) {
                        taxes.push({
                            uid: tax.taxUid,
                            catalogObjectId: tax.uid,
                            scope: "LINE_ITEM",
                        });

                        appliedTaxes.push({
                            taxUid: tax.taxUid,
                            uid: tax.uid,
                        });
                    } else {
                        appliedTaxes.push({
                            taxUid: taxes[taxIndex].uid,
                            uid: taxes[taxIndex].catalogObjectId,
                        });
                    }
                });

                return {
                    catalogObjectId: data.catalogObjectId,
                    quantity: data.quantity,
                    modifiers: data.modifiers,
                    appliedTaxes: appliedTaxes || [],
                    note: data.note || "",
                };
            });

            const deliveryServiceCharges =
                checkoutState.isDelivery && delivery.enabledServiceCharge
                    ? delivery.serviceCharges
                    : [];

            let deliveryFee = deliveryServiceCharges;

            if (deliveryDistance && deliveryDistance?.distance?.value > 4000) {
                deliveryFee = [
                    getCalculatedDeliveryServiceCharge(
                        delivery.calculateFee,
                        deliveryDistance,
                        currency
                    ),
                ];
            }

            const order: Order = {
                locationId: locationId,
                lineItems: lineItems,
                taxes: taxes,
                pricingOptions: { autoApplyDiscounts: true },
                serviceCharges: checkoutState.isDelivery ? deliveryFee : [],
            };

            // request to calculate order
            const cal = await calculateOrder(merchantId, order);
            // if calculate order failed, go back to items screen
            if (cal == null) {
                somethingWentWrong();
                navigation.navigate("Items", {
                    language: language,
                    locationId: locationId,
                });
                return;
            }

            handleUpdatecheckoutState("order", cal.order);
            await dispatch(setCalOrder(cal.order));
            handleUpdatecheckoutState("isProcessing", false);
        },
        [
            cart,
            locationId,
            merchantId,
            checkoutState.isDelivery,
            checkoutState.distance,
        ]
    );

    const handleLocationChange = useCallback(
        async (location: any) => {
            handleUpdatecheckoutState("deliveryLocation", {
                lat: location.lat || location.latitude,
                lng: location.lng || location.longitude,
            });

            const distance = await getDistance(
                `${coordinates.latitude},${coordinates.longitude}`,
                `${location.lat},${location.lng}`
            );
            handleUpdatecheckoutState("distance", distance);
            await calculateCart(distance as Distance);
        },
        [checkoutState.deliveryLocation]
    );

    useEffect(() => {
        // if empty cart, go back to home
        if (!cart.itemsList.length) {
            navigation.navigate("Home", {
                language: route.params.language,
                locationId: locationId,
            });
        }

        i18n.locale = route.params.language;
        dispatch(setLanguage(route.params.language));

        navigation.setOptions({
            title: t("Checkout"),
        });
        // calculate cart/order
        calculateCart();
    }, []);

    const dataValidations = useMemo(() => {
        return {
            displayName: checkoutState.displayName.length > 7,
            phoneNumber: validatePhone(
                checkoutState.callingCode,
                checkoutState.phoneNumber
            ),
            emailAddress:
                !checkoutState.emailAddress ||
                validEmail(checkoutState.emailAddress),
        };
    }, [
        checkoutState.displayName,
        checkoutState.callingCode,
        checkoutState.phoneNumber,
        checkoutState.emailAddress,
    ]);

    const onSelect = useCallback((code: string) => {
        handleUpdatecheckoutState("callingCode", code);
    }, []);

    const handleToastError = useCallback(() => {
        let message = ""; // error message
        if (!dataValidations.displayName) {
            message = t(
                "Please enter your full name and most have more than 8 characters"
            );
        } else if (!dataValidations.phoneNumber) {
            message = t("Please enter a valid phone number");
        } else if (!dataValidations.emailAddress) {
            message = t("Please enter a valid email address");
        } else if (!checkoutState.distance && checkoutState.isDelivery) {
            message = t(
                "Please confirm the delivery location by selecting it on the map"
            );
        } else if (checkoutState.order?.lineItems?.length === 0) {
            message = t("Please add items to the cart or refresh the page");
        }
        // if there is an error, display toast
        if (message) displayToast(message, "error");

        return message ? true : false;
    }, [
        displayToast,
        dataValidations,
        checkoutState.distance,
        checkoutState.isDelivery,
    ]);

    const handlePlaceOrder = useCallback(
        async (uid?: string) => {
            // check if data is valid
            const error = handleToastError();
            if (error) return; // if there is an error, return

            // start processing
            await handleUpdatecheckoutState("isProcessing", true);

            // get user id
            const userId = !isEmpty(user?.id)
                ? user?.id
                : !isEmpty(uid)
                ? uid
                : checkoutState.callingCode.replace("+", "") +
                  checkoutState.phoneNumber;

            // if (PlatcheckoutState.OS !== "web") {
            //     if (!userId) {
            //         setOpenAuthSheet(true);
            //         return;
            //     }
            // }

            try {
                // todo: remove this later:
                const isOnlinePayment = paymentTypes?.isOnlinePayment;

                // * Online payment method:
                if (isOnlinePayment) {
                    // * 1. Generate a random order reference to connect the order with the payment:
                    const order_payment_ref = doc(collection(db, "orders")).id;
                    // * 2. Store the prepared order to async storage:
                    await AsyncStorage.setItem(
                        "PrepareOrder",
                        JSON.stringify({
                            userId: userId,
                            uid: auth.currentUser?.uid,
                            currentUserLocation: currentUserLocation,
                            merchantId: merchantId,
                            checkoutState: checkoutState,
                            isOnlinePayment: isOnlinePayment,
                        })
                    );
                    // * 3. Redirect to the payment gateway:
                    const { url } = await initPayment(
                        order_payment_ref,
                        checkoutState.phoneNumber,
                        Number(checkoutState.order?.totalMoney?.amount),
                        merchantId,
                        locationId,
                        language
                    );
                    if (Platform.OS === "web") {
                        window.location.href = url;
                    } else {
                        navigation.navigate("Payment", {
                            language: language,
                            url: url,
                        });
                    }
                    // todo: handle payment callback and create order if payment is approved
                    // * 4. if payment is approved create order in the payment screen
                    // check the payment PaymentScreen -> index.web.tsx
                }

                // * Non-online payment method:
                else {
                    const { orderId, order } = await createOrder({
                        userId: userId,
                        uid: auth.currentUser?.uid,
                        currentUserLocation: currentUserLocation,
                        merchantId: merchantId,
                        checkoutState: checkoutState,
                        isOnlinePayment: isOnlinePayment,
                    });

                    if (orderId) {
                        // go order status screen
                        navigation.navigate("OrderStatus", {
                            language: language,
                            order_id: orderId,
                        });
                    }

                    // handle error if order not created
                    if (!orderId) {
                        handleUpdatecheckoutState("isProcessing", false);
                        somethingWentWrong();
                        return;
                    }
                }
                // stop processing
                await handleUpdatecheckoutState("isProcessing", false);
            } catch (error: any) {
                handleUpdatecheckoutState("isProcessing", false);
                console.error("[ERR @ handlePlaceOrder]:", error.message);
            }
        },
        [
            checkoutState,
            user,
            dataValidations,
            cart.curbsideDetails,
            handleToastError,
        ]
    );

    if (!checkoutState.order) {
        return <CenterSpinner />;
    }

    return (
        <Box _dark={_dark} _light={_light} flex={1}>
            {/* TODO: Order Type is not complete */}
            {/* <OrderTypes onChange={handleUpdatecheckoutState} /> */}
            <Alert mx={4} my={2.5} colorScheme="success" status="success">
                <VStack
                    space={2}
                    flexShrink={1}
                    w="100%"
                    flexDirection={flexDirection}
                >
                    <HStack
                        flexShrink={1}
                        space={2}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <HStack
                            space={2}
                            flexShrink={1}
                            alignItems="center"
                            flexDirection={flexDirection}
                        >
                            <Alert.Icon />
                            <Text fontSize={"md"}>
                                {!Number(cart.selectedTableNumber) &&
                                !Number(cart.selectedParkingNumber) &&
                                !cart.isDelivery
                                    ? t("PICK-UP")
                                    : !isNaN(
                                          Number(cart.selectedTableNumber)
                                      ) && cart.selectedTableNumber
                                    ? `${t("Table")} ${
                                          cart.selectedTableNumber
                                      }`
                                    : !isNaN(
                                          Number(cart.selectedParkingNumber)
                                      ) && cart.selectedParkingNumber
                                    ? `${t("Parking")} ${
                                          cart.selectedParkingNumber
                                      }`
                                    : t("DELIVERY")}
                            </Text>
                        </HStack>
                    </HStack>
                </VStack>
            </Alert>

            <ScrollView px={6} showsVerticalScrollIndicator={false}>
                {/* Customer IncheckoutStateation */}
                <Stack space={1} mb={2}>
                    <Heading size={"sm"} width={"100%"} textAlign={textAlign}>
                        {t("Customer Info")}
                    </Heading>
                    <Input
                        isInvalid={!dataValidations.displayName}
                        value={checkoutState.displayName}
                        invalidOutlineColor={"rose.600"}
                        isDisabled={checkoutState.isProcessing}
                        size="md"
                        variant="underlined"
                        autoComplete="name"
                        textAlign={textAlign}
                        placeholder={t("Full Name")}
                        isRequired={true}
                        maxLength={45}
                        onChangeText={(displayName) => {
                            // update displayName
                            handleUpdatecheckoutState(
                                "displayName",
                                displayName
                            );
                            // update ticketName
                            handleUpdatecheckoutState(
                                "ticketName",
                                Number(cart.selectedTableNumber) > 0
                                    ? `Table ${cart.selectedTableNumber} طاولة`
                                    : Number(cart.selectedParkingNumber) > 0
                                    ? `Parking ${cart.selectedParkingNumber} موقف`
                                    : displayName
                            );
                        }}
                    />

                    <Input
                        isInvalid={!dataValidations.phoneNumber}
                        value={checkoutState.phoneNumber} // remove leading zeros from phone number
                        invalidOutlineColor={"rose.600"}
                        isDisabled={checkoutState.isProcessing}
                        size="md"
                        variant="underlined"
                        autoComplete="tel"
                        textAlign={textAlign}
                        placeholder={t("Phone")}
                        isRequired={true}
                        keyboardType={"phone-pad"}
                        onChangeText={(phoneNumber) => {
                            handleUpdatecheckoutState(
                                "phoneNumber",
                                getPhoneNumber(
                                    phoneNumber,
                                    checkoutState.callingCode
                                )
                            );
                        }}
                        leftElement={
                            <Heading size={"xs"} textAlign={textAlign}>
                                <CallingCodeModal
                                    value={checkoutState.callingCode}
                                    onSelect={onSelect}
                                />
                            </Heading>
                        }
                    />

                    <Input
                        isInvalid={!dataValidations.emailAddress}
                        value={checkoutState.emailAddress}
                        invalidOutlineColor={"rose.600"}
                        isDisabled={checkoutState.isProcessing}
                        size="md"
                        variant="underlined"
                        autoComplete="email"
                        textAlign={textAlign}
                        placeholder={t("Email")}
                        isRequired={false}
                        maxLength={45}
                        onChangeText={(emailAddress) => {
                            handleUpdatecheckoutState(
                                "emailAddress",
                                emailAddress
                            );
                        }}
                    />
                </Stack>
                {checkoutState.isDelivery && (
                    <Stack mb={4}>
                        <Map
                            currentLocation={{
                                lat: currentUserLocation?.latitude as number,
                                lng: currentUserLocation?.longitude as number,
                            }}
                            deliveryAddress={checkoutState.distance?.endAddress}
                            withCurrnetLocationMarker={true}
                            zoom={18}
                            buttonTitle={t("Select Delivery Location")}
                            onLocationChange={async (location) => {
                                await handleLocationChange(location);
                            }}
                        />
                    </Stack>
                )}

                {/* <checkoutStateControl isRequired>
                    <checkoutStateControl.Label
                        justifyContent={justifyContent}
                        _text={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "muted.600",
                            textAlign: textAlign,
                        }}
                    >
                        {t(
                            "By clicking Confirm Order, you agree to our terms and privacy policy"
                        )}
                    </checkoutStateControl.Label>
                </checkoutStateControl> */}

                <CarPickup enable={enableCarPickup} />
                <PaymentTypes
                    size={"sm"}
                    onChange={handleUpdatecheckoutState}
                />
                <Hidden platform={"web"}>
                    <PromoCode
                        size={"sm"}
                        setOrder={handleUpdatecheckoutState}
                    />
                </Hidden>
                <View w="100%" mb={6}>
                    {/* Cart Summary */}
                    <Heading size={"md"} width={"100%"} textAlign={textAlign}>
                        {t("Cart Summary")}
                    </Heading>
                    <SummaryCart
                        flexDirection={flexDirection}
                        textAlign={textAlign}
                        cart={cart}
                        lineItems={checkoutState.order.lineItems}
                    />

                    {/* Delivery Charges */}
                    <ServiceCharges
                        isDelivery={checkoutState.isDelivery}
                        enabledServiceCharge={delivery?.enabledServiceCharge}
                        serviceCharges={checkoutState.order?.serviceCharges}
                    />

                    <Divider my={2} thickness={2.5} />

                    <Amount
                        title={t("Subtotal")}
                        titleSize={"xs"}
                        amount={
                            checkoutState.order?.totalMoney?.amount +
                            checkoutState.order?.totalDiscountMoney?.amount
                        }
                        amountSize={"xs"}
                        currency={currency}
                    />
                    {!checkoutState.order.totalDiscountMoney?.amount ? null : (
                        <Amount
                            title={t("Discount")}
                            titleSize={"xs"}
                            amount={
                                checkoutState.order?.totalDiscountMoney
                                    ?.amount * -1
                            }
                            amountSize={"xs"}
                            currency={currency}
                        />
                    )}

                    <Divider my={2} thickness={1} />

                    {checkoutState.order.taxes?.map((tax) => {
                        const texType =
                            tax.type === "INCLUSIVE"
                                ? "Included | مشمول"
                                : "Added | مضاف";
                        return (
                            <Amount
                                key={tax.uid || tax.catalogObjectId}
                                title={`${getLanguageText(
                                    language,
                                    tax.name ?? ""
                                )} ${getLanguageText(language, texType)} (${
                                    tax.percentage
                                }%)`}
                                titleSize={"xs"}
                                amount={tax?.appliedMoney?.amount as bigint}
                                amountSize={"xs"}
                                currency={currency}
                            />
                        );
                    })}

                    <Amount
                        title={t("Total")}
                        titleSize={"md"}
                        amount={
                            checkoutState.order?.totalMoney?.amount as bigint
                        }
                        amountSize={"md"}
                        currency={currency}
                    />
                </View>
            </ScrollView>
            <Box
                _light={_light}
                _dark={_dark}
                alignItems="center"
                px={6}
                borderRadius={"xl"}
                borderBottomRadius={"2xl"}
                justifyContent="space-between"
                safeAreaBottom={Platform.OS === "web" ? false : true}
                _web={{
                    position: "sticky",
                }}
                bottom={3}
            >
                <Button
                    isLoading={checkoutState.isProcessing}
                    onPress={() => {
                        if (
                            cart.isDelivery &&
                            delivery.minAmount > 0 &&
                            checkoutState.order?.totalMoney?.amount &&
                            checkoutState.order?.totalMoney?.amount <
                                delivery.minAmount
                        ) {
                            onToggle();
                            return;
                        }
                        handlePlaceOrder();
                    }}
                    size={"lg"}
                    width={"100%"}
                    borderTopRadius={"xl"}
                    borderBottomRadius={"2xl"}
                    _text={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginBottom: -1,
                    }}
                >
                    {paymentTypes?.isOnlinePayment ||
                    delivery?.isOnlinePaymentOnly
                        ? t("Pay and Confirm Order")
                        : t("Confirm Order")}
                </Button>
            </Box>

            <DeliverySheet
                isOpen={isOpen}
                onClose={onClose}
                completeText="Continue Shopping"
                closeText="Cancel"
                onComplete={async () => {
                    await navigation.navigate("Items", {
                        language: language,
                        locationId: locationId,
                    });
                    onClose();
                }}
                deliveryConfig={delivery}
            />

            {/* {PlatcheckoutState.OS !== "web" && (
                <AuthSheet
                    isOpen={openAuthSheet}
                    onClose={() => setOpenAuthSheet(false)}
                    phone={checkoutState.phoneNumber}
                    onComplete={(userId: string) => {
                        setOpenAuthSheet(false);
                        handlePlaceOrder(userId);
                    }}
                />
            )} */}
        </Box>
    );
}
