import LottieView from "lottie-react-native";
import { Center, Heading, Modal, useDisclose } from "native-base";
import React, { useEffect } from "react";
import { t } from "../translations/i18n";

interface ModalTableProps {
    tableNumber: string | number | undefined;
}

export default function ModalTable({ tableNumber }: ModalTableProps) {
    const { isOpen, onOpen, onClose } = useDisclose();

    useEffect(() => {
        if (tableNumber) {
            onOpen();
        }
    }, [tableNumber]);

    if (!tableNumber) {
        return;
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Body>
                    <Center>
                        <Heading size={"2xl"} mt={5}>{`${t(
                            "Table"
                        )} #${tableNumber}`}</Heading>
                        <LottieView
                            style={{
                                width: 200,
                                height: 200,
                            }}
                            source={require("../assets/lottie/warning.json")}
                            autoPlay
                            loop={false}
                        />
                    </Center>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
}
