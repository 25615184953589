/*
Copyright 2019 Square Inc.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/**
 *  Catalog Item information for the page
 *
 * Description:
 *  Bundles together a CatalogItemObject and its associated catalogImageObject
 *  into a class.
 *
 *  Learn more about CatalogItem objects here:  https://developer.squareup.com/reference/square/objects/CatalogItem
 *  Learn more about CatalogImage objects here: https://developer.squareup.com/reference/square/objects/CatalogImage
 */
export default function CatalogItem(catalogItemObj, image, modifiersObjects, categoryObject) {

  // Return the catalog item object Id
  const itemObjectId = catalogItemObj.id;

  // Returns  name of CatalogItemObject
  const name = catalogItemObj.item_data.name;

  // Returns description of CatalogItemObject
  const description = catalogItemObj.item_data.description;

  // Returns tax IDs of CatalogItemObject
  const taxIds = catalogItemObj.item_data?.tax_ids || [];

  // Returns associated image url for CatalogObject
  const imageUrl = image;

  // Returns the first variation in the variations array, our example we are selling one version of the item
  // We don't have to check invalid itemData because we've filtered them out when initialize.
  const defaultVariation = catalogItemObj?.item_data?.variations[0];

  // Returns CatalogItemObject id, which is what is actually sold
  // If no variation exists it defaults to the CatalogItemObject id
  const defaultVariationId = defaultVariation.id;

  // Returns the category ids of the CatalogItemObject the item can belong to more than one category
  const categories = catalogItemObj.item_data?.categories?.map((category) => category.id);

  // Returns price in dollars
  // item price money can be undefined, then we default to 0.00
  const price =
    defaultVariation.item_variation_data.price_money || 0
      ? `${(parseInt(defaultVariation.item_variation_data.price_money.amount) / 100).toFixed(2)}`
      : 0.0;

  // Return the price reage of catalogItemObj.item_data.variations for examole 1-10 as string
  const priceRange = () => {
    const variations = catalogItemObj.item_data.variations;
    //find max and min price
    let maxPrice = 0;
    let minPrice = 0;

    // sometime variations has the same price
    if (variations.length === 1) {
      return `${(variations[0].item_variation_data?.price_money?.amount / 100).toFixed(2)}`;
    }

    // sometime variations has multible prices and all the same price, so we need to return one price
    if (variations.length > 1 && variations.every((variation) => variation.item_variation_data?.price_money?.amount === variations[0].item_variation_data?.price_money?.amount)) {
      return `${(variations[0].item_variation_data?.price_money?.amount / 100).toFixed(2)}`;
    }
    // sometime variations has different price, so we need to return price range
    if (variations.length > 1 && variations.some((variation) => variation.item_variation_data?.price_money?.amount !== variations[0].item_variation_data?.price_money?.amount)) {
      maxPrice = Math.max(...variations.map((variation) => variation.item_variation_data?.price_money?.amount || 0));
      minPrice = Math.min(...variations.map((variation) => variation.item_variation_data?.price_money?.amount || 0));
      // min is 0, so we need to return the lowest price higher than 0 and the highest price
      if (minPrice === 0) {
        minPrice = Math.min(...variations.filter((variation) => variation.item_variation_data?.price_money?.amount > 0).map((variation) => variation.item_variation_data?.price_money?.amount));
      }
      // if min and max are the same, we need to return one price
      if (minPrice === maxPrice) {
        return `${(minPrice / 100).toFixed(2)}`;
      }

      return `${(minPrice / 100).toFixed(0)} - ${(maxPrice / 100).toFixed(0)}`;
    }
  }

  return {
    defaultVariationId,
    itemObjectId,
    name,
    description,
    taxIds,
    imageUrl,
    price,
    priceRange: priceRange(),
    currency: defaultVariation.item_variation_data?.price_money?.currency || "SAR",
    defaultVariation,
    variations: catalogItemObj.item_data.variations,
    present_at_all_locations: catalogItemObj?.present_at_all_locations,
    present_at_location_ids: catalogItemObj?.present_at_location_ids,
    absent_at_location_ids: catalogItemObj?.absent_at_location_ids,
    ecom_visibility: catalogItemObj.item_data.ecom_visibility,
    modifier_lists: modifiersObjects,
    categories,
    category: categoryObject // reporting category
  };
}
