import { Center, HStack, Link, Text } from "native-base";
import React from "react";
import { _dark, _light } from "../../Theme";
import { Platform } from "react-native";
import { BlurView } from "expo-blur";
const { version } = require("../../app.json").expo;

export default function Footer() {
    return (
        <Center
            w={"100%"}
            bottom={0}
            mt={"auto"}
            _light={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            _dark={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            _web={{
                position: "sticky",
            }}
        >
            <BlurView
                intensity={90}
                style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <HStack
                    space={2}
                    alignItems="center"
                    mt={1}
                    mb={Platform.OS !== "web" ? 3 : undefined}
                >
                    <Text>Powered by</Text>
                    <Link href="https://jaicome.com" isExternal>
                        <Text color={"primary.500"} underline fontSize={"lg"}>
                            Jaicome
                        </Text>
                    </Link>
                    <Text>{`v${version}`}</Text>
                </HStack>
            </BlurView>
        </Center>
    );
}
