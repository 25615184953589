import { Badge } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { t } from "../translations/i18n";

export default function SoldOutBadge() {
    return (
        <Badge
            size={"xs"}
            _text={{
                mt: 1,
                bottom: 0.5,
                noOfLines: 1,
                padding: 0,
                margin: 0,
                fontSize: "xs",
                fontWeight: "400",
                height: Platform.OS === "android" ? "20px" : "14px",
            }}
            h={"18px"}
            paddingY={0}
            marginY={0}
            variant="outline"
            colorScheme="danger"
        >
            {t("Sold Out")}
        </Badge>
    );
}
