import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
    Alert,
    Button,
    Center,
    HStack,
    Icon,
    Text,
    Toast,
    useDisclose,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import {
    setIsDelivery,
    setParkingNumber,
    setTableNumber,
} from "../redux/reducers/manageCart";
import i18n, { t } from "../translations/i18n";
import { convertTimeTo12, isDeliveryAvailable } from "../utils";
import { Delivery, ReduxState, ServiceTime } from "../utils/types";
import DeliverySheet from "./DeliverySheet";
import { Platform } from "react-native";

interface Props {
    deliveryConfig: Delivery;
    handelNavigateToItemsScreen: any;
}

export default function ButtonDelivery({
    deliveryConfig,
    handelNavigateToItemsScreen,
}: Props) {
    const dispatch = useDispatch();

    const currentUserLocation = useSelector((s: ReduxState) => s.auth.location);

    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";

    const { isOpen, onClose, onToggle } = useDisclose();

    const displayToast = (errorMessage: string) => {
        Toast.show({
            placement: "top",
            render: () => {
                return (
                    <Center>
                        <Alert
                            status="error"
                            minW="88%"
                            maxW="88%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <HStack flexDirection={flexDirection}>
                                <Alert.Icon />
                                <Text flexShrink={1} mx={2}>
                                    {errorMessage}
                                </Text>
                            </HStack>
                        </Alert>
                    </Center>
                );
            },
        });
    };

    // If there is no delivery settings or delivery is not active, return null
    if (!deliveryConfig || !deliveryConfig.isActive) return null;

    return (
        <>
            <Button
                borderRadius={"xl"}
                size="lg"
                width={"100%"}
                _text={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: -1,
                }}
                leftIcon={
                    <Icon
                        as={MaterialCommunityIcons}
                        name="truck-fast"
                        size="sm"
                    />
                }
                onPress={() => {
                    // Reset table and parking number
                    dispatch(setTableNumber(0));
                    dispatch(setParkingNumber(0));

                    if (currentUserLocation) {
                        const { startAt, endAt } =
                            deliveryConfig?.serviceTime as ServiceTime;
                        if (
                            startAt &&
                            endAt &&
                            !isDeliveryAvailable(startAt, endAt)
                        ) {
                            displayToast(
                                `${t(
                                    "Delivery service is not available at this time, it start at"
                                )} ${convertTimeTo12(startAt)} ${t(
                                    "and end at"
                                )} ${convertTimeTo12(endAt)}`
                            );
                            return;
                        }
                        if (deliveryConfig.minAmount > 0) {
                            onToggle();
                            return;
                        }
                        if (currentUserLocation) {
                            handelNavigateToItemsScreen();
                            dispatch(setIsDelivery(true));
                        } else {
                            displayToast(
                                t(
                                    "Please enable location services to use delivery and try again after reloading the page"
                                )
                            );
                        }
                    } else {
                        if (Platform.OS === "web")
                            displayToast(
                                t(
                                    "Please refresh the page and give permission to enable browser location services for delivery orders"
                                )
                            );
                        else
                            displayToast(
                                t(
                                    "Please enable app location services for delivery orders"
                                )
                            );
                    }
                }}
            >
                {t("DELIVERY")}
            </Button>

            <DeliverySheet
                isOpen={isOpen}
                closeText={"Cancel"}
                onClose={() => {
                    dispatch(setIsDelivery(false));
                    onClose();
                }}
                completeText={"Continue"}
                onComplete={() => {
                    handelNavigateToItemsScreen();
                    dispatch(setIsDelivery(true));
                    onClose();
                }}
                deliveryConfig={deliveryConfig}
            />
        </>
    );
}
