import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import {
    Center,
    HStack,
    Icon,
    IconButton,
    Input,
    Menu,
    VStack,
} from "native-base";
import React from "react";
import { useDispatch } from "react-redux";
import { setLanguage } from "../redux/reducers/manageLanguage";
import i18n, { t, useTranslation } from "../translations/i18n";
import LocationsMap from "./LocationsMap";

type MapProps = {
    userLocation: {
        lat: number;
        lng: number;
    };
    listLocation: any[];
    handleSearch?: (text: string) => void;
};

export default function AppLocationsBar({
    userLocation,
    listLocation,
    handleSearch,
}: MapProps): JSX.Element {
    const language = i18n.locale === "ar" ? "ar" : "en";
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";
    const textAlign = i18n.locale === "ar" ? "right" : "left";

    const dispatch = useDispatch();
    const navigation = useNavigation();

    const handleLanguageChange = (languageCode: string) => {
        useTranslation(languageCode);
        dispatch(setLanguage(languageCode));

        navigation.navigate("Locations", {
            language: languageCode,
        });
    };

    return (
        <Center safeAreaTop bg="primary.600" roundedBottom={"2xl"}>
            <VStack
                bg="primary.600"
                p="3"
                pb={4}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                roundedBottom={"2xl"}
            >
                <HStack
                    alignItems={"center"}
                    justifyContent="space-between"
                    width={"100%"}
                    pb={2}
                    flexDirection={flexDirection}
                    textAlign={textAlign}
                >
                    {language === "ar" ? (
                        <Image
                            style={{
                                width: 95,
                                height: 26,
                                marginHorizontal: 10,
                            }}
                            source={require(`./../assets/images/jaicome-logo-ar.png`)}
                            aria-label={`app-logo-ar`}
                            accessibilityLabel={`app-logo-ar`}
                        />
                    ) : (
                        <Image
                            style={{
                                width: 95,
                                height: 26,
                                marginHorizontal: 10,
                            }}
                            source={require(`./../assets/images/jaicome-logo-en.png`)}
                            aria-label={`app-logo-en`}
                            accessibilityLabel={`app-logo-en`}
                        />
                    )}
                    <Menu
                        w="190"
                        trigger={(triggerProps) => {
                            return (
                                <IconButton
                                    size="sm"
                                    marginX={2}
                                    padding={1}
                                    icon={
                                        <Icon
                                            size={"lg"}
                                            as={MaterialIcons}
                                            name="more-vert"
                                            color="white"
                                        />
                                    }
                                    accessibilityLabel="More options menu"
                                    {...triggerProps}
                                />
                            );
                        }}
                    >
                        <Menu.Group title={t("Language")}>
                            <Menu.Item
                                textAlign={textAlign}
                                onPress={() => handleLanguageChange("ar")}
                            >
                                عربي
                            </Menu.Item>
                            <Menu.Item
                                textAlign={textAlign}
                                onPress={() => handleLanguageChange("en")}
                            >
                                English
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                </HStack>
                <HStack
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                    space={2}
                    paddingX={2}
                    flexDirection={flexDirection}
                    textAlign={textAlign}
                >
                    <Input
                        size="xs"
                        placeholder={t("Subject, Restaurants, Cafes, etc")}
                        bgColor="white"
                        textAlign={textAlign}
                        flex={1}
                        _input={{
                            paddingX: 0,
                            paddingY: 1,
                            fontSize: "sm",
                        }}
                        onChange={(e) => handleSearch?.(e.nativeEvent.text)}
                        isFullWidth={true}
                        InputLeftElement={
                            language === "en" ? (
                                <Icon
                                    size="sm"
                                    mx="2"
                                    as={MaterialIcons}
                                    name="search"
                                    color="primary.600"
                                />
                            ) : (
                                <></>
                            )
                        }
                        InputRightElement={
                            language === "ar" ? (
                                <Icon
                                    size="md"
                                    mx="2"
                                    as={MaterialIcons}
                                    name="search"
                                    color="primary.600"
                                />
                            ) : (
                                <></>
                            )
                        }
                    />

                    <LocationsMap
                        currentLocation={{
                            lat: userLocation?.latitude as number,
                            lng: userLocation?.longitude as number,
                        }}
                        listLocation={listLocation}
                        withCurrnetLocationMarker={true}
                    />
                </HStack>
            </VStack>
        </Center>
    );
}
