import { HStack, Heading } from "native-base";
import React from "react";
import i18n from "../../translations/i18n";
import { priceFormat } from "../../utils";

interface Prop {
    title: string;
    titleSize: string;
    amount: number | bigint;
    amountSize: string;
    currency: string;
}

export default function Amount({
    title,
    titleSize,
    amount,
    amountSize,
    currency,
}: Prop) {
    const language = i18n.locale;
    const flexDirection = language === "ar" ? "row-reverse" : "row";

    return (
        <HStack
            flexDirection={flexDirection}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Heading size={titleSize}>{title}</Heading>
            <HStack flexDirection={flexDirection} alignItems={"center"}>
                <Heading size={amountSize} mx={1}>
                    {priceFormat(amount)}
                </Heading>
                <Heading size={"xs"} color={"primary.600"}>
                    {currency}
                </Heading>
            </HStack>
        </HStack>
    );
}
