import { Platform } from 'react-native';
import { hostingUrl } from './configs';
const { version } = require("../../app.json").expo;

// * Initiate payment
export const initPayment = async (order_payment_ref: string, phone_number: string, amount: number, merchantId: string,
	locationId: string, language?: string) => {
	const payment = await fetch(`${hostingUrl}/payment/initiate`, {
		method: 'POST',
		mode: 'cors',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			order_payment_ref,
			phone_number,
			amount,
			merchantId,
			locationId,
			platform: Platform.OS,
			version: `v${version}`,
			language: language || 'ar',
		}),
	}).then((result) => result.json());

	return payment;
};
