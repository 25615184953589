import { Badge, HStack } from "native-base";
import React from "react";
import i18n, { t } from "../translations/i18n";
import { _dark, _light } from "../../Theme";

export default function LocationBadges({ location }: any) {
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";

    if (!location.isActive && !location.numberOfTables && !location.hasDelivery)
        return null;

    return (
        <HStack space={1} flexDirection={flexDirection}>
            {!location.isActive ? null : (
                <Badge
                    colorScheme="primary"
                    rounded="md"
                    _web={{
                        _text: {
                            height: "14px",
                            fontSize: "xs",
                        },
                    }}
                    _dark={{ _text: { color: _dark.bg } }}
                >
                    {t("PICK-UP").toLocaleUpperCase()}
                </Badge>
            )}
            {!location.numberOfTables ? null : (
                <Badge
                    colorScheme="primary"
                    rounded="md"
                    _web={{
                        _text: {
                            height: "14px",
                            fontSize: "xs",
                        },
                    }}
                    _dark={{ _text: { color: _dark.bg } }}
                >
                    {t("DINE-IN").toLocaleUpperCase()}
                </Badge>
            )}
            {!location.hasDelivery ? null : (
                <Badge
                    colorScheme="primary"
                    rounded="md"
                    _web={{
                        _text: {
                            height: "14px",
                            fontSize: "xs",
                        },
                    }}
                    _dark={{ _text: { color: _dark.bg } }}
                >
                    {t("DELIVERY").toLocaleUpperCase()}
                </Badge>
            )}
        </HStack>
    );
}
