import { Image } from "expo-image";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React from "react";
import { _dark, _light } from "../../Theme";
import i18n from "../translations/i18n";
import { getLanguageText } from "../utils/textFormat";

export default function RegisterLocationCard({ location, onPress }: any) {
    return (
        <Box width={"100%"} _dark={_dark} _light={_light}>
            <Pressable mt={4} mx={4} onPress={() => onPress()}>
                {({ isPressed }) => {
                    return (
                        <Box
                            width={"100%"}
                            _dark={{
                                borderColor: "coolGray.700",
                                backgroundColor: "gray.800",
                            }}
                            _light={{
                                backgroundColor: "gray.50",
                            }}
                            borderWidth="1"
                            borderColor="coolGray.300"
                            shadow="3"
                            p="5"
                            rounded="8"
                            style={{
                                transform: [
                                    {
                                        scale: isPressed ? 0.96 : 1,
                                    },
                                ],
                            }}
                        >
                            <HStack alignItems="center">
                                {!location.logoUrl ? null : (
                                    <>
                                        <Image
                                            style={{
                                                width: 80,
                                                height: 80,
                                                borderRadius: 20,
                                            }}
                                            source={{ uri: location?.logoUrl }}
                                            aria-label={`store-logo`}
                                        />
                                    </>
                                )}

                                <VStack padding={2} mx="1">
                                    <Text
                                        mt="3"
                                        fontWeight="medium"
                                        fontSize="xl"
                                    >
                                        {getLanguageText(
                                            i18n.locale,
                                            location.businessName
                                        )}
                                    </Text>
                                    <Text
                                        mt="1"
                                        fontWeight="medium"
                                        fontSize="md"
                                    >
                                        {getLanguageText(
                                            i18n.locale,
                                            location.name
                                        )}
                                    </Text>
                                </VStack>
                            </HStack>
                        </Box>
                    );
                }}
            </Pressable>
        </Box>
    );
}
