import LottieView from "lottie-react-native";
import { Badge, Button, Center, Heading, Spacer, Text } from "native-base";
import { useEffect, useState } from "react";
import { Alert } from "react-native";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../redux/reducers/manageLanguage";
import i18n, { t } from "../../translations/i18n";
import { createOrderAfterPayment } from "./utils";

export default function PaymentScreen({ navigation, route }: any) {
    const dispatch = useDispatch();

    // ? This is the response from the payment gateway:
    // ? Example:
    // http://app.jaicome.com/ar/payment?id=bc8891e5-82e6-4fc4-9742-7189f88a6710&status=paid&message=APPROVED&invoice_id=cee8f77b-42c1-48a8-bd76-5e7d298fbd60
    // http://localhost:19006/ar/payment?id=bc8891e5-82e6-4fc4-9742-7189f88a6710&status=paid&message=APPROVED&invoice_id=cee8f77b-42c1-48a8-bd76-5e7d298fbd60

    const { status, message, invoice_id } = route.params;
    const language = route.params.language || "ar";
    i18n.locale = language;

    console.log(language, status, message, invoice_id);

    const [orderId, setOrderId] = useState<string>("");

    // ? Go back to the previous screen
    const onGoBack = () => {
        if (navigation?.canGoBack()) {
            navigation?.goBack();
        }
    };

    useEffect(() => {
        // * Set the language (Moved to useEffect to avoid re-rendering the component multiple times)
        dispatch(setLanguage(language));

        const onCreateOrder = async () => {
            try {
                if (status === "paid" && invoice_id) {
                    await createOrderAfterPayment(
                        invoice_id,
                        setOrderId,
                        (orderId) => {
                            navigation.navigate("OrderStatus", {
                                language,
                                order_id: orderId,
                            });
                        }
                    );
                } else {
                    throw new Error("Payment is not valid, please try again.");
                }
            } catch (error: any) {
                const message = String(error.message);
                console.log("[ERR @ onCreateOrder]:", message);
                Alert.alert(
                    "Error",
                    message,
                    [
                        {
                            text: "OK",
                            onPress: onGoBack,
                        },
                    ],
                    { cancelable: false }
                );
            }
        };

        setTimeout(() => {
            onCreateOrder();
        }, 1000);
    }, []);

    return (
        <Center flex={1} padding={4}>
            <Spacer />

            <Center>
                <LottieView
                    source={require("../../assets/lottie/card-payment-successful.json")}
                    autoPlay
                    loop={false}
                    style={{ width: 250, height: 250 }}
                />
            </Center>

            <Heading textAlign="center" size="lg">
                {status === "paid"
                    ? t("Payment Successful")
                    : t("Payment Failed")}
            </Heading>

            <Text textAlign="center" color="muted.500">
                {t("Wait while navigating you to the order status screen")}
            </Text>

            <Badge
                margin={3}
                colorScheme={status === "paid" ? "success" : "danger"}
            >
                {t(message)}
            </Badge>

            <Spacer />

            <Center padding={5}>
                <Heading size={"xs"}>{t("Payment Reference ID")}</Heading>
                <Text>{invoice_id}</Text>
            </Center>

            {orderId && (
                <Center>
                    <Spacer />

                    <Button
                        width={"100%"}
                        onPress={() => {
                            navigation.navigate("OrderStatus", {
                                language: language,
                                order_id: orderId,
                            });
                        }}
                    >
                        {t("View Order Status")}
                    </Button>
                </Center>
            )}
        </Center>
    );
}
