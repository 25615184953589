

interface OrderStatus {
    icon: string;
    color: string;
    status: string;
    hasDivider: boolean;
}



const orderPendingStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "gray.200",
        status: "Order accepted",
        hasDivider: true,
    },
    ready: {
        icon: "bell-ring",
        color: "gray.200",
        status: "Ready",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "gray.200",
        status: "Picked Up",
        hasDivider: false,
    },
};

const orderAcceptStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "primary.600",
        status: "Order accepted",
        hasDivider: true,
    },
    ready: {
        icon: "bell-ring",
        color: "gray.200",
        status: "Ready",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "gray.200",
        status: "Picked Up",
        hasDivider: false,
    },
};

const orderReadyStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "primary.600",
        status: "Order accepted",
        hasDivider: true,
    },
    ready: {
        icon: "bell-ring",
        color: "primary.600",
        status: "Ready",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "gray.200",
        status: "Picked Up",
        hasDivider: false,
    },
};

const orderDeliveryPendingStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "gray.200",
        status: "Order accepted",
        hasDivider: true,
    },
    delivery: {
        icon: "truck-fast",
        color: "gray.200",
        status: "Driver on the way",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "gray.200",
        status: "Picked Up",
        hasDivider: false,
    },
};

const orderDeliveryAcceptStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "primary.600",
        status: "Order accepted",
        hasDivider: true,
    },
    delivery: {
        icon: "truck-fast",
        color: "gray.200",
        status: "Driver on the way",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "gray.200",
        status: "Delivered",
        hasDivider: false,
    },
};

const orderDeliveryReadyStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "primary.600",
        status: "Order accepted",
        hasDivider: true,
    },
    delivery: {
        icon: "truck-fast",
        color: "primary.600",
        status: "Driver on the way",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "gray.200",
        status: "Delivered",
        hasDivider: false,
    },
};

const orderDeliveryPickupStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "primary.600",
        status: "Order accepted",
        hasDivider: true,
    },
    delivery: {
        icon: "truck-fast",
        color: "primary.600",
        status: "Driver on the way",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "primary.600",
        status: "Delivered",
        hasDivider: false,
    },
};

const orderPickupStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    accepted: {
        icon: "check-circle",
        color: "primary.600",
        status: "Order accepted",
        hasDivider: true,
    },
    ready: {
        icon: "bell-ring",
        color: "primary.600",
        status: "Ready",
        hasDivider: true,
    },
    pickedUp: {
        icon: "shopping",
        color: "primary.600",
        status: "Picked Up",
        hasDivider: false,
    },
};

const orderCancelStage: { [key: string]: OrderStatus } = {
    send: {
        icon: "send-circle",
        color: "primary.600",
        status: "Order Send",
        hasDivider: true,
    },
    pending: {
        icon: "clock-alert",
        color: "primary.600",
        status: "Pending",
        hasDivider: true,
    },
    canceled: {
        icon: "close-circle",
        color: "primary.600",
        status: "Canceled",
        hasDivider: false,
    },
};

export const OrderStutus: { [key: string]: { [key: string]: OrderStatus } } = {
    PROPOSED: orderPendingStage,
    RESERVED: orderAcceptStage,
    PREPARED: orderReadyStage,
    COMPLETED: orderPickupStage,
    CANCELED: orderCancelStage,
    // DELIVERY
    DELIVERY_PROPOSED: orderDeliveryPendingStage,
    DELIVERY_RESERVED: orderDeliveryAcceptStage,
    DELIVERY_PREPARED: orderDeliveryReadyStage,
    DELIVERY_COMPLETED: orderDeliveryPickupStage,
    DELIVERY_CANCELED: orderCancelStage,
}