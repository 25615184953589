import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import {
    Box,
    Button,
    Divider,
    Heading,
    Hidden,
    HStack,
    Icon,
    IconButton,
    KeyboardAvoidingView,
    ScrollView,
    Spacer,
    Stack,
    Text,
    TextArea,
} from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";

// Components
import CartBar from "../components/Cart/CartBar";
import ItemModifiers from "../components/ItemModifiers";
import ItemVariations from "../components/ItemVariations";

import InputSpinner from "../components/InputSpinner";
// Setup the theme
import { _dark, _light } from "../../Theme";
// Types
import OrderLineItem from "../interfaces/OrderLineItem";
import OrderLineItemModifier from "../interfaces/OrderLineItem/OrderLineItemModifier";
import { Money, ReduxState } from "../utils/types";
// Helper function
import CenterSpinner from "../components/CenterSpinner";
import useIsLocationOpen from "../hooks/useIsLocationOpen";
import useLocation from "../hooks/useLocation";
import { setLanguage } from "../redux/reducers/manageLanguage";
import i18n, { t } from "../translations/i18n";
import { handleShare, priceFormat } from "../utils";
import { getLanguageText } from "../utils/textFormat";
import { addItem, clearCart } from "./../redux/reducers/manageCart";

export default function ProductScreen({ route }) {
    const cart = useSelector((state: ReduxState) => state.cart);
    const navigation = useNavigation();

    const islocationOpen = useIsLocationOpen();
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";
    const textAlign = i18n.locale === "ar" ? "right" : "left";

    const dispatch = useDispatch();

    const { language, locationId, itemId } = route.params;
    const { location, items } = useLocation(locationId);

    // Set the default item info
    const [item, setItem] = useState<any>();
    const [selectedVariation, setSelectedVariation] = useState<any>();
    const [selectedModifiers, setSelectedModifiers] = useState<
        OrderLineItemModifier[]
    >([]);
    const [selectedQty, setSelectedQty] = useState<number>(1);
    const [note, setNote] = useState<string>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const total = useMemo(() => {
        if (selectedVariation) {
            let totalPrice = selectedVariation.price_money.amount;
            selectedModifiers.forEach((modifier: OrderLineItemModifier) => {
                const amount = modifier.basePriceMoney.amount as number;
                totalPrice += parseFloat(amount.toString());
            });
            return totalPrice;
        }
        return 0;
    }, [selectedVariation, selectedModifiers]);

    const isLoading = useMemo(() => {
        return isLoadingData || itemId !== item?.itemObjectId || !item;
    }, [
        isLoadingData,
        items,
        itemId !== item?.itemObjectId,
        setIsLoadingData,
        item,
    ]);

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoadingData(true);
                // location set in the previous screen
                if (location?.id !== locationId) {
                    dispatch(clearCart());
                }
                const selectedItem = items.find(
                    (item: any) => item.itemObjectId === itemId
                );
                if (selectedItem) {
                    setItem(selectedItem);
                    setSelectedVariation({
                        id: selectedItem.variations[0].id,
                        name: selectedItem.variations[0].item_variation_data
                            .name,
                        price_money: {
                            amount: Number(
                                selectedItem.variations[0].item_variation_data
                                    .price_money.amount
                            ),
                        } as Money,
                    });
                }

                setIsLoadingData(false);
            } catch (error) {
                console.log(error);
            }
        };

        i18n.locale = language;
        dispatch(setLanguage(language));
        setSelectedModifiers([]); // Reset the modifiers if page is reloaded
        loadData();
    }, [dispatch, items, itemId, language, location, locationId]);

    const addItemToCart = async () => {
        setIsProcessing(true);
        const totalPrice = total;
        const orderLineItem: OrderLineItem = {
            catalogObjectId: selectedVariation.id,
            itemType: "ITEM",
            name: item.name,
            variationName: selectedVariation.name,
            quantity: selectedQty.toString(),
            modifiers: selectedModifiers,
            basePriceMoney: {
                amount: selectedVariation.price_money.amount,
                currency: item.currency_code,
            } as Money,
            // appliedTaxes: appliedTaxes,
            variationTotalPriceMoney: {
                amount: selectedVariation.price_money.amount * selectedQty,
                currency: item.currency_code,
            } as Money,
            appliedTaxes: item.taxIds.map((taxId: string, index: number) => {
                return { taxUid: Date.now().toString() + index, uid: taxId };
            }),
            totalMoney: {
                amount: totalPrice,
                currency: item.currency_code,
            } as Money,
            grossSalesMoney: {
                amount: totalPrice * selectedQty,
                currency: item.currency_code,
            } as Money,
            note: note,
        };

        // Add Item to shopping cart at redux cart state
        dispatch(
            addItem({
                key: Date.now().toString(),
                data: orderLineItem,
                url: item.imageUrl,
            })
        );

        setIsProcessing(false);

        // Navigate to the items screen
        await navigation.navigate("Items", {
            language: language,
            locationId: locationId,
        });
    };

    // If the item is not loaded yet, show the spinner
    if (isLoading) return <CenterSpinner />;

    // Display the product info
    return (
        <Box
            flex={1}
            width={Dimensions.get("window").width}
            maxHeight={Dimensions.get("window").height}
            alignSelf="center"
            _dark={_dark}
            _light={_light}
        >
            <ScrollView flex={1} showsVerticalScrollIndicator={false}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === "ios" ? "padding" : "height"}
                >
                    {/* Cart  Bar */}
                    <CartBar
                        cart={cart}
                        goBack={() =>
                            navigation.navigate("Items", {
                                language: language,
                                locationId: locationId,
                            })
                        }
                    />

                    {/* Product Image */}
                    <Box w={"100%"}>
                        {/* <AspectRatio w={393} h={295}> */}
                        <HStack
                            w={"100%"}
                            justifyContent={"space-between"}
                            position={"absolute"}
                            bottom={-1}
                            m={0}
                            pt={3}
                            px={6}
                            pb={0}
                            zIndex={1}
                            style={{
                                background: `linear-gradient(to top, ${_light.bg} 10%, transparent 100%)`,
                                boxShadow: `inset 0px -20px 10px -10px ${_light.bg}`,
                            }}
                        >
                            <Hidden platform={["web"]}>
                                <IconButton
                                    variant={"ghost"}
                                    rounded={"full"}
                                    colorScheme={"primary"}
                                    width={8}
                                    height={8}
                                    onPress={() =>
                                        handleShare(
                                            `https://app.jaicome.com/${language}/${locationId}/id/${itemId}`,
                                            `${getLanguageText(
                                                i18n.locale,
                                                item.name
                                            )} - ${getLanguageText(
                                                i18n.locale,
                                                location.name
                                            )}`
                                        )
                                    }
                                    icon={
                                        <Icon
                                            as={Feather}
                                            name="share"
                                            size={"md"}
                                        />
                                    }
                                />
                            </Hidden>

                            <Heading
                                textAlign={textAlign}
                                w={"100%"}
                                size={"xl"}
                                numberOfLines={1}
                                fontSize={{ base: "2xl", md: "3xl" }}
                            >
                                {getLanguageText(i18n.locale, item.name)}
                            </Heading>
                        </HStack>

                        <Image
                            source={
                                item.imageUrl
                                    ? {
                                          uri: item.imageUrl,
                                          cacheKey: item.imageUrl,
                                      }
                                    : require("./../assets/images/image-url-non.png")
                            }
                            style={{
                                minHeight: 295,
                                opacity: !item.imageUrl ? 0.1 : 1,
                            }}
                            contentFit="cover"
                        />

                        {/* </AspectRatio> */}
                    </Box>

                    {/* Item Options Box */}
                    <Box p={6} pt={0}>
                        {/* Item Name and  Description */}
                        <Box>
                            <Text textAlign={textAlign} w={"100%"}>
                                {getLanguageText(i18n.locale, item.description)}
                            </Text>
                        </Box>

                        {/* Item Variations Names and Prices */}
                        <ItemVariations
                            textAlign={textAlign}
                            flexDirection={flexDirection}
                            variations={item.variations}
                            onChange={setSelectedVariation}
                        />

                        {/* Item Modifiers Names and Prices */}
                        <ItemModifiers
                            textAlign={textAlign}
                            flexDirection={flexDirection}
                            modifier_lists={item.modifier_lists}
                            onChange={setSelectedModifiers}
                        />

                        {/* Item Line Note */}
                        <TextArea
                            textAlign={textAlign}
                            variant="filled"
                            placeholder={t("Add Note")}
                            mt={3}
                            onChangeText={(value) => setNote(value)}
                        />
                    </Box>
                </KeyboardAvoidingView>
            </ScrollView>

            <Box>
                <Divider />

                <HStack
                    flexDirection={flexDirection}
                    width={"100%"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    px={6}
                    my={0}
                >
                    <Stack>
                        <Text textAlign={textAlign} py={1}>
                            {t("Price")}
                        </Text>
                        <HStack p={1} mx={2} flexDirection={flexDirection}>
                            {/* regular_price */}
                            <Heading>{priceFormat(total)}</Heading>
                            {/* currency_code */}
                            <Heading
                                size={"sm"}
                                color={"primary.500"}
                                p={"0.5"}
                            >
                                {location.currency}
                            </Heading>
                        </HStack>
                    </Stack>

                    {!location.isActive ? null : (
                        <Stack>
                            <Text textAlign={textAlign} py={1}>
                                {t("Quantity")}
                            </Text>
                            <InputSpinner onChange={setSelectedQty} />
                        </Stack>
                    )}
                </HStack>

                {!location.isActive ? null : (
                    <Box
                        alignItems="center"
                        mx={4}
                        _web={{ mb: 2 }}
                        mb={5}
                        mt={2}
                        borderRadius={"xl"}
                        justifyContent="space-between"
                    >
                        {islocationOpen ? (
                            <Button
                                isLoading={isProcessing}
                                isLoadingText={t("Adding")}
                                onPress={() => addItemToCart()}
                                size={"lg"}
                                width={"100%"}
                                borderTopRadius={"xl"}
                                borderBottomRadius={"2xl"}
                                _text={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    marginBottom: -1,
                                }}
                            >
                                {t("Add to Cart")}
                            </Button>
                        ) : (
                            <Button
                                size={"lg"}
                                width={"100%"}
                                borderTopRadius={"xl"}
                                borderBottomRadius={"2xl"}
                                colorScheme="error"
                                disabled
                                _text={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    marginBottom: -1,
                                }}
                            >
                                {t("Store Closed")}
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
}
