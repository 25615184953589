/**
 * Guarantees that a promise throw will be handled and returned gracefully as an error if any
 * The returned promise will never throw an exception
 * Result and error type can be specified through awaitable<ResultType, ErrorType>
 * @param fn Promise
 * @returns Promise<[result, error]>
 * - `result`: Returned on success, null on throw (Infered type of `fn`)
 * - `error`: Null on success, returned on throw (Default to Error)
 */
/* Modified version from karanpratapsingh */
async function awaitable<R, E = Error>(
  fn: Promise<R>,
): Promise<[R | null, E | null]> {
  try {
    // // eslint-disable-next-line no-throw-await/no-direct-await
    const data: R = await fn;
    return [data, null];
  } catch (error: any) {
    return [null, error];
  }
}

export default awaitable;
