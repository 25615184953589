import {
    MaterialCommunityIcons,
    Fontisto,
    MaterialIcons,
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { doc, onSnapshot } from "firebase/firestore";
import LottieView from "lottie-react-native";
import {
    Badge,
    Box,
    Button,
    Center,
    HStack,
    Heading,
    Icon,
    IconButton,
    Link,
    Modal,
    Progress,
    Spacer,
    Stack,
    StatusBar,
    Text,
    VStack,
    ZStack,
    useDisclose,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Image } from "expo-image";

import { Dimensions, Platform } from "react-native";
import WebView from "react-native-webview";
import { useDispatch } from "react-redux";
import { _dark, _light } from "../../Theme";
import CenterSpinner from "../components/CenterSpinner";
import OrderStutusSteps from "../components/OrderStutusSteps";
import { db } from "../firebase/firebase";
import { getLocationInfo } from "../firebase/reads";
import { clearCart } from "../redux/reducers/manageCart";
import { setLanguage } from "../redux/reducers/manageLanguage";
import i18n, { t } from "../translations/i18n";
import { getLanguageText } from "../utils/textFormat";

export default function OrderSuccessScreen({ route }) {
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";
    const textAlign = i18n.locale === "ar" ? "right" : "left";

    const { order_id, language } = route.params;

    const navigation = useNavigation();
    const dispatch = useDispatch();

    const { isOpen, onToggle } = useDisclose();
    const modal = useDisclose();

    // set language
    i18n.locale = language;
    dispatch(setLanguage(language));

    const [counter, setCounter] = useState<number>(0);

    const [state, setState] = useState({
        orderState: null,
        createdAt: "",
        updatedAt: [],
        paymentId: "",
        counter: 0,
        location: null,
        locationId: "",
        isDelivery: false,
        isOnlinePayment: false,
    });

    const minutes = 1000 * 60 * 5;

    const updateState = (newState: any) => {
        setState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    // function count different bettwen currentTime and createAt and update state every second
    useEffect(() => {
        const interval = setInterval(() => {
            if (state.createdAt) {
                const diff =
                    new Date().getTime() - new Date(state.createdAt).getTime();
                setCounter(diff);
            }
        }, 1000);

        return () => clearInterval(interval);
    });

    // clear cart
    useEffect(() => {
        dispatch(clearCart());
    }, []);

    // onSnapshot for orders collection firestore version 9
    useEffect(() => {
        const unsub = onSnapshot(
            doc(db, "orders", order_id),
            async (doc: any) => {
                if (doc.exists()) {
                    updateState({
                        orderState: doc.data().state,
                        createdAt: doc.data().createdAt,
                        updatedAt: doc.data().updatedAt,
                        paymentId: doc.data().paymentId,
                        locationId: doc.data().locationId,
                        isDelivery:
                            doc.data().source == "Jaicome Delivery"
                                ? true
                                : false,
                        isOnlinePayment: doc.data().isOnlinePayment,
                    });
                }
                if (doc.data().state !== state.orderState) {
                    modal.onOpen();
                }
            }
        );
        return unsub;
    }, []);

    // if not location load location data
    useEffect(() => {
        if (!state.location) {
            getLocationInfo(state.locationId).then((location) => {
                updateState({ location });
            });
        }
    }, [state.locationId]);

    // order data is not loaded yet
    if (
        !state.orderState ||
        !state.updatedAt ||
        !state.createdAt ||
        !state.location
    )
        return <CenterSpinner />;

    if (isOpen)
        return (
            <>
                <WebView
                    source={{
                        uri: `https://squareup.com/receipt/preview/${state.paymentId}`,
                    }}
                />
                <Stack
                    space={1}
                    px={4}
                    pt={2}
                    _web={{ mb: 2 }}
                    my={4}
                    mt={"auto"}
                    borderRadius={"xl"}
                    width={"100%"}
                >
                    <Button
                        onPress={() => onToggle()}
                        size={"lg"}
                        borderTopRadius={"xl"}
                        borderBottomRadius={"2xl"}
                        _text={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: -1,
                        }}
                    >
                        {t("Back")}
                    </Button>
                </Stack>
            </>
        );

    return (
        <Center
            justifyContent={"flex-start"}
            h={"100%"}
            _dark={_dark}
            _light={_light}
        >
            <StatusBar barStyle={"light-content"} />
            <Box zIndex={2} position="absolute" w="100%" top={0}>
                <Box safeAreaTop />
                <HStack
                    px={4}
                    _web={{ my: 3, px: 4 }}
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                >
                    <Spacer />
                    <HStack space={2}>
                        {!state.location?.phoneNumber ? null : (
                            <Button
                                // onPress={onOpen}
                                width={"36px"}
                                height={"36px"}
                                shadow={3}
                                borderRadius="full"
                                variant={"unstyled"}
                                colorScheme={"dark"}
                                borderWidth={1.5}
                                borderColor={"white"}
                            >
                                <Link
                                    href={`tel://${state.location?.phoneNumber}`}
                                    isExternal
                                >
                                    <Icon
                                        as={Fontisto}
                                        name="phone"
                                        size={"sm"}
                                        color={"white"}
                                    />
                                </Link>
                            </Button>
                        )}

                        {!state.location?.coordinates ? null : (
                            <Button
                                // onPress={onOpen}
                                width={"36px"}
                                height={"36px"}
                                shadow={3}
                                borderRadius="full"
                                variant={"unstyled"}
                                colorScheme={"dark"}
                                borderWidth={1.5}
                                borderColor={"white"}
                            >
                                <Link
                                    href={`https://www.google.com/maps/place/${state.location?.coordinates.latitude},${state.location?.coordinates.longitude}`}
                                    isExternal
                                >
                                    <Icon
                                        as={MaterialCommunityIcons}
                                        name="map-legend"
                                        size={"sm"}
                                        color={"white"}
                                    />
                                </Link>
                            </Button>
                        )}
                    </HStack>
                </HStack>
            </Box>
            {/* Location/Store Image Cover */}
            <ZStack
                w={"100%"}
                backgroundColor={"primary.600"}
                h={200}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Center shadow={3}>
                    <Image
                        source={{
                            uri: state.location?.posBackgroundUrl,
                        }}
                        style={{
                            opacity: 0.5,
                            width: Dimensions.get("window").width,
                            height: 200,
                        }}
                    />
                </Center>
                <Center bottom={"18px"}>
                    <Center
                        borderRadius={"2xl"}
                        borderWidth={2}
                        borderColor={"white"}
                        shadow={3}
                    >
                        <Image
                            source={{
                                uri: state.location?.logoUrl,
                            }}
                            style={{
                                width: 60,
                                height: 60,
                                borderRadius: 14,
                            }}
                        />
                    </Center>
                    <Heading
                        color={"white"}
                        size={"md"}
                        textAlign={"center"}
                        p={1}
                    >
                        {getLanguageText(
                            language,
                            state.location?.businessName
                        )}
                    </Heading>
                    <Text
                        color={"white"}
                        fontSize={"sm"}
                        textAlign={"center"}
                        letterSpacing={"xl"}
                    >
                        {`${getLanguageText(
                            language,
                            state.location?.address?.locality as string
                        )}, ${getLanguageText(
                            language,
                            state.location?.address?.addressLine1 as string
                        )}`}
                    </Text>
                </Center>
            </ZStack>
            <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Body>
                        <Center>
                            {orderStage(state.orderState) === "Canceled" ? (
                                <LottieView
                                    source={require("../assets/lottie/cancel.json")}
                                    loop
                                    autoPlay
                                    style={{
                                        width: 200,
                                        height: 200,
                                    }}
                                />
                            ) : orderStage(state.orderState) === "Ready" ||
                              orderStage(state.orderState) === "Picked Up" ? (
                                <Center>
                                    <Center
                                        _web={{
                                            position: "absolute",
                                            top: "10px",
                                            left: "50px",
                                            width: 150,
                                            height: 150,
                                        }}
                                        position={"absolute"}
                                        zIndex={1}
                                        top={"30px"}
                                        right={"60px"}
                                    >
                                        <LottieView
                                            style={{
                                                width: 100,
                                                height: 100,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                            source={require("../assets/lottie/success.json")}
                                            autoPlay
                                            loop={false}
                                        />
                                    </Center>

                                    <LottieView
                                        style={{
                                            width: 200,
                                            height: 200,
                                            marginBottom: 10,
                                        }}
                                        source={require("../assets/lottie/order-bag.json")}
                                        autoPlay
                                        loop={true}
                                    />
                                </Center>
                            ) : orderStage(state.orderState) ===
                              "In Progress" ? (
                                <LottieView
                                    source={require("../assets/lottie/success.json")}
                                    autoPlay
                                    loop={true}
                                    style={{
                                        width: 200,
                                        height: 200,
                                        padding: 0,
                                    }}
                                />
                            ) : orderStage(state.orderState) === "Pending" ? (
                                <LottieView
                                    style={{
                                        width: 200,
                                        height: 200,
                                        padding: 0,
                                    }}
                                    source={require("../assets/lottie/send.json")}
                                    autoPlay
                                />
                            ) : null}
                            <Center>
                                <Heading p={0}>
                                    {orderStage(state.orderState) === "Canceled"
                                        ? t("We are sorry")
                                        : orderStage(state.orderState) ===
                                          "Pending"
                                        ? t("Please wait")
                                        : orderStage(state.orderState) ===
                                          "In Progress"
                                        ? t("Order accepted")
                                        : orderStage(state.orderState) ===
                                          "Ready"
                                        ? t("Your order is ready")
                                        : orderStage(state.orderState) ===
                                          "Picked Up"
                                        ? t("Your order is picked up")
                                        : null}
                                </Heading>
                                <Heading size={"xs"} textAlign={"center"}>
                                    {orderStage(state.orderState) === "Canceled"
                                        ? t(
                                              "Your order has been cancel because store did not accepted your order"
                                          )
                                        : orderStage(state.orderState) ===
                                          "Pending"
                                        ? t(
                                              "Your order has been sent successfully and please wait for the store to accept your order"
                                          )
                                        : null}
                                </Heading>

                                {orderStage(state.orderState) === "Pending" ? (
                                    <Center w={"100%"} m={2} mt={5}>
                                        <Progress
                                            _filledTrack={{
                                                bg: "lime.500",
                                            }}
                                            value={counter}
                                            width={"230px"}
                                            max={minutes}
                                        />
                                        <Heading p={2} size={"sm"}>
                                            {new Date(counter)
                                                .toISOString()
                                                .slice(14, 19)}
                                        </Heading>
                                    </Center>
                                ) : null}

                                {orderStage(state.orderState) === "Canceled" ||
                                orderStage(state.orderState) === "Pending" ? (
                                    <Text
                                        fontSize="xs"
                                        textAlign={"center"}
                                        letterSpacing={"xl"}
                                        color={"gray.400"}
                                    >
                                        {orderStage(state.orderState) ===
                                        "Pending"
                                            ? t(
                                                  "If your order does not accepted in 5 minutes it will be auto cancel"
                                              )
                                            : null}
                                    </Text>
                                ) : null}
                            </Center>
                        </Center>
                    </Modal.Body>
                </Modal.Content>
            </Modal>

            <OrderStutusSteps
                currentStatus={state.orderState}
                updatedAt={state.updatedAt}
                onToggle={modal.onToggle}
                isDelivery={state.isDelivery}
                isOnlinePayment={state.isOnlinePayment}
            />

            <Stack
                space={1}
                px={4}
                _web={{ mb: 2 }}
                my={4}
                mt={"auto"}
                borderRadius={"xl"}
                width={"100%"}
            >
                <Button
                    onPress={() => {
                        if (Platform.OS === "web")
                            window.open(
                                `https://squareup.com/receipt/preview/${state.paymentId}`,
                                "_blank"
                            );
                        else onToggle();
                    }}
                    size={"lg"}
                    variant={"outline"}
                    borderRadius={"xl"}
                    _text={{
                        marginBottom: -1,
                    }}
                    leftIcon={
                        <Icon
                            as={MaterialCommunityIcons}
                            name="receipt"
                            size={"sm"}
                            color={"primary.600"}
                        />
                    }
                >
                    {t("Order Details")}
                </Button>

                <Button
                    onPress={() =>
                        navigation.navigate("Locations", {
                            language,
                        })
                    }
                    size={"lg"}
                    borderTopRadius={"xl"}
                    borderBottomRadius={"2xl"}
                    _text={{
                        marginBottom: -1,
                    }}
                >
                    {t("Explore More Stores")}
                </Button>
            </Stack>
        </Center>
    );
}

// Helper function to get order stage
const orderStage = (OrderState: string): string => {
    if (OrderState === "PROPOSED") {
        return "Pending";
    } else if (OrderState === "RESERVED") {
        return "In Progress";
    } else if (OrderState === "PREPARED") {
        return "Ready";
    } else if (OrderState === "COMPLETED") {
        return "Picked Up";
    }
    return "Canceled"; // CANCELED
};
