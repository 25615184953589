import { hostingUrl } from "./configs";

export const findPromoCodeDsicount = async (promoCode: string, locationId: string): Promise<any> => {
    try {
        const response = await fetch(
            `${hostingUrl}/findPromoCodeDiscount`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ promoCode, locationId }),
            }
        );
        return await response.json();

    } catch (error) {
        console.log(error);
    }
    return null;
}