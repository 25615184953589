import { Ionicons } from "@expo/vector-icons";
import {
    Box,
    Button,
    FormControl,
    Heading,
    Icon,
    Input,
    WarningOutlineIcon,
} from "native-base";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findPromoCodeDsicount } from "../../api/posts";
import { calculateOrder } from "../../api/square";
import { setCalOrder } from "../../redux/reducers/manageCart";
import i18n, { t } from "../../translations/i18n";
import { ReduxState } from "../../utils/types";

export default function PromoCode(props: any) {
    const locationId = useSelector((s: ReduxState) => s.location.id);
    const merchantId = useSelector((s: ReduxState) => s.location.merchantId);
    const order = useSelector((s: ReduxState) => s.cart.order); // calculated order

    const [value, setValue] = useState("");
    const [discount, setDiscount] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // use this to show loading indicator [not implemented yet

    const dispatch = useDispatch();
    const textAlign = i18n.locale === "ar" ? "right" : "left";
    const flexDirection = i18n.locale === "ar" ? "row-reverse" : "row";

    const handelChange = useCallback(
        (value: string) => {
            setValue(value);
        },
        [setValue, value]
    );

    const applyPromoCode = useCallback(async () => {
        if (!value) return;
        setIsLoading(true);
        const promoCodeDiscount = await findPromoCodeDsicount(
            value,
            locationId
        );
        if (!promoCodeDiscount?.name) {
            setIsValid(false);
            setDiscount(null);
            setShowError(true);
        } else {
            setDiscount(promoCodeDiscount);
            setIsValid(true);
            setShowError(false);
            // update order discount add promo code discount to the order discounts array
            const updatedOrder = {
                ...order,
                discounts: order?.discounts
                    ? [...order?.discounts, promoCodeDiscount]
                    : [],
            };
            const cal = await calculateOrder(merchantId, updatedOrder);
            await dispatch(setCalOrder(cal.order));
            props.setOrder("order", cal.order);
        }
        setIsLoading(false);
    }, [value, locationId]);

    const removePromoCode = useCallback(async () => {
        setIsLoading(true);
        // update order discount remove promo code discount from the order discounts array
        const updatedOrder = {
            ...order,
            discounts: order?.discounts
                ? order?.discounts?.filter(
                      (applyedDiscount) =>
                          applyedDiscount.name !== discount?.name
                  )
                : [],
        };
        const cal = await calculateOrder(merchantId, updatedOrder);
        await dispatch(setCalOrder(cal?.order));
        props.setOrder("order", cal?.order);

        // remove promo code
        setDiscount(null);
        setValue("");
        setIsValid(false);
        setShowError(false);
        setIsLoading(false);
    }, []);

    return (
        <Box mb={6}>
            <Heading
                size={props.size || "md"}
                width={"100%"}
                textAlign={textAlign}
            >
                {t("Promo code / coupon")}
            </Heading>
            <FormControl isInvalid={showError}>
                <Input
                    type={"text"}
                    w="100%"
                    py="0"
                    textAlign={textAlign}
                    flexDirection={flexDirection}
                    value={value}
                    size="md"
                    variant={"underlined"}
                    letterSpacing={value ? 2 : 0}
                    isReadOnly={isValid}
                    borderColor={isValid ? "success.600" : null}
                    onChangeText={handelChange}
                    InputRightElement={
                        <>
                            {isValid ? (
                                <Button
                                    zIndex={9999}
                                    margin={1.5}
                                    size="xs"
                                    colorScheme={"danger"}
                                    _text={{ fontWeight: "bold" }}
                                    variant={"link"}
                                    w="1/6"
                                    isLoading={isLoading}
                                    onPress={() => {
                                        removePromoCode();
                                    }}
                                >
                                    {t("Remove")}
                                </Button>
                            ) : (
                                <Button
                                    margin={1.5}
                                    size="xs"
                                    rounded="xl"
                                    w="1/6"
                                    isLoading={isLoading}
                                    _text={{ fontWeight: "bold" }}
                                    onPress={() => {
                                        // apply promo code
                                        applyPromoCode();
                                    }}
                                >
                                    {t("Apply")}
                                </Button>
                            )}
                        </>
                    }
                    InputLeftElement={
                        <Icon
                            as={Ionicons}
                            name="pricetag"
                            size={"md"}
                            margin={1.5}
                            color={discount?.name ? "success.800" : null}
                        />
                    }
                    placeholder={t("Promo code")}
                />
                <FormControl.ErrorMessage
                    leftIcon={<WarningOutlineIcon size="xs" />}
                >
                    {t("Invalid promo code")}
                </FormControl.ErrorMessage>
            </FormControl>
        </Box>
    );
}
