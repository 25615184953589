import { camelKeys } from "js-convert-case";

// check if the business is open by passing location.businessHours
export default function isLocationOpen(businessHours: any) {

  // convet the businessHours.periods to camelCase
  const periods = businessHours?.periods.map((period: any) => camelKeys(period));
  if (!periods) return false;

  const today = new Date().getDay();
  const dayOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const todayPeriod = periods?.filter(
    (period: any) => period.dayOfWeek === dayOfWeek[today]
  );

  if (!todayPeriod.length) {
    return false;
  }

  const isOpen = todayPeriod.filter((period: any) => {
    const { startLocalTime, endLocalTime } = period;
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour12: false,
    });

    if (currentTime >= startLocalTime && currentTime <= endLocalTime) {
      return true;
    }
  });

  return !!isOpen.length;
}
