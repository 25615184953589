import { HStack, Switch, Text, extendTheme, useColorMode } from "native-base";
import React from "react";
import { Platform } from "react-native";

// Define changing color mode Dark/Light
export const _dark = { bg: "#171717" };
export const _light = { bg: "#f5f5f5" };

// Define the color
export const colors = {
    // Add new color
    // white: "#171717",
    // black: "#f5f5f5",
    primary: {
        50: "#e5e4ed",
        100: "#bebbd3",
        200: "#9490b5",
        300: "#6d6697",
        400: "#534883",
        500: "#3a2a70",
        600: "#352368",
        700: "#2d1b5d",
        800: "#251252",
        900: "#19003d",
    },
    secondary: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#e5e5e5",
        300: "#d4d4d4",
        400: "#a3a3a3",
        500: "#737373",
        600: "#525252",
        700: "#404040",
        800: "#262626",
        900: "#171717",
    },
    yellow: {
        50: "#fff8e0",
        100: "#ffefb3",
        200: "#ffe480",
        300: "#ffd94d",
        400: "#ffd026",
        500: "#ffc800",
        600: "#ffc200",
        700: "#ffbb00",
        800: "#ffb400",
        900: "#ffa700",
    },
};

// Define the config
const config = {
    useSystemColorMode: false,
    initialColorMode: "light",
};

// const components = {
//     Button: {
//         variants: {
//             solid: {
//                 _text: {
//                     color: "#171717",
//                 },
//                 _icon: {
//                     color: "#171717",
//                 },
//             },
//         },
//     },
//     IconButton: {
//         variants: {
//             solid: {
//                 _icon: {
//                     color: "#171717",
//                 },
//             },
//         },
//     },
// };

// Define the font

const fontConfig = {
    Tajawal: {
        200: {
            normal: "Tajawal_200ExtraLight",
        },
        300: {
            normal: "Tajawal_300Light",
        },
        400: {
            normal: "Tajawal_400Regular",
        },
        500: {
            normal: "Tajawal_500Medium",
        },
        700: {
            normal: "Tajawal_700Bold",
        },
        800: {
            normal: "Tajawal_800ExtraBold",
        },
        900: {
            normal: "Tajawal_900Black",
        },
    },
};

// Make sure values below matches any of the keys in `fontConfig`
const fonts = {
    heading: "Tajawal",
    body: "Tajawal",
    mono: "Tajawal",
};

let themeConfig = {
    // components,
    colors,
    config,
    fonts,
    fontConfig,
};

// extend the theme
export const theme = extendTheme(themeConfig);

type MyThemeType = typeof theme;
declare module "native-base" {
    interface ICustomTheme extends MyThemeType {}
}

// export function setTheme(custmizeTheme: any) {
//     const { colorMode, toggleColorMode } = useColorMode();
//     if (Platform.OS === "web" && custmizeTheme) {
//         if (custmizeTheme?.config?.initialColorMode !== colorMode) {
//             toggleColorMode();
//         }
//         if (custmizeTheme?.colors) {
//             theme.colors = { ...theme.colors, ...custmizeTheme?.colors };
//         }
//     }
// }

// export function resetTheme() {
//     theme.colors.primary = {
//         50: "#e5e4ed",
//         100: "#bebbd3",
//         200: "#9490b5",
//         300: "#6d6697",
//         400: "#534883",
//         500: "#3a2a70",
//         600: "#352368",
//         700: "#2d1b5d",
//         800: "#251252",
//         900: "#19003d",
//     };

//     components.Button.variants.solid._text.color = "white";
//     components.Button.variants.solid._icon.color = "white";
//     components.IconButton.variants.solid._icon.color = "white";
// }

// Color Switch Component
export function ToggleDarkMode() {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <HStack space={2} alignItems="center">
            <Text>Dark</Text>
            <Switch
                isChecked={colorMode === "light"}
                onToggle={toggleColorMode}
                aria-label={
                    colorMode === "light"
                        ? "switch to dark mode"
                        : "switch to light mode"
                }
            />
            <Text>Light</Text>
        </HStack>
    );
}
