import { getAuth } from "firebase/auth";
import { ReactNode, useCallback, useEffect } from "react";
import { useDispatch, batch as ReduxBatch } from "react-redux";
import { getCustomerInfo } from "../firebase/reads";
import useUserLocation from "../hooks/useUserLocation";
import { removeUser, setToken, setUser } from "../redux/reducers/auth";

interface AuthProviderProps {
    children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
    // User Location hook:
    useUserLocation();

    const dispatch = useDispatch();

    const onRemoveUser = useCallback(() => {
        dispatch(removeUser());
    }, []);

    useEffect(() => {
        const sub = getAuth().onAuthStateChanged(
            async (user) => {
                if (user?.phoneNumber) {
                    try {
                        // Set token in cookies:
                        const token = await user.getIdToken();
                        const _user = await getCustomerInfo(user?.phoneNumber);
                        if (_user) {
                            ReduxBatch(() => {
                                dispatch(setUser(_user));
                                dispatch(setToken(token));
                            });
                        } else {
                            onRemoveUser();
                        }
                    } catch {}
                } else {
                    onRemoveUser();
                }
            },
            (error) => {
                console.error(error.message);
            }
        );

        return () => sub();
    }, []);

    useEffect(() => {
        const sub = getAuth().onIdTokenChanged(
            async (user) => {
                if (user) {
                    try {
                        const token = await user.getIdToken();
                        dispatch(setToken(token));
                    } catch {
                        onRemoveUser();
                    }
                } else {
                    onRemoveUser();
                }
            },
            (error) => {
                console.error(error.message);
            }
        );

        return () => sub();
    }, []);

    return <>{children}</>;
}
