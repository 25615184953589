import { Timestamp, doc, getDoc } from "firebase/firestore";
import { camelKeys } from 'js-convert-case';
import { Location, Order, OrderLineItem } from "square";
import { db } from "../firebase/firebase";
import { getAccessToken } from "../firebase/reads";
import CatalogList from "../models/catalog-list";
import { Platform } from "react-native";
import { Distance, PrepareOrder, Recipient, Token, User } from "../utils/types";
import { hostingUrl } from "./configs";
import { updateCustomer, updateOrder } from "../firebase/writes";
const { version } = require("../../app.json").expo;


export const getCatalogList = async (merchantId: string): Promise<any> => {
  const accessToken = await getAccessToken(merchantId);

  try {
    if (!accessToken) throw new Error("No access token");
    else {
      const items = await loadItems(accessToken);
      return items;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const loadItems = async (accessToken: string) => {
  // Set to retrieve ITEM and IMAGE CatalogObjects
  try {
    const response = await fetch(
      `${hostingUrl}/catalog`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          access_token: accessToken,
          app_version: version,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const { objects } = await response.json();
    const items = CatalogList(objects); // convert to CatalogList or prepare items for the UI
    return items;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getDefaultLocation = async (accessToken: string): Promise<any> => {
  const loadLocation = async () => {
    try {
      const response = await fetch(
        `${hostingUrl}/defaultLocation`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            access_token: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const { location } = await response.json();

      return location;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  return await loadLocation();
};


export const getListLocation = async (accessToken: string): Promise<any> => {
  const loadLocation = async () => {
    try {
      const response = await fetch(
        `${hostingUrl}/listLocation`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            access_token: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const { locations } = await response.json();

      // TODO: move convertion objects to camelCase to the server
      let listLocation = [] as Location[];;
      locations.forEach((location: any) => {
        listLocation.push(camelKeys(location, {
          recursive: true,
          recursiveInArray: true,
        }) as Location);
      });

      return listLocation;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  return await loadLocation();
};

export const createOrder = async (prepareOrder: PrepareOrder, referenceId?: string
) => {
  const { userId, uid, currentUserLocation, merchantId, checkoutState, isOnlinePayment } = prepareOrder;
  const { order, displayName, ticketName, callingCode, phoneNumber, emailAddress, sourceId, curbsideDetails, isDelivery, deliveryLocation, distance } = checkoutState;

  // insert or update customer info
  await updateCustomer({
    id: userId,
    uid: uid,
    name: displayName,
    phone: phoneNumber,
    email: emailAddress,
    lastOrder: Timestamp.now(),
  } as User);

  const currentTime = new Date();
  const readyAfterMinutes = 30 * 60000;
  const pickupAt = new Date(currentTime.getTime() + readyAfterMinutes);
  const expireAfterMinutes = 5 * 60000;
  const expiresAt = new Date(currentTime.getTime() + expireAfterMinutes);

  // remove variables to be abel to send the requast 
  const lineItems = order?.lineItems?.map((item: OrderLineItem) => {
    const modifiers =
      item?.modifiers?.map((modifier: any) => {
        return { catalogObjectId: modifier.catalogObjectId };
      }) || [];
    return {
      catalogObjectId: item.catalogObjectId,
      quantity: item.quantity,
      basePriceMoney: item.basePriceMoney,
      modifiers: modifiers,
      note: item.note,
      appliedTaxes: item.appliedTaxes?.map(tax => { return { taxUid: tax.taxUid } }),
    };
  });

  const distanceText = isDelivery && distance?.endAddress ? `${distance?.endAddress}\n${distance.distance.text} -> ${distance.duration.text}` : "";
  const deliveryUrl = isDelivery && deliveryLocation ? `https://www.google.com/maps/place/${deliveryLocation?.lat},${deliveryLocation?.lng}` : "";
  const curbsidePickupDetails = !isDelivery && curbsideDetails ? { curbsideDetails } : deliveryUrl ? { curbsideDetails: deliveryUrl } : undefined;

  try {
    const body = {
      sourceId: sourceId,
      order: {
        referenceId: referenceId, // [order_payment_ref]: external payment id form the payment gateway
        locationId: order.locationId,
        source: {
          name: isDelivery ? "Jaicome Delivery" : "Jaicome Pickup",
        },
        lineItems: lineItems,
        pricingOptions: { autoApplyDiscounts: true },
        discounts: order?.discounts?.map(discount => {
          return {
            uid: discount.uid,
            name: discount.name,
            type: discount.type,
            percentage: discount.percentage,
            amountMoney: discount.amountMoney,
            appliedMoney: discount.appliedMoney,
            scope: discount.scope,
          }
        }) || [],
        serviceCharges: order?.serviceCharges?.map(serviceCharge => {
          return {
            uid: serviceCharge.uid,
            name: serviceCharge.name,
            scope: serviceCharge.scope,
            amountMoney: serviceCharge.amountMoney,
            taxable: serviceCharge.taxable,
            calculationPhase: serviceCharge.calculationPhase,
          }
        }) || [],
        taxes: order.taxes?.map(tax => {
          return { uid: tax.uid, catalogObjectId: tax.catalogObjectId, scope: tax.scope }
        }) || [],
        fulfillments: [
          {
            // autoCompleteDuration: "PT12H", // the order will be automatically completed after 12 hours
            type: "PICKUP",
            state: "PROPOSED",
            pickupDetails: {
              expiresAt: expiresAt.toISOString(),
              recipient: {
                displayName: ticketName,
                phoneNumber: `${callingCode + phoneNumber}`, // example: 966500000000
              },
              scheduleType: "ASAP",
              pickupAt: pickupAt.toISOString(),
              curbsidePickupDetails: curbsidePickupDetails,
              // Add a note to display the payment type and customer info
              note: `${sourceId === "CASH" ? "CASH | كاش" : "CARD | بطاقة"}\n${displayName} - ${phoneNumber}\n${distanceText}`,
            },
          },
        ],
        state: "OPEN",
        ticketName: ticketName
      },
      // This is the metadata that will be used for debugging and analytics
      platform: Platform.OS,
      version: `v${version}`,
      isOnlinePayment
    };

    // TODO: create useAccessToken hook
    // get the merchant access token
    const accessToken = await getAccessToken(merchantId);
    //TODO refrech token if expired soon

    if (!accessToken) {
      throw new Error("token not found");
    }
    else {
      const response = await fetch(
        `${hostingUrl}/createOrder`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            access_token: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      // check if the response is ok
      if (!response.ok) {
        throw new Error(`Failed to create order. Status: ${response.status}`);
      }

      // get the order
      const data = await response.json();


      // update order in firestore
      await updateOrder({
        id: data.orderId,
        paymentSourceId: sourceId,
        totalMoney: checkoutState.order?.totalMoney,
        customerName: displayName,
        customerPhone: phoneNumber,
        customerEmail: emailAddress,
        uid: uid || userId,
        distance: isDelivery
          ? distance
          : undefined,
        currentUserLocation: currentUserLocation
          ? {
            lat: currentUserLocation?.latitude,
            lng: currentUserLocation?.longitude,
          }
          : undefined,
        deliveryLocation: deliveryLocation,
      });

      return data;
    }

  } catch (error) {
    console.log(error);
    return null;
  }
};

export const calculateOrder = async (merchantId: string, order: Order) => {
  let attempts = 0;
  const maxAttempts = 3;

  while (attempts < maxAttempts) {
    try {
      // Basic validations
      if (!merchantId) throw new Error('Merchant ID is required');
      if (!order) throw new Error('Order is required');
      if (!order.lineItems) throw new Error('Order must contain line items');

      // Fetch access token
      const accessTokenSnapshot = await getDoc(doc(db, 'tokens', merchantId));
      const accessTokenData = accessTokenSnapshot.data();
      if (!accessTokenData || !accessTokenData.accessToken) {
        throw new Error(`Merchant ${merchantId} has no access token`);
      }
      const accessToken = accessTokenData.accessToken;

      // Calculate order request
      const response = await fetch(`${hostingUrl}/calculateOrder`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          access_token: accessToken,
        },
        body: JSON.stringify({ order }),
      });

      if (!response.ok) {
        throw new Error(`Failed to calculate order. Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error(`Error calculating order (attempt ${attempts + 1}):`, error.message);
      attempts++;
    }
  }

  console.error(`Failed to calculate order after ${maxAttempts} attempts.`);
  return null;
};



export const getSquareAuthLoginURL = async (): Promise<any> => {
  try {
    const response = await fetch(
      `${hostingUrl}/login`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const { url } = await response.json();
    return url;
  } catch (error) {
    console.log(error);
  }
};

export const getDistance = async (origin: string, destination: string): Promise<Distance | null> => {
  try {
    console.log(`${hostingUrl}/distance?origin=${origin}&destination=${destination}`);

    const response = await fetch(
      `${hostingUrl}/distance?origin=${origin}&destination=${destination}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }

    );

    if (response.status !== 200) {
      throw new Error(`Error distance API need to use defferent trip mode, driving mode can not use for this trip`);
    }

    const { distance, duration, endAddress } = await response.json();
    return { distance, duration, endAddress };

  } catch (error) {
    console.log(error);
    return null;
  }
}

// refresh token if expired and return the new token
export const refreshToken = async (token: Token): Promise<any> => {
  try {
    // if token is about to expire, refresh it
    const expiresAt = new Date(token.expiresAt.slice(0, 10));
    const now = new Date();
    const diffDays = parseInt((expiresAt - now) / (1000 * 60 * 60 * 24), 10);

    //if different is 15 or less days, refresh token
    if (diffDays <= 15) {
      const response = await fetch(
        `${hostingUrl}/refreshToken`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken: token.refreshToken }),
        }
      );
      const { accessToken } = await response.json();
      return accessToken;
    }

  } catch (error) {
    console.log(error);
  }
  return token.accessToken;
};