import { Image } from "expo-image";
import { Center, Link } from "native-base";
import React from "react";

export default function DownloadAppBadges() {
    return (
        <Center display={"flex"} flexDirection={"row"}>
            <Link href="https://play.google.com/store/apps/details?id=com.jaicome.com.stella&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <Image
                    style={{
                        width: 125,
                        height: 41,
                        maxWidth: 600,
                    }}
                    alt="Get it on Google Play"
                    source={{
                        uri: "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
                    }}
                />
            </Link>
            <Link href="https://apps.apple.com/us/app/%D8%AC%D8%A7%D9%8A%D9%83-%D9%85/id6448929575?itsct=apps_box_badge&amp;itscg=30200">
                <Image
                    style={{
                        width: 96,
                        height: 32,
                        maxWidth: 600,
                    }}
                    alt="Download on the App Store"
                    source={{
                        uri: "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1684886400",
                    }}
                />
            </Link>
        </Center>
    );
}
