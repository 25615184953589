import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, User, UserLocation } from "../../utils/types";

const initialState: AuthState = {
    user: null,
    token: null,
    location: null,
};

export const manageItems = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state: AuthState, action: PayloadAction<User>) => {
            if (action.payload) {
                return { ...state, user: action.payload };
            }
            return state;
        },
        removeUser: (state: AuthState) => {
            return { ...state, user: null, token: null };
        },
        setToken: (state: AuthState, action: PayloadAction<string>) => {
            if (action.payload) {
                return { ...state, token: action.payload };
            }
            return { ...state };
        },
        setUserLocation: (
            state: AuthState,
            action: PayloadAction<UserLocation>
        ) => {
            if (action.payload) {
                return { ...state, location: action.payload };
            }
            return { ...state };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUser, removeUser, setToken, setUserLocation } =
    manageItems.actions;

export default manageItems.reducer;
