import { MaterialIcons } from "@expo/vector-icons";
import {
    Actionsheet,
    Box,
    Button,
    Center,
    Heading,
    Input,
    Modal,
    ScrollView,
    Spacer,
    Text,
    useDisclose,
} from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import { Dimensions, Platform } from "react-native";
import Flag from "react-native-flags";
import i18n, { t } from "../../translations/i18n";
import { Country } from "../../utils/types";
import { callingCodes } from "./countries";

interface Props {
    value?: string;
    onSelect?: (code: string) => void;
}

const CallingCodeModal = ({ value, onSelect }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclose();
    const [searchQuery, setSearchQuery] = useState<string>("");

    const language = i18n.locale;
    const flexDirection = language === "ar" ? "row-reverse" : "row";
    const textAlign = language === "ar" ? "right" : "left";
    const height = Dimensions.get("screen").height;

    const handleSelectCode = (code: Country) => {
        onSelect?.(code.code);
        onClose();
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    const filteredCodes = callingCodes.filter((country) =>
        country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const isoCode = useMemo(() => {
        const _iso = callingCodes.find(
            (object) => object.code === value
        )?.isoCode;
        return _iso ?? "SA";
    }, [value]);

    useEffect(() => {
        if (Platform.OS === "web") {
            // scroll to top
            window.scrollTo(0, 0);
        }
    }, [isOpen]);

    return (
        <Center>
            <Button onPress={onOpen} variant={"unstyled"} p={0}>
                <Center flexDirection="row">
                    <Flag code={isoCode} size={24} />
                    <Heading size={"xs"} px={2}>
                        {value}
                    </Heading>
                </Center>
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={"full"}
                minHeight={height}
            >
                <Modal.Content
                    minHeight={height}
                    borderTopRadius="0"
                    p={2}
                    safeAreaTop
                >
                    <Heading
                        textAlign={textAlign}
                        paddingX={2}
                        width={"100%"}
                        size={"md"}
                        _dark={{ color: "gray.300" }}
                    >
                        {t("Select a country code")}
                    </Heading>
                    <Box
                        w="100%"
                        justifyContent="center"
                        flexDirection={flexDirection}
                        paddingX={2}
                    >
                        <Input
                            w="100%"
                            textAlign={textAlign}
                            placeholder={t("Search countries")}
                            variant="unstyled"
                            value={searchQuery}
                            onChangeText={handleSearch}
                            InputLeftElement={
                                flexDirection == "row" ? (
                                    <MaterialIcons name="search" size={16} />
                                ) : (
                                    <></>
                                )
                            }
                            InputRightElement={
                                flexDirection == "row-reverse" ? (
                                    <MaterialIcons name="search" size={16} />
                                ) : (
                                    <></>
                                )
                            }
                        />
                    </Box>

                    <Modal.Body
                        p={0}
                        _scrollview={{
                            showsVerticalScrollIndicator: false,
                        }}
                    >
                        {filteredCodes.map((country, index) => (
                            <Actionsheet.Item
                                flexDirection={flexDirection}
                                key={index}
                                onPress={() => handleSelectCode(country)}
                            >
                                <Box
                                    flexDirection={flexDirection}
                                    alignItems="center"
                                    w={"100%"}
                                >
                                    <Flag code={country.isoCode} size={24} />
                                    <Text
                                        textAlign={textAlign}
                                        mx={2}
                                    >{`${country.name} (${country.code})`}</Text>
                                    <Spacer />
                                </Box>
                            </Actionsheet.Item>
                        ))}
                        <Spacer />
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </Center>
    );
};

export default CallingCodeModal;
