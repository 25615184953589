import { doc, increment, setDoc } from "firebase/firestore";
import { User } from "../utils/types";
import { db } from "./firebase";

export async function updateCustomer(user: User | Partial<User>) {
    try {
        if (user.id) {
            const ref = doc(db, "customers", user.id);
            return setDoc(ref, { ...user, numberOfOrders: increment(1) }, { merge: true });
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function updateOrder(order: any) {
    try {
        if (order.id) {
            const ref = doc(db, "orders", order.id);
            return setDoc(ref, order, { merge: true });
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export const updateDailyAppReport = async (dailyReport: any) => {
    const today = new Date().toISOString().slice(0, 10)

    if (dailyReport.state === "CANCELLED") {
        // const report = { [today]: { totalCancelledSales: increment((dailyReport.totalSales)), cancelledOrders: increment(1), } }
        // await setDoc(doc(db, "appReport", "daily"), report, { merge: true })
    }
    else if (dailyReport.state === "OPEN") {
        const report = { [today]: { totalSales: dailyReport.totalSales, numberOfOrders: dailyReport.numberOfOrders } }
        await setDoc(doc(db, "appReport", "daily"), report, { merge: true })
    }
    else if (dailyReport.state === "TEST") {
        const report = { [today]: { numberOfTestOrders: dailyReport.numberOfTestOrders, customersTest: dailyReport.customersTest } }
        await setDoc(doc(db, "appReport", "daily"), report, { merge: true })
    }

}