import React, { useCallback } from "react";
import { Button, Actionsheet, useDisclose, Icon } from "native-base";
import { Entypo } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/reducers/manageLanguage";
import { useTranslation } from "../translations/i18n";
import { Language, ReduxState } from "../utils/types";

interface Props {
    options: Language[];
    leftIcon?: any;
    selectedIcon?: any;
    colorScheme?: string;
    variant?: string;
}

// internal pseudo props to get more customizable components
export default function OptionsProps({
    options,
    leftIcon = <></>,
    selectedIcon = (
        <Icon as={Entypo} name="check" size="sm" color={"primary.600"} />
    ),
    colorScheme = "primary",
    variant = "ghost",
}: Props) {
    const { isOpen, onOpen, onClose } = useDisclose();
    const selected = useSelector((s: ReduxState) => s.language);

    const dispatch = useDispatch();

    const onChangeSelection = useCallback(() => onClose(), []);

    const onPressLanguage = useCallback((selection: Language) => {
        useTranslation(selection.code);
        dispatch(setLanguage(selection.code));
        onChangeSelection();
    }, []);

    return (
        <>
            <Button
                colorScheme={colorScheme}
                variant={variant}
                onPress={onOpen}
                leftIcon={leftIcon}
                _text={{ alignItems: "center", marginBottom: -1 }}
            >
                {selected.lang}
            </Button>

            <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                    {options.map((selection, index) => {
                        return (
                            <Actionsheet.Item
                                leftIcon={
                                    selected.code === selection.code ? (
                                        selectedIcon
                                    ) : (
                                        <></>
                                    )
                                }
                                key={index}
                                onPress={() => onPressLanguage(selection)}
                            >
                                {selection.lang}
                            </Actionsheet.Item>
                        );
                    })}
                </Actionsheet.Content>
            </Actionsheet>
        </>
    );
}
