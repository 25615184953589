import { Box } from "native-base";
import React from "react";

export default function CarPickup({ enable }: any) {
    // if location is not enabled car pickup hide the component
    if (!enable) {
        return null;
    }
    // if location is enabled car pickup show the component
    return <Box></Box>;
}
