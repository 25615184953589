import * as Localization from "expo-localization";
import i18n from "i18n-js";
import ar from "./ar.json";
import en from "./en.json";

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
  en: en,
  ar: ar,
};
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

// Use the current language for the translation
export const useTranslation = (code: string) => {
  // TODO:
  // get current language to set the translation language
  // const lang = "ar";
  i18n.locale = code;
};

export const t = i18n.t;

export default i18n;
