import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, initializeFirestore } from "firebase/firestore";

// Initialize Firebase

export const firebaseConfig = {
    apiKey: "AIzaSyBG32qa1wyrW_teOof3ahfmW9Paw5INzCQ",
    authDomain: "stella-server-57a85.firebaseapp.com",
    projectId: "stella-server-57a85",
    storageBucket: "stella-server-57a85.appspot.com",
    messagingSenderId: "288062011768",
    appId: "1:288062011768:web:e4091508780d8e3f04708d",
    measurementId: "G-KQPK1TD7DH",
};

const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
    ignoreUndefinedProperties: true,
});
export const db = getFirestore(app);
export const auth = getAuth(app);

export async function onSignOut() {
    return auth.signOut().catch((error) => {
        console.log("[ERR @ firebase.ts -> onSignOut()]: " + error.message);
    });
}
