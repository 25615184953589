/*
Copyright 2019 Square Inc.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import CatalogItem from "./catalog-item";

/**
 * Description:
 *  An instance of this CatalogList provides the catalog list data to render
 *  views/index.pug
 *
 * Parameters:
 *  objects:  Array of Catalog objects returned from ListCatalog api
 *
 * Learn more about the ListCatalog api here: https://developer.squareup.com/reference/square/catalog-api/list-catalog
 *
 */
export default function CatalogList(catalogList: any) {

  let items = [];

  const populateItems = (objects: any) => {
    if (!objects || objects.length === 0) return;

    // Separate out the CatalogImages and the CatalogItems
    const catalogItemObjects = objects.filter(
      // In this example, we assume no item has more than one variation and we don't display any item that has no variation
      (obj) => obj.type === "ITEM"
    );

    const catalogImageObjects = objects.filter((obj) => obj.type === "IMAGE");

    const catalogModifierObjects = objects.filter(
      (obj: any) => obj.type === "MODIFIER_LIST"
    );

    const catalogCategoryObjects = objects.filter(
      (obj: any) => obj.type === "CATEGORY"
    );

    const modifiers_list = catalogModifierObjects?.map((list: any) => {
      const id = list.id;
      const name = list?.modifier_list_data?.name;
      const selectionType = list?.modifier_list_data?.selection_type;
      const presentAtLocationIds = list?.present_at_location_ids
      const modifier_options = list?.modifier_list_data?.modifiers?.map(
        (option) => {
          const price_money = option?.modifier_data?.price_money
            ? {
              amount: option?.modifier_data?.price_money.amount,
              currency: option?.modifier_data?.price_money.currency,
            }
            : null;
          return {
            id: option.id,
            name: option.modifier_data.name,
            ordinal: option.modifier_data?.ordinal,
            on_by_default: option.modifier_data?.on_by_default,
            location_overrides: option.modifier_data?.location_overrides,
            price_money: price_money
              ? price_money
              : { amount: 0, currency: "SAR" },
          };
        }
      );

      return {
        id: id,
        name: name,
        presentAtLocationIds: presentAtLocationIds,
        selectionType: selectionType,
        modifier_options: modifier_options,
      };
    });

    // For a shorter look time, we will convert the array of CatalogImageObjects, into a map
    // where the keys are the CatalogImageObjects ids and the value are the CatalogImageObjects
    const catalogImageObjectsMap = catalogImageObjects.reduce(
      (map, imageObject: any) => {
        map[imageObject.id] = imageObject?.image_data?.url;
        return map;
      },
      {}
    );

    const catalogCategoryObjectsMap = catalogCategoryObjects.reduce(
      (map, categoryObject: any) => {
        map[categoryObject.id] = {
          id: categoryObject.id,
          name: categoryObject?.category_data?.name,
          ordinal: categoryObject?.category_data?.parent_category?.ordinal,
        };
        return map;
      },
      {}
    );

    // Reassigns this.items to be an array of CatalogItem instances
    items = catalogItemObjects.map((item: any) => {
      const imageObject = catalogImageObjectsMap[item?.item_data?.image_ids];
      const categoryObject =
        catalogCategoryObjectsMap[item?.item_data?.reporting_category?.id];

      const modifierListIds =
        item?.item_data?.modifier_list_info?.map((list: any) => {
          if (list.enabled) return list.modifier_list_id;
        }) || [];

      const modifiersObjects = modifiers_list
        .map((list: any) => {
          if (modifierListIds.includes(list.id)) return list;
        })
        .filter(Boolean); // remove and null or undefined object

      return CatalogItem(
        item,
        imageObject,
        modifiersObjects,
        categoryObject
      );
    });
  }
  // Call populateItems to populate items
  populateItems(catalogList);

  return items;
}
