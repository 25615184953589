import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
    Box,
    HStack,
    Icon,
    Pressable,
    ScrollView,
    Text,
    VStack,
} from "native-base";
import {
    default as React,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { _dark, _light } from "../../Theme";
import AppLocationsBar from "../components/AppLocationsBar";
import CenterSpinner from "../components/CenterSpinner";
import Footer from "../components/Footer";
import LocationBadges from "../components/LocationBadges";
import { db } from "../firebase/firebase";
import { Location } from "../interfaces/Location";
import { setLocation } from "../redux/reducers/location";
import { setLanguage } from "../redux/reducers/manageLanguage";
import { convertFromGeoPoint, getDistance } from "../utils";
import isLocationOpen from "../utils/isLocationOpen";
import { getLanguageText } from "../utils/textFormat";
import { ReduxState } from "../utils/types";
import i18n, { t, useTranslation } from "./../translations/i18n";
import { setItems } from "../redux/reducers/manageItems";

export default function LocationsScreen({ route }) {
    const userLocation = useSelector((s: ReduxState) => s.auth.location);
    const currentLanguage = "ar";
    const flexDirection = currentLanguage === "ar" ? "row-reverse" : "row";
    const textAlign = currentLanguage === "ar" ? "right" : "left";

    const navigation = useNavigation();
    const dispatch = useDispatch();

    const [listLocation, setListLocation] = useState<Location[]>([]);
    const [searchText, setSearchText] = useState("");

    const isTestLocation = searchText.toLocaleUpperCase() === "TEST LOCATION";

    const isOpen = useCallback(
        (location: Location) => isLocationOpen(location?.businessHours),
        []
    );

    const distance = useCallback(
        (location: Location) => {
            if (userLocation && location.coordinates) {
                const { latitude, longitude } = convertFromGeoPoint(
                    location.coordinates as any
                );
                const _distanceInKm = getDistance(
                    latitude,
                    longitude,
                    userLocation.latitude,
                    userLocation.longitude
                );
                return `${Number(_distanceInKm.toFixed(2))} ${t("km")}`;
            }
            return "";
        },
        [userLocation]
    );

    const onPressSelectLocation = useCallback(async (location: Location) => {
        try {
            if (location) {
                dispatch(setLocation(location));
                dispatch(setItems([]));
                navigation.navigate("Home", {
                    language: i18n.locale === "ar" ? "ar" : "en",
                    locationId: location.id,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useLayoutEffect(() => {
        i18n.locale = route.params?.language ?? "en";
        dispatch(setLanguage(route.params?.language ?? "en"));
        navigation.setOptions({ title: t("Home") });
    }, [currentLanguage]);

    const loadLocationData = async (isTest: boolean) => {
        try {
            // get list location
            const locationsRef = collection(db, "locations");
            const q = query(
                locationsRef,
                where("isActive", "==", true),
                where("isTest", "==", isTest)
            );
            const getLocations = async () => {
                const querySnapshot = await getDocs(q);
                let locations: Location[] = [];
                querySnapshot.forEach((doc) => {
                    locations.push(doc.data() as Location);
                });
                setListLocation(locations);
            };
            getLocations();
        } catch (error) {
            console.log(error);
        }
    };

    const data = useMemo(() => {
        // Sort by nearest:
        if (userLocation) {
            return listLocation.reduce(
                (prev: (Location & { distanceInKm: number })[], curr) => {
                    const { latitude, longitude } = convertFromGeoPoint(
                        curr.coordinates as any
                    );
                    const distanceInKm = getDistance(
                        latitude,
                        longitude,
                        userLocation.latitude,
                        userLocation.longitude
                    );
                    return [...prev, { ...curr, distanceInKm }].sort(
                        (a, b) => a.distanceInKm - b.distanceInKm
                    );
                },
                []
            );
        }
        return listLocation;
    }, [listLocation, userLocation]);

    const handleSearch = useCallback((text: string) => {
        setSearchText(text);
    }, []);

    const filteredData = useMemo(() => {
        if (isTestLocation) {
            return data;
        }
        return data.filter(({ businessName, address }) => {
            const searchTextLower = searchText.toLocaleLowerCase();
            const name = businessName?.toLocaleLowerCase();
            const city = address?.locality?.toLocaleLowerCase();

            return (
                name.includes(searchTextLower) ||
                city?.includes(searchTextLower)
            );
        });
    }, [data, searchText, listLocation]);

    const renderLocation = useCallback(
        (location: Location) => {
            return (
                <Pressable
                    key={location.id}
                    marginX={4}
                    marginTop={4}
                    onPress={() => onPressSelectLocation(location)}
                >
                    {({ isHovered, isFocused, isPressed }) => {
                        const open = isOpen(location);
                        return (
                            <Box
                                _dark={{
                                    borderColor: "coolGray.700",
                                    backgroundColor: "gray.800",
                                }}
                                _light={{
                                    backgroundColor: "gray.50",
                                }}
                                borderWidth="1"
                                borderColor="coolGray.300"
                                shadow="3"
                                // bg={
                                //   isPressed
                                //     ? "coolGray.200"
                                //     : isHovered
                                //     ? "coolGray.200"
                                //     : "coolGray.100"
                                // }
                                p="3"
                                marginLeft={flexDirection === "row" ? 4 : 0}
                                marginRight={flexDirection === "row" ? 0 : 4}
                                rounded="8"
                                style={{
                                    transform: [
                                        {
                                            scale: isPressed ? 0.96 : 1,
                                        },
                                    ],
                                }}
                            >
                                <HStack
                                    alignItems={"center"}
                                    position="absolute"
                                    right={
                                        flexDirection !== "row" ? undefined : 2
                                    }
                                    left={
                                        flexDirection === "row" ? undefined : 2
                                    }
                                    top="0.5"
                                    flexDirection={flexDirection}
                                >
                                    <Text
                                        fontSize={"10px"}
                                        paddingTop={1}
                                        paddingX={1}
                                    >
                                        {open
                                            ? t("Open").toLocaleUpperCase()
                                            : t("Close").toLocaleUpperCase()}
                                    </Text>
                                    <Box
                                        width={"8px"}
                                        height={"8px"}
                                        backgroundColor={
                                            open ? "success.600" : "danger.600"
                                        }
                                        rounded="full"
                                    />
                                </HStack>

                                <HStack
                                    flexDirection={flexDirection}
                                    left={
                                        flexDirection === "row"
                                            ? -30
                                            : undefined
                                    }
                                    right={
                                        flexDirection !== "row"
                                            ? -30
                                            : undefined
                                    }
                                >
                                    <Box
                                        shadow={2}
                                        style={{
                                            width: 80,
                                            height: 80,
                                            minWidth: 80,
                                            minHeight: 80,
                                            borderRadius: 20,
                                        }}
                                    >
                                        <Image
                                            source={{
                                                uri: location.logoUrl,
                                            }}
                                            style={{
                                                width: 80,
                                                height: 80,
                                                minWidth: 80,
                                                minHeight: 80,
                                                borderRadius: 20,
                                            }}
                                            aria-label={`store-logo-${location.businessName}`}
                                        />
                                    </Box>

                                    <VStack
                                        paddingX={2}
                                        paddingY={1}
                                        maxWidth={"250px"}
                                        // justifyContent={"space-between"}
                                        justifyContent={"center"}
                                    >
                                        <Text
                                            fontWeight="medium"
                                            fontSize="xl"
                                            maxWidth={"250px"}
                                            textAlign={textAlign}
                                        >
                                            {getLanguageText(
                                                i18n.locale,
                                                location.businessName
                                            )}
                                        </Text>
                                        {!location.description ? null : (
                                            <Text
                                                maxWidth={"250px"}
                                                textAlign={textAlign}
                                            >
                                                {getLanguageText(
                                                    i18n.locale,
                                                    location.description
                                                )}
                                            </Text>
                                        )}

                                        <LocationBadges location={location} />

                                        <HStack
                                            space={2}
                                            maxWidth={"250px"}
                                            flexDirection={flexDirection}
                                        >
                                            {userLocation ? (
                                                <HStack
                                                    space={1}
                                                    alignContent={"center"}
                                                    alignItems={"center"}
                                                    flexDirection={
                                                        flexDirection
                                                    }
                                                >
                                                    <Icon
                                                        as={MaterialIcons}
                                                        name="location-on"
                                                        size={4}
                                                    />
                                                    <Text
                                                        fontSize="sm"
                                                        paddingTop={1}
                                                    >
                                                        {distance(location)}
                                                    </Text>
                                                </HStack>
                                            ) : null}

                                            <HStack
                                                space={1}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                flexDirection={flexDirection}
                                            >
                                                <Icon
                                                    as={MaterialIcons}
                                                    name="location-city"
                                                    size={4}
                                                />
                                                <Text
                                                    fontSize="sm"
                                                    paddingTop={1}
                                                    isTruncated
                                                    maxW={"140px"}
                                                >
                                                    {`${getLanguageText(
                                                        i18n.locale,
                                                        location.address
                                                            .locality as string
                                                    )}, ${getLanguageText(
                                                        i18n.locale,
                                                        location.address
                                                            .addressLine1 as string
                                                    )}`}
                                                </Text>
                                            </HStack>
                                        </HStack>
                                    </VStack>
                                </HStack>
                            </Box>
                        );
                    }}
                </Pressable>
            );
        },
        [flexDirection, i18n.locale, onPressSelectLocation, userLocation]
    );

    useEffect(() => {
        if (isTestLocation) {
            loadLocationData(true);
        } else {
            loadLocationData(false);
        }
    }, [searchText]);

    // set default language to arabic
    useEffect(() => {
        useTranslation("ar");
        dispatch(setLanguage("ar"));
    }, []);

    if (!data.length) {
        return <CenterSpinner />;
    }

    return (
        <Box size={"100%"} _dark={_dark} _light={_light}>
            <AppLocationsBar
                userLocation={userLocation}
                listLocation={listLocation}
                handleSearch={handleSearch}
            />
            <ScrollView>
                {!isTestLocation
                    ? filteredData.map((location: Location) =>
                          renderLocation(location)
                      )
                    : listLocation.map((location: Location) =>
                          renderLocation(location)
                      )}
                {/* for spacing the footer and end of location list*/}
                <Box height={100} />
            </ScrollView>

            <Footer />

            {/* <ToggleDarkMode /> */}
        </Box>
    );
}
