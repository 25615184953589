import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { Token, User } from "../utils/types";
import { refreshToken } from "../api/square";

export async function getCustomerInfo(phoneNumber: string) {
    try {
        const ref = doc(db, `customers/966${phoneNumber}`);
        const snap = await getDoc(ref);
        if (snap.exists()) {
            return snap.data() as User;
        }
        return null;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function getAccessToken(merchantId: string) {
    try {
        const ref = doc(db, `tokens/${merchantId}`);
        const snap = await getDoc(ref);
        if (snap.exists()) {
            return await refreshToken(snap.data() as Token); // refresh access token
        }
        return null;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function getLocationInfo(locationId: string) {
    try {
        const ref = doc(db, `locations/${locationId}`);
        const snap = await getDoc(ref);
        if (snap.exists()) {
            return snap.data();
        }
        return null;
    } catch (error: any) {
        throw new Error(error.message);
    }
}