import { doc, setDoc } from "firebase/firestore";
import { Box, Button, Center, Link, Stack, Text, useToast } from "native-base";
import { default as React, useCallback, useEffect } from "react";
import { Platform } from "react-native";
import { Location } from "square";

// Setup the theme
import { _dark, _light } from "../../Theme";
import CenterSpinner from "../components/CenterSpinner";
import RegisterLocationCard from "../components/RegisterLocationCard";
import { db } from "../firebase/firebase";
import { validateLocation } from "../interfaces/Location";
import { getListLocation, getSquareAuthLoginURL } from "../api/square";
import { Delivery, ServiceCharge, ServiceTime } from "../utils/types";

export default function SignupLocationScreen({ route }: any) {
    const toast = useToast();

    const [sinupURL, setSignupURL] = React.useState("");
    const [accessToken, setAccessToken] = React.useState(
        route.params?.accessToken || null
    );
    const [listLocation, setListLocation] = React.useState([]);

    useEffect(() => {
        if (accessToken) {
            setAccessToken(accessToken);
            const getLocation = async () => {
                const locations = await getListLocation(accessToken);
                setListLocation(locations);
            };
            getLocation();
        } else {
            const getURL = async () => {
                const url = await getSquareAuthLoginURL();
                setSignupURL(url);
            };
            getURL();
        }
    }, [setSignupURL, setAccessToken]);

    //TODO: move this code to firebase/writes.ts
    const handleSelectLocation = useCallback(async (location) => {
        if (validateLocation(location).length === 0) {
            await setDoc(
                doc(db, "locations", location.id),
                {
                    ...location,
                    numberOfTables: 0,
                    isActive: false, // TODO: set to true when location is ready to go live after review
                    isTest: false, // TODO: set to true when location is ready to go live after review
                    delivery: {
                        isActive: false,
                        minAmount: 0,
                        serviceTime: {
                            startAt: null,
                            endAt: null,
                        } as ServiceTime,
                        enabledServiceCharge: false,
                        serviceCharges: [
                            {
                                name: "Delivery Service Fee | رسوم خدمة التوصيل",
                                amountMoney: {
                                    amount: 100, // 100 == 1 SAR
                                    currency: location.currency,
                                },
                                scope: "ORDER",
                                calculationPhase: "SUBTOTAL_PHASE",
                                taxable: true,
                            } as ServiceCharge,
                        ],
                    } as Delivery,
                },
                { merge: true }
            );
            // create todo review list for the location register or update info
            await setDoc(
                doc(db, "reviewLocations", location.id),
                {
                    updateAt: new Date(),
                    status: "pending",
                },
                { merge: true }
            );
            toast.show({
                title: "Location added seccessfully",
            });
        } else {
            toast.show({
                title: "Location is not valid please complete all required fields",
                description: validateLocation(location).join("\n"),
            });
        }
    }, []);

    // have access token list locations
    if (accessToken) {
        // loading data indicator
        if (listLocation.length === 0) return <CenterSpinner />;
        // show list locations
        return (
            <Box w={"100%"} _dark={_dark} _light={_light} alignItems="center">
                {listLocation.map((location: any) => (
                    <RegisterLocationCard
                        key={location.id}
                        location={location}
                        onPress={() => {
                            //TODO: navigate to next screen to review the location registration info
                            handleSelectLocation(location as Location);
                        }}
                    />
                ))}
            </Box>
        );
    }

    // loading indicator
    if (!sinupURL) {
        return <CenterSpinner />;
    }

    // sign in with square
    return (
        <Center
            size={"100%"}
            p={3}
            _dark={_dark}
            _light={_light}
            alignItems="center"
        >
            <Button
                borderRadius={"xl"}
                size={"lg"}
                colorScheme={"blue"}
                width={280}
                _text={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: -1,
                }}
                onPress={() => {
                    if (Platform.OS === "web")
                        window.location.replace(sinupURL);
                }}
            >
                Sign in
            </Button>
            <Stack width={260} m={2} flexDir={"row"}>
                <Text>Don't have Square POS account?</Text>
                <Link
                    href="https://squareup.com/signup/sa?lang_code=sa&country_code=ar"
                    isExternal
                >
                    <Text mx={2} color={"blue.600"} fontWeight={"bold"}>
                        Sign up
                    </Text>
                </Link>
            </Stack>

            {/* TODO: able to change language */}
        </Center>
    );
}
