import { createSlice } from '@reduxjs/toolkit';
import { Order } from 'square';
import lodash from 'lodash';

const countCartQty = (state: any) => {
	// reset the calculation
	state.numberOfItems = 0;
	state.total = 0;
	state.subtotal = 0;
	state.tax = 0;
	state.order = {} as Order;

	state.itemsList.map(({ data }: any) => {
		state.numberOfItems += data.quantity;
		state.total += data.grossSalesMoney.amount;
	});

	// subtotal
	state.subtotal = state.total; //     / 1.15;
	// tax
	state.tax = state.subtotal - state.total / 1.15;
	return state;
};

// check if item is exist for catalogObjectId, modifiers, note, variationName, basePriceMoney
// return index if exist else return -1
const isExist = (itemsList: any, item: any) => {
	let index = -1;
	itemsList.map(({ data }: any, i: number) => {
		if (data.catalogObjectId === item.data.catalogObjectId) {
			// check modifiers
			if (lodash.isEqual(data.modifiers, item.data.modifiers)) {
				// check note
				if (data.note === item.note) {
					// check variationName
					if (data.variationName === item.data.variationName) {
						// check basePriceMoney
						if (lodash.isEqual(data.basePriceMoney, item.data.basePriceMoney)) {
							index = i;
						}
					}
				}
			}
		}
	});
	return index;
}

const addItemToCart = (itemsList: any, item: any) => {
	const index = isExist(itemsList, item)
	// if exist increse the quantity and grossSalesMoney and variationTotalPriceMoney
	if (index != -1) {
		const qty = Number(item.data.quantity) + Number(itemsList[index].data.quantity);
		itemsList[index].data.quantity = qty.toString();
		itemsList[index].data.grossSalesMoney.amount += item.data.grossSalesMoney.amount;
		itemsList[index].data.variationTotalPriceMoney.amount += item.data.variationTotalPriceMoney.amount;
	} else {
		itemsList.push(item); // add item to cart if not exist
	}
}

export const manageCart = createSlice({
	name: 'cart',
	initialState: {
		numberOfItems: 0,
		selectedTableNumber: 0,
		selectedParkingNumber: 0,
		isDelivery: false,
		curbsideDetails: null,
		promoCode: '',
		itemsList: [],
		order: {} as Order
	},
	reducers: {
		addItem: (state, action) => {
			addItemToCart(state.itemsList, action.payload)
			countCartQty(state);
			return state;
		},
		removeItem: (state, action) => {
			state.itemsList.splice(action.payload, 1);
			countCartQty(state);
			return state;
		},
		clearCart: (state) => {
			state.itemsList = [];
			state.order = {} as Order;
			state.numberOfItems = 0;
			countCartQty(state);
			return state;
		},
		setCalOrder: (state, action) => {
			state.order = { ...action.payload, discounts: action.payload?.discounts || [] };
			return state;
		},
		setTableNumber: (state, action) => {
			state.selectedTableNumber = action.payload;
			return state;
		},
		setParkingNumber: (state, action) => {
			state.selectedParkingNumber = action.payload;
			return state;
		},
		setIsDelivery: (state, action) => {
			state.isDelivery = action.payload;
			return state;
		},
		setPromoCode: (state, action) => {
			state.promoCode = action.payload;
			return state;
		},
		setCurbsideDetails: (state, action) => {
			state.curbsideDetails = action.payload;
			return state;
		}
	},
});

// Action creators are generated for each case reducer function
export const { addItem, removeItem, clearCart, setCalOrder, setTableNumber, setParkingNumber, setIsDelivery, setPromoCode, setCurbsideDetails } = manageCart.actions;

export default manageCart.reducer;
