// Icons
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
    Actionsheet,
    Badge,
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Icon,
    StatusBar,
    useDisclose,
    VStack,
} from "native-base";
import React from "react";
import { StatusBarStyle } from "react-native";
import { useSelector } from "react-redux";
import i18n, { t } from "../../translations/i18n";
import { ReduxState } from "../../utils/types";
import CartContent from "./CartContent";
import { _light } from "../../../Theme";

interface Props {
    cart: any;
    goBack?: any;
    barStyle?: StatusBarStyle;
}

export default function CartBar(props: Props) {
    const { cart, goBack, barStyle } = props;
    const navigation = useNavigation();
    const { isOpen, onOpen, onClose } = useDisclose();

    // check if location is active
    const isActive = useSelector(
        (state: ReduxState) => state.location.isActive
    );

    // Get the total number of cart items
    var cartItemsQty = 0;
    props.cart.itemsList.map(
        ({ data }) => (cartItemsQty += Number(data.quantity))
    );

    // Get total amount of cart items
    var totalAmount = 0;
    cart.itemsList.map(
        ({ data }) => (totalAmount += data.grossSalesMoney.amount)
    );

    return (
        <>
            <Box zIndex={2} position="absolute" w="100%">
                <StatusBar barStyle={barStyle} />
                <Box safeAreaTop />
                <HStack
                    px="4"
                    my="3"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                >
                    <HStack>
                        {goBack ? (
                            <Button
                                onPress={goBack}
                                p="2"
                                shadow={3}
                                mt="5.69px"
                                borderRadius="xl"
                                variant={"solid"}
                                startIcon={
                                    <Icon
                                        pl={1}
                                        _android={{ right: -3, pl: 0 }}
                                        size="md"
                                        as={MaterialIcons}
                                        name="arrow-back-ios"
                                        textAlign={"center"}
                                    />
                                }
                            />
                        ) : null}
                    </HStack>
                    {!isActive ? null : (
                        <VStack>
                            {cartItemsQty ? (
                                <Badge
                                    colorScheme="danger"
                                    borderRadius="full"
                                    mb={-3}
                                    mr={-1.5}
                                    p={"1px"}
                                    px={"4px"}
                                    zIndex={1}
                                    variant="solid"
                                    alignSelf="flex-end"
                                    _text={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        padding: "0.1px",
                                        marginBottom: "-4px",
                                    }}
                                >
                                    {cartItemsQty}
                                </Badge>
                            ) : null}

                            <Button
                                onPress={onOpen}
                                p="2"
                                shadow={3}
                                borderRadius="xl"
                                variant={"solid"}
                                startIcon={
                                    <Icon
                                        as={MaterialIcons}
                                        name="shopping-bag"
                                        size="md"
                                    />
                                }
                            />
                        </VStack>
                    )}
                </HStack>
            </Box>

            <Center>
                <Actionsheet isOpen={isOpen} onClose={onClose}>
                    <Actionsheet.Content
                        _light={_light}
                        _ios={{ marginBottom: -5 }}
                        // _android={{ marginBottom: -5 }}
                    >
                        {cart.itemsList.length ? (
                            <>
                                <CartContent data={cart.itemsList} />
                                <Box width={"100%"} paddingX={4}>
                                    <Button
                                        onPress={() => {
                                            onClose();
                                            navigation.navigate("Checkout", {
                                                language:
                                                    i18n.locale === "ar"
                                                        ? "ar"
                                                        : "en",
                                            });
                                        }}
                                        size={"lg"}
                                        width={"100%"}
                                        borderTopRadius={"xl"}
                                        borderBottomRadius={"2xl"}
                                        _text={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            marginBottom: -1,
                                        }}
                                    >
                                        {t("Review Order")}
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <Center h={"250px"}>
                                <Heading color={"light.600"} size={"md"}>
                                    {t("Your Cart Empty")}
                                </Heading>
                            </Center>
                        )}
                    </Actionsheet.Content>
                </Actionsheet>
            </Center>
        </>
    );
}
