import { FontAwesome } from "@expo/vector-icons";
import { Center, Heading, HStack, Icon, IconButton } from "native-base";
import React, { useState } from "react";

export default function InputSpinner(props: any, { limit = 100 }) {
    const [qty, setQty] = useState(1);

    const increase = () => {
        if (qty < limit) {
            const updatedQty = qty + 1;
            setQty(updatedQty);
            props.onChange(updatedQty);
        }
    };
    const decrease = () => {
        const updatedQty = qty - 1;
        if (qty > 1 || props.isNegative) {
            setQty(updatedQty); // update (child) this component
            props.onChange(updatedQty); // update (parent) main component
        }
    };

    return (
        <HStack
            _dark={{
                borderColor: "coolGray.600",
                backgroundColor: "gray.700",
            }}
            _web={{
                // shadow: 2,
                borderWidth: 0,
            }}
            _light={{
                backgroundColor: "primary.50",
            }}
            // borderWidth={1}
            borderRadius="full"
            p={2}
            mx={2}
            justifyContent="space-between"
        >
            <Center>
                <IconButton
                    // colorScheme="secondary"
                    onPress={decrease}
                    variant="solid"
                    borderRadius="full"
                    p={"1.5"}
                    icon={
                        <Icon
                            as={FontAwesome}
                            name={"minus"}
                            size={"xs"}
                            textAlign={"center"}
                        />
                    }
                />
            </Center>
            <Center>
                <Heading size={"sm"} w="35px" textAlign={"center"} px="3">
                    {qty}
                </Heading>
            </Center>
            <Center>
                <IconButton
                    // colorScheme="secondary"
                    onPress={increase}
                    variant="solid"
                    borderRadius="full"
                    p={"1.5"}
                    icon={
                        <Icon
                            as={FontAwesome}
                            name={"plus"}
                            size={"xs"}
                            textAlign={"center"}
                        />
                    }
                />
            </Center>
        </HStack>
    );
}
