import { Entypo } from "@expo/vector-icons";
import {
    Actionsheet,
    Button,
    Heading,
    Icon,
    ScrollView,
    useDisclose,
} from "native-base";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setParkingNumber, setTableNumber } from "../redux/reducers/manageCart";
import i18n, { t } from "../translations/i18n";

interface Props {
    onSelected: () => void;
    numberOfOptions: number;
    selectedOption: number;
    buttonText: string;
    title: string;
    optionTitle: string;
    leftIcon?: any;
    selectedIcon?: any;
    colorScheme?: string;
    variant?: string;
}

export default function DiningOptionsProps({
    onSelected,
    numberOfOptions,
    selectedOption,
    leftIcon,
    buttonText,
    title,
    optionTitle,
    selectedIcon = (
        <Icon as={Entypo} name="check" size="sm" color={"primary.600"} />
    ),
    colorScheme = "primary",
    variant = "ghost",
}: Props) {
    const { isOpen, onOpen, onClose } = useDisclose();
    const dispatch = useDispatch();

    const onChangeSelection = useCallback(() => onClose(), [onClose]);

    const onPressTable = useCallback(
        (selection: number) => {
            if (optionTitle === "Table") {
                dispatch(setTableNumber(selection));
                dispatch(setParkingNumber(0));
            } else if (optionTitle === "Parking") {
                dispatch(setParkingNumber(selection));
                dispatch(setTableNumber(0));
            } else {
                dispatch(setParkingNumber(0));
                dispatch(setTableNumber(0));
            }
            onChangeSelection();
            onSelected();
        },
        [dispatch, optionTitle, onChangeSelection, onSelected]
    );

    if (numberOfOptions === 0 || numberOfOptions === undefined) {
        return null;
    }

    return (
        <>
            <Button
                borderRadius="xl"
                size="lg"
                width="100%"
                _text={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: -1,
                }}
                leftIcon={leftIcon}
                onPress={onOpen}
            >
                {t(buttonText)}
            </Button>

            <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                    <Heading>{t(title)}</Heading>
                    <ScrollView
                        size="full"
                        showsVerticalScrollIndicator={false}
                    >
                        {[...Array(numberOfOptions)].map((_, index) => {
                            const isSelected =
                                (i18n.locale === "en" &&
                                    selectedOption === index + 1) ||
                                (i18n.locale === "ar" &&
                                    selectedOption === index + 1);
                            const isRTL = i18n.locale === "ar";
                            return (
                                <Actionsheet.Item
                                    alignItems={
                                        isRTL ? "flex-end" : "flex-start"
                                    }
                                    leftIcon={
                                        isRTL && isSelected
                                            ? selectedIcon
                                            : null
                                    }
                                    rightIcon={
                                        !isRTL && isSelected
                                            ? selectedIcon
                                            : null
                                    }
                                    key={index}
                                    onPress={() => onPressTable(index + 1)}
                                >
                                    {`${t(optionTitle)} ${index + 1}`}
                                </Actionsheet.Item>
                            );
                        })}
                    </ScrollView>
                </Actionsheet.Content>
            </Actionsheet>
        </>
    );
}
