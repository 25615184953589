import { Ionicons } from "@expo/vector-icons";
import { Box, Button, Heading, Icon, Stack } from "native-base";
import React, { useCallback } from "react";
import { Image } from "expo-image";
import { useSelector } from "react-redux";
import i18n, { t } from "../../translations/i18n";
import { ReduxState } from "../../utils/types";

export default function PaymentTypes(props: any) {
    const { location, cart } = useSelector((s: ReduxState) => s);
    // get the payment types configuration from the store
    const { isOnlinePayment, cash, card } = location?.paymentTypes || {
        isOnlinePayment: false,
        cash: true,
        card: true,
    };
    // get the delivery configuration from the store to check if the location is online payment only for the delivery
    const isOnlinePaymentOnly =
        (location.delivery?.isOnlinePaymentOnly && cart.isDelivery) || false;

    // the default value is "EXTERNAL" if the card payment is enabled
    const [value, setValue] = React.useState(card ? "EXTERNAL" : "CASH");

    // get the text alignment and the flex direction based on the language
    const textAlign = i18n.locale === "ar" ? "right" : "left";
    const justifyContent = i18n.locale === "ar" ? "flex-end" : "flex-start";

    const handelChange = useCallback(
        (value: "EXTERNAL" | "CASH") => {
            setValue(value);
            props.onChange("sourceId", value);
        },
        [setValue, value]
    );

    if (isOnlinePayment || isOnlinePaymentOnly) {
        return (
            <Box mb={3}>
                <Heading
                    size={props.size || "md"}
                    width={"100%"}
                    textAlign={textAlign}
                    mb={2}
                >
                    {t("Payment Method")}
                </Heading>
                <Button
                    variant={"outline"}
                    width={"100%"}
                    justifyContent={justifyContent}
                    borderRadius={"xl"}
                    borderColor={"primary.600"}
                    backgroundColor={"primary.50"}
                    borderWidth={1.5}
                    mb={2}
                >
                    <Stack width={"100%"}>
                        <Heading size={"xs"} bold textAlign={textAlign}>
                            {t("Online Payment")}
                        </Heading>
                        <Image
                            style={{
                                height: 24,
                                width: 190,
                                resizeMode: "contain",
                            }}
                            source={require("../../assets/images/payments-methods-logos.png")}
                            aria-label={`payment-type-logos`}
                            accessibilityLabel={`payment-type-logos`}
                        />
                    </Stack>
                </Button>
            </Box>
        );
    }

    return (
        <Box mb={3}>
            <Heading
                size={props.size || "md"}
                width={"100%"}
                textAlign={textAlign}
                mb={2}
            >
                {t("Pay in-store")}
            </Heading>
            <Button.Group
                borderRadius={"xl"}
                isAttached
                size="sm"
                width={"100%"}
                alignContent={"center"}
                justifyContent={"center"}
            >
                {card && (
                    <Button
                        w={"50%"}
                        variant={value === "EXTERNAL" ? "solid" : "outline"}
                        onPress={() => handelChange("EXTERNAL")}
                        rightIcon={
                            <Icon
                                as={Ionicons}
                                name="card"
                                size={"sm"}
                                color={value === "EXTERNAL" ? "white" : null}
                            />
                        }
                        _text={{
                            mb: -1,
                        }}
                    >
                        {t("Card payment in-store")}
                    </Button>
                )}

                {/* Enable the cash payment option if it is enabled or there is no payment option is enabled */}
                {(cash || (!cash && !card)) && (
                    <Button
                        w={"50%"}
                        variant={value === "CASH" ? "solid" : "outline"}
                        onPress={() => handelChange("CASH")}
                        rightIcon={
                            <Icon
                                as={Ionicons}
                                name="cash"
                                size={"sm"}
                                color={value === "CASH" ? "white" : null}
                            />
                        }
                        _text={{
                            mb: -1,
                        }}
                    >
                        {t("Cash")}
                    </Button>
                )}
            </Button.Group>
        </Box>
    );
}
